import React from 'react'
import { ClientsNav } from './ClientsNav/ClientsNav'
import { LoincMain } from './LoincMain/LoincMain'
import { ProgressBar } from './ProgressBar/ProgressBar'
import { Nav } from './Nav/Nav'
import { useSelector } from 'react-redux'

export const OnaosTranscoding = () => {
    const userAccess = useSelector((state) => state.log.access)

    return (
        <React.Fragment>
            {(userAccess.isAdminOnaos || userAccess.isExpertOnaos) && <ClientsNav />}
            <Nav />
            <LoincMain />
            <ProgressBar />
        </React.Fragment>
    )
}
