import { DashBoardTableObject, Search, TitleDashboard } from 'onaosreact'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setExportDicoCheck, setTableFileDatas } from '../../../../redux/filemenu/FileMenu'
import { setExportButtonAvailable, setIdsExport } from '../../../../redux/exportmenu/ExportMenu'
import { ExportButton } from '../../Export/ExportButton'
import { useSnackbar } from 'notistack'
import { makeRequest } from '../../../../api/apihook'






export const OnaosAccessTraceability = ({}) => {


    const exportButtonAvailable = useSelector(state =>state.ExportMenu.exportButtonAvailable)
    
    const clientListing = useSelector(state => state.FileMenu.tableDatas)
    const clientInfos = useSelector(state => state.FileMenu.clientInfos)
    const filtersInit = useSelector((state) => state.FileMenu.filters)
    const tokenRefresh = useSelector((state) => state.log.tokenRefresh)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const dispatch = useDispatch()

    const [clientsAuto, setClientsAuto] = useState([])
    const [filters, setFilter] = useState(JSON.parse(JSON.stringify(filtersInit)))

    useEffect(()=>{
      setFilter(JSON.parse(JSON.stringify(filtersInit)))
    }, [filtersInit])


  useEffect(()=>{
      const clientsDtas = clientInfos.map(v => {
          return v.text[0]
      })
      setClientsAuto(clientsDtas)
  }, [clientInfos])



    useEffect(()=> {
      const idCheck = clientListing.body.filter(line => line.check === true).map(line => {
         return line.id
      })            
      dispatch(setIdsExport(idCheck))
      dispatch(setExportButtonAvailable(idCheck.length > 0))
    }, [clientListing])

    const onSearch = (text, tags) => {
      enqueueSnackbar('Recherche en cours...', {
        variant: 'info',
        persist: true,
      })
      const datas = {
        client: text,
        type : 'traca',
        filters: tags,
      }
      
      makeRequest('put', '/searchDicos', tokenRefresh, datas).then((resp) => {
        if (resp.data && resp.data.clientsFiles) dispatch(setTableFileDatas(resp.data.clientsFiles))
        if (resp.data && resp.data.filters ) setFilter(resp.data.filters)
        closeSnackbar()
      })    
    }

    const onCheckLine = (lineID) => {
        dispatch(setExportDicoCheck(lineID))
    }

    return(
        <React.Fragment>
            <TitleDashboard 
                text = "Traçabilité"
                style = {{fontSize : "20px", padding : "0 16px", margin : '0px'}}
            />
            <Search
                leftComponent = {
                  <div style = {{visibility : "hidden", display : 'flex', justifyContent : 'start', gap : "16px"}}>
                    <ExportButton 
                        disabled={true}
                    />
                  </div>
                }
                rightComponent = {
                  <div style = {{display : 'flex', justifyContent : 'end', gap : "16px"}}>
                    <ExportButton 
                        disabled={!exportButtonAvailable}
                        exportType = 'tracabilite'
                    />
                  </div>
                }
                searchLabel = "Rechercher une structure"
                searchStyle = {{height : '32px', boxSizing: 'border-box', gap : '16px' }}
                blocSearchStyle = {{justifyContent : 'space-between', padding : '0 16px 16px', marginBottom : ''}}
                styleBlockSearch = {{ gap : '16px', padding : '0 16px'}}
                defaultInputValue = ""
                searhcAndFilterWidth = "632px"
                filterData = {filters}
                // filterSize = {{width: '100%'}}
                onSearch = {onSearch}
                autoCompletDatas = {clientsAuto}
                removePage = {true}
                blockStyle= {{marginTop : "0px", height : '94%'}}
                tagStyle = {{width : '100%', padding : '0 16px 16px', boxSizing : 'border-box'}}
                openBlockSearchStyle = {{borderRadius : "5px 5px 0 0"}}
                allowEmpty = {true}

            >
                <DashBoardTableObject 
                    body = {clientListing.body}
                    head = {clientListing.head}
                    maxCarac = {25}
                    borderLineStyle = {{height : "48px", borderBottom : '1px solid #ECECEC'}}
                    headStyle  ={{zIndex : 20, height : "48px"}}
                    footStyle = {{height : "150px" }}
                    checkbox = {true}
                 //   stateChangeAll = {()=>console.log("stateall")}
                    onChange = {(lineID) => onCheckLine(lineID)}
                />
            </Search>
        </React.Fragment>
    )


}