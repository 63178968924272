import { createSlice } from "@reduxjs/toolkit";


export const TranscodingSlice = createSlice({
    name : "Transcoding",
    initialState:{
        clientnav : {
            clientsList : {
              datas : [],
              id : '',
            },
            dicoType : {
              datas : [],
              id : ''
            },
            expertChapitres : {
              datas : [],
              id : ''
            },
        },
        nav : {
            chapitres : {
                datas : [],
                id : ""
            },
            entry : {
                datas :[],
                id : ""
            }, 
        },
        clientDatas : {},
        allEntries : [],
        loincDatas : {
            cards : [
            ]
        },
        updateCard : {},
        otherInfos : {},
        entrieID : "",
        progression : {},
        showAllDone : true,
        showAccessDanger : true,

    },
    reducers: {
        setEntriesIDActive : (state, action) => {
            state.entrieID = action.payload
            return state
        },
        stopShowAllDone : (state, action) => {
            state.showAllDone = false 
            return state
        },
        stopShowAccessDanger : (state, action) => {
            state.showAccessDanger = false 
            return state
        },
        setProgression : (state, action) => {
            if (action.payload === '') {
                state.progression = undefined
                return state 
            }
            const id = action.payload
            const entryPosition = state.allEntries.findIndex(line => line.id === id) + 1
            const total = state.allEntries.length 

            const toDoEntries = state.allEntries.filter(line => line.state === 'Non traitée').length
            const skipEntries = state.allEntries.filter(line => line.state === 'Passée').length
            const progress = [
                (total - (skipEntries + toDoEntries)) * 100 / total,
                skipEntries * 100 / total,
                toDoEntries * 100 / total,
            ]

            state.progression = {
                currentLocalEntrie : entryPosition,
                countTotalEntrie : total,
                progress : progress,
                done : Number(total - (skipEntries + toDoEntries)),
                todo : toDoEntries,
                skip : skipEntries,
                texts: [
                    Math.round(progress[0]*100)/100 + "% (" + Number(total - (skipEntries + toDoEntries)) + "/" + total + ")" ,
                    Math.round(progress[1]*100)/100 + "% (" + skipEntries + "/" + total + ")" ,
                    Math.round(progress[2]*100)/100 + "% (" + toDoEntries + "/" + total + ")" ,
                ]
            }

            return state 
        },

       setAllEntries : (state, action) => {
            state.allEntries = action.payload.all

            if (action.payload.all.length === 0 || action.payload.id === '') {
                state.progression = undefined
                return state
            }

            const id = action.payload.id
            const entryPosition = action.payload.all.findIndex(line => line.id === id) + 1
            const total = action.payload.all.length 

            const toDoEntries = action.payload.all.filter(line => line.state === 'Non traitée').length
            const skipEntries = action.payload.all.filter(line => line.state === 'Passée').length
            const progress = [
                (total - (skipEntries + toDoEntries)) * 100 / total,
                skipEntries * 100 / total,
                toDoEntries * 100 / total,
            ]

            state.progression = {
                currentLocalEntrie : entryPosition,
                countTotalEntrie : total,
                progress : progress,
                done : Number(total - (skipEntries + toDoEntries)),
                todo : toDoEntries,
                skip : skipEntries,
                texts: [
                    Math.round(progress[0]*100)/100 + "% (" + Number(total - (skipEntries + toDoEntries)) + "/" + total + ")" ,
                    Math.round(progress[1]*100)/100 + "% (" + skipEntries + "/" + total + ")" ,
                    Math.round(progress[2]*100)/100 + "% (" + toDoEntries + "/" + total + ")" ,
                ]
            }

            return state
       },
       setLocalInfos : (state, action) => {
            state.clientDatas = action.payload
            return state
       },
       setLoincInfos : (state, action) => {
            state.loincDatas.cards = action.payload
            return state
       },
       setUpdateCardInfos : (state, action) => {
            state.updateCard = action.payload
            return state
       },
       setOtherInfos : (state, action)=>{
            state.otherInfos = action.payload
            return state
       },
       setNavClientPart : (state, action)=> {
            state.nav = action.payload
            return state
       },
       setNavOnaosPart : (state, action)=> {
            state.clientnav = action.payload
            return state
       },
       setchapterID : (state, action) => {
            state.nav.chapitres.id = action.payload
            return state
       },
       setTypeOfEntryID : (state, action) => {
            state.nav.entry.id = action.payload
            return state
       },
       clearNavEntry : (state, action) => {
            state.nav = {
                chapitres : {
                    datas : [],
                    id : ""
                },
                entry : {
                    datas :[],
                    id : ""
                }, 
            }
            return state
       },
       setDefaultOnaosNavBar : (state, action) => {
            state.clientnav.clientsList.id = action.payload.clientID
            state.clientnav.dicoType.id = action.payload.dicoType
            state.clientnav.expertChapitres.id = action.payload.collectionID
            return state
       },
       changeLOINCState : (state, action) => {
            const label = action.payload.type === "Vérification Client" ? 'sélectionné client' : action.payload.type === "Review"  ? 'sélectionné review expert' : 'sélectionné expert'
            state.loincDatas.cards.forEach((element, index) => {
                const code = element.code
                if (action.payload.datas[code] !== undefined) {
                    state.loincDatas.cards[index].Status[label] = action.payload.datas[code]
                }
            });
            if (action.payload.addNonLOINC !== undefined) {
                state.loincDatas.cards.unshift(action.payload.addNonLOINC)
            }

            return state
        },
        turnSelectedIDToUntreated : (state, action) => {
            const indexEntry = state.allEntries.findIndex(line => line.id === action.payload)
            if  (indexEntry === undefined) return state 
            if (state.allEntries[indexEntry].state === "Non traitée") return state
            
            state.allEntries[indexEntry].hoverColor = ""
            const oldState = state.allEntries[indexEntry].state
            state.allEntries[indexEntry].state = 'Non traitée'
            const total = state.allEntries.length


            const toDoEntries = state.progression.todo + 1 
            const skipEntries = oldState === "Passée" ?  state.progression.skip - 1 :  state.progression.skip
            const doneEntries = Number(total - (skipEntries + toDoEntries))

            const progress = [
                (total - (skipEntries + toDoEntries)) * 100 / total,
                skipEntries * 100 / total,
                toDoEntries * 100 / total,
            ]
          
            state.progression.progress = progress
            state.progression.texts = [
                Math.round(progress[0]*100)/100 + "% (" + doneEntries + "/" + total + ")" ,
                Math.round(progress[1]*100)/100 + "% (" + skipEntries + "/" + total + ")" ,
                Math.round(progress[2]*100)/100 + "% (" + toDoEntries + "/" + total + ")" ,
            ]
            state.progression.done = doneEntries
            state.progression.todo = toDoEntries
            state.progression.skip = skipEntries
            return state

        },
        turnSelectedIDTo : (state, action) => {
            if (action.payload.id === undefined || action.payload.color === undefined || action.payload.state === undefined ) return state 
            const indexEntry = state.allEntries.findIndex(line => line.id === action.payload.id)
            if  (indexEntry === undefined || indexEntry === null) return state 
            state.allEntries[indexEntry].hoverColor = action.payload.color
            state.allEntries[indexEntry].state = action.payload.state
            return state
        },
        editComm : (state, action) => {
            const position = action.payload.position 
            const value = action.payload.value 
            state.clientDatas.infosComm.comms[position].value = value
            return state
        },

        
    }
})


export const {setEntriesIDActive, setProgression, setAllEntries, setLocalInfos, 
  setLoincInfos, setOtherInfos, setNavClientPart, setNavOnaosPart,
  setchapterID, setTypeOfEntryID, clearNavEntry, setDefaultOnaosNavBar,
  changeLOINCState, turnSelectedIDToUntreated, turnSelectedIDTo, editComm, 
  setUpdateCardInfos, stopShowAllDone, stopShowAccessDanger
} = TranscodingSlice.actions;