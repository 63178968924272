import { createSlice } from "@reduxjs/toolkit";



export const InformationMenuSlice = createSlice({
    name : "InformationMenu",
    initialState:{
        adminInfos : {fullName : '', email : '', phone : '', id : ''}, 
        expertInfos : [],
        openChapterAssign : false,
        chaptersListing : [],
        allClients : [],
        clientId : -1,
        isEditableClient : false,
    },
    reducers: {
        setAdminInfos : (state, action) => {
            state.adminInfos = action.payload
            return state
        },
        setExpertInfos : (state, action) => {
            state.expertInfos = action.payload
            return state
        },
        setChapterDicExpertInfos : (state, action) => {
            state.expertInfos.chapter = action.payload
            return state
        },
        clearChapterDicExpertInfos : (state, action) => {
            state.expertInfos.chapter = undefined
            return state
        },
        setOpenChaptersWindow : (state, action) => {
            state.expertInfos.openChapterAssign = action.payload
            return state
        },
        setListingChapters : (state, action) => {
            state.chaptersListing = action.payload
            return state
        },
        setAllClients : (state, action) => {
            state.allClients = action.payload
            return state
        },
        setClientActive : (state, action) => {
            state.clientId = action.payload
            return state
        },
        setEditableClient : (state, action) => {
            state.isEditableClient = action.payload
            return state
        }

    }
})


export const {setAdminInfos, setExpertInfos, setChapterDicExpertInfos, 
    setOpenChaptersWindow, setListingChapters, setAllClients, clearChapterDicExpertInfos,
    setClientActive, setEditableClient} = InformationMenuSlice.actions;