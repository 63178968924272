import { ConfirmationSnackBar, ModalChaptersAndTable } from 'onaosreact'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setListingChapters, setOpenChaptersWindow } from '../../../../../redux/informationmenu/InformationMenu'
import { makeRequest } from '../../../../../api/apihook'
import { dispatchExperts } from '../../../../../api/utils'
import { useSnackbar } from 'notistack'


export const AssignExperts = ({}) => {

    const dispatch = useDispatch()
    const expertDatas = useSelector(state => state.InformationMenu.expertInfos)
    const chaptersDatas = useSelector(state => state.InformationMenu.chaptersListing)
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)
    const clientID = useSelector(state => state.InformationMenu.clientId)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    
    const [changed, setChanged] = useState(false);
    const [clientSelected, setClientSelected] = useState(false);

    useEffect(() => {
        if (!clientSelected) {
            // Afficher la notification quand le composant est monté
            enqueueSnackbar("Veuillez sélectionner d'abord un expert", {
                variant:"info",
                persist:true
            })
        } else {
            closeSnackbar();
        }
    }, [clientSelected]);
    
    const sendInfos = (ownerID, data) => {
        if (ownerID === undefined){
            return
        }
        const email = expertDatas.allUsers.filter((line) => line.id === ownerID)[0].text[2];
        const tmp = data.map((v, k) => {
            return v
                ? {
                      label: chaptersDatas[k].label,
                      assignTo: {
                          email: email,
                          uuid: ownerID,
                      },
                  }
                : chaptersDatas[k].assignTo.uuid === ownerID
                  ? {
                        label: chaptersDatas[k].label,
                        assignTo: {
                            email: '',
                            uuid: '',
                        },
                    }
                  : chaptersDatas[k];
        });
        dispatch(setListingChapters(tmp));
        const datasChapters = {
            userID : ownerID,
            chapters : tmp.filter(v => v.assignTo.uuid === ownerID).map(v => {return v.label}),
            clientID : clientID
        };
        
        makeRequest('put', '/assignChapters', tokenRefresh, datasChapters);
        setChanged(true);
    };

    const close = () => {
        if (changed) {
            makeRequest("put", "/sendEmailRecap/" + clientID, tokenRefresh)
        }
        dispatchExperts({dispatch : dispatch, tokenRefresh : tokenRefresh, clientID : clientID,  callback : ()=>{dispatch(setOpenChaptersWindow(false)); closeSnackbar(); setChanged(false)}});
        
    };

    const handleChapterSelection = (position, callback) => {
        const chapterOwner = chaptersDatas[position].assignTo.uuid;
        
        if (chapterOwner && chapterOwner !== clientID) {
            enqueueSnackbar('Ce chapitre est déjà assigné à un autre expert. Voulez-vous continuer ?', {
                variant: 'warning',
                action: (id) => (
                    <ConfirmationSnackBar
                        selectConfirm={() => {
                            callback()
                            closeSnackbar(id)
                        }}
                        selectDismiss={() => {
                            closeSnackbar(id)
                        }}
                    />
                ),
            });
        } else {
            callback();
        }
    };





    return(
        <ModalChaptersAndTable 
            title="Assigner des chapitres"
            onClose={close}
            doSelectChapter={sendInfos}
            autoOptions={expertDatas.allUsers}
            chaptersDatas={chaptersDatas}
            defaultList={true}
            onChapterSelection={handleChapterSelection} // Ajout de la fonction de rappel
            setClientSelected={setClientSelected}
        />
    );
};
