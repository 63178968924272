import { createSlice } from '@reduxjs/toolkit'

export const SearchEngineSlice = createSlice({
    name: 'SearchEngine',
    initialState: {
        open: false,
        show: false,
        showDetailsPanel: false,
        localEntries: {},
        loincSelected: undefined,
        autoComplete: [],
        filter: {},
        listingFilter: undefined,
        listingFilterNewName: '',
        saveFilter: false,
        search: {
            head: [],
            body: [],
        },
        detailsPanel: {
        },
    },
    reducers: {
        setIsOpen: (state, action) => {
            state.open = action.payload
            return state
        },
        setShowSearchEngine: (state, action) => {
            state.show = action.payload
            return state
        },
        setShowDetailsPanel: (state, action) => {
            state.showDetailsPanel = action.payload
            return state
        },
        setLocalEntries: (state, action) => {
            state.localEntries = action.payload
            return state
        },
        setLOINCSelected: (state, action) => {
            state.loincSelected = action.payload
            return state
        },
        setShowAutoComplete: (state, action) => {
            state.autoComplete = action.payload
            return state
        },
        setShowFilter: (state, action) => {
            state.filter = action.payload
            return state
        },
        setShowListingFilter: (state, action) => {
            state.listingFilter = action.payload
            return state
        },
        setShowNewFilterName: (state, action) => {
            state.listingFilterNewName = action.payload
            return state
        },
        setShowSaveFilter: (state, action) => {
            state.saveFilter = action.payload
            return state
        },
        setDetailsPanel: (state, action) => {
            state.detailsPanel = action.payload
            return state
        },
    },
})

export const {
    setIsOpen,
    setShowSearchEngine,
    setShowDetailsPanel,
    setLocalEntries,
    setLOINCSelected,
    setShowAutoComplete,
    setShowFilter,
    setShowListingFilter,
    setShowNewFilterName,
    setShowSaveFilter,
    setDetailsPanel,
} = SearchEngineSlice.actions
