import { ModalImportFiles, ModalWindow } from 'onaosreact'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setClientInfosFile, setOpenFileImport, setTableFileDatas } from '../../../../redux/filemenu/FileMenu'
import { makeRequest } from '../../../../api/apihook'
import { useSnackbar } from 'notistack'






export const ImportFile = ({}) => {
    const [clientID, setClientID] = useState("")
    const clientsInfos = useSelector(state => state.FileMenu.clientInfos )
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const dispatch = useDispatch()


    const importFileRequest = (datas) => {

        const fileDatas = new FormData()
        Object.entries(datas).forEach((v,k) => {
            fileDatas.append('File[]', v[1].file.filePath)
            fileDatas.append('Name[]',  v[1].name)
            fileDatas.append('Type[]',v[0])

        })
        let url = ""
        if (clientID === "") {
            url = "/addFile" //route for user => not used
        } else {
            url = "/addFileForClient" // route block if not admin/onaos expert
            fileDatas.append('forClient',clientID)
        }

        makeRequest("post", url, tokenRefresh, fileDatas).then(resp => {
            if (resp.data) {
                dispatch(setTableFileDatas(resp.data.clientsFiles))
                dispatch(setOpenFileImport(false))
            }
        })

    }

    const updateClientsDB = () => {
        //confirmation!r
        enqueueSnackbar('Synchonisation en cours, merci de patienter', { 
            variant: 'info',
            persist: true,
        }) 
        makeRequest('get', '/updateClientsDB', tokenRefresh).then(resp => {
            closeSnackbar()
            if (resp.data) {
                if (resp.data.clients) dispatch(setClientInfosFile(resp.data.clients))
            }
        })
    }

    return(
        <ModalWindow style = {{   width : "1296px",height : "max-content", minHeight : "450px",  backgroundColor : '#f7f7f7', padding : "16px"}}>
            <ModalImportFiles 
                title = "Ajouter des fichiers"
                onClose = {()=>dispatch(setOpenFileImport(false))}
                onClick = {importFileRequest}
                clientsList = {clientsInfos}
                windowStyle = {{minHeight : "450px"}}
                onClientSelect = {(clientID) => setClientID(clientID)}
                onUpdate = {updateClientsDB}
            />
        </ModalWindow>
    )


}