import { DashBoardTableObject } from 'onaosreact'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkReportLine } from '../../../../redux/veillemenu/VeilleMenu'


export const VeilleClient = ({}) => {

    const listing = useSelector(state => state.VeilleMenu.reportslisting)
    const isVeilleCustomer = useSelector(state => state.VeilleMenu.updateCustomer)
    const dispatch = useDispatch()

  
    const onClickLine = (id, position) => {

  
    }
  

    const onCheckLine = (lineID, position) => {
      dispatch(checkReportLine(position))
    }
  

    return (
      <React.Fragment>
        {
          isVeilleCustomer && (listing.body === undefined || listing.body.length === 0) && 
          <div style = {{display : 'flex', padding : '0 16px 16px 16px'}}>
            <p>A ce jour vos transcodages LOINC ont été réalisés avec la dernière version publiée de la terminologie LOINC.
              Nous vous invitons donc à consulter ce menu après publication d'une nouvelle version de la terminologue. <br/><br/>
              Vous recevrez un mail vous informant de la disponibilité d'une nouvelle étude d'impacts.
              </p>
          </div>
        }

        {
          !isVeilleCustomer && 
          <div style = {{display : 'flex', padding : '0 16px 16px 16px'}}>
            <p>Pour accéder à votre étude d'impacts suite à la mise à jour LOINC, nous vous invitons à vous rapprocher de notre <a href="mailto:service-client@onaos.com" >service client</a> pour souscrire à notre solution de veille et mise à jour automatique.</p>
          </div>
        }

        {
        isVeilleCustomer && listing.body !== undefined && listing.body.length > 0 &&
          <DashBoardTableObject 
              body = {listing.body}
              head = {listing.head}
              maxCarac = {25}
              onClick = {onClickLine}
              borderLineStyle = {{height : "48px", borderBottom : '1px solid #ECECEC'}}
              footStyle = {{height : "150px" }}
              checkbox = {true}
            //  stateChangeAll = {()=>console.log("stateall")}
              headStyle  ={{zIndex : 20, height : "48px"}}
              onChange = {onCheckLine}
          />
        }
      </React.Fragment>

    )


}