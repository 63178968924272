import React from 'react'
import { TitleVeille } from './TitleVeille'

export const HeadVeilleOnaos = ({}) => {
    const containerTitleStyle = {
        padding: '0 16px',
    }

    return (
        <div style={containerTitleStyle}>
            <TitleVeille />
        </div>
    )
}
