import { createSlice } from "@reduxjs/toolkit";



export const FileMenuSlice = createSlice({
    name : "FileMenu",
    initialState:{
        tabLabel : [],
        selectedTab : 0,
        tableDatas : {
            body : [],
            head : []
        },
        openImport : false,
        clientInfos : [],
        filters : {}
    },
    reducers: {
        setTabFileLabel : (state, action) => {
            state.tabLabel = action.payload
            return state            
        },
        setActiveFileTab : (state, action) => {
            state.selectedTab = action.payload
            return state
        },
        setTableFileDatas : (state, action) => {
            state.tableDatas = action.payload
            return state
        },
        setOpenFileImport : (state, action) => {
            state.openImport = action.payload
            return state
        },
        setClientInfosFile : (state, action) => {
            state.clientInfos = action.payload
            return state
        },
        setExportDicoCheck : (state, action) => {
            state.tableDatas.body = state.tableDatas.body.map(v => {
                return v.id === action.payload ? {...v, check : v.check === undefined ? true : !v.check} : v
              })
            return state 
        },
        setDicoFilters : (state, action) => {
            state.filters = action.payload
            return state            
        },
    }
})


export const {setTabFileLabel, setActiveFileTab, setExportDicoCheck,
    setTableFileDatas, setOpenFileImport, setClientInfosFile, setDicoFilters} = FileMenuSlice.actions;