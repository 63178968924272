import React from 'react'

import { DivsLine, NeuMorphismeFlex, TitleDashboard } from 'onaosreact'
import { useSelector } from 'react-redux'

export const AdminInfos = ({ isEditable }) => {
    const adminInfos = useSelector((state) => state.InformationMenu.adminInfos)

    const divLineStyle = [{ fontWeight: 'bold', fontSize: '10px' }, { fontSize: '13px' }]

    const neumorphismeStyle = {
        height: '',
        padding: '16px 0 0',
        gap: '16px',
    }

    const titleStyle = {
        fontSize: '20px',
        padding: '0 16px',
    }

    const containeStyle = {
        display: 'flex',
        flexDirection: 'column',
    }

    const setCellStyle = {
        width: '15%',
    }

    return (
        <NeuMorphismeFlex style={neumorphismeStyle}>
            <TitleDashboard text='Informations administrateur' style={titleStyle} />
            <div style={containeStyle}>
                <DivsLine textStyle={divLineStyle} cells={['ADMINISTRATEUR', adminInfos.fullName]} cellStyle={setCellStyle} />
                <DivsLine textStyle={divLineStyle} cells={['EMAIL', adminInfos.email]} cellStyle={setCellStyle} />
                <DivsLine textStyle={divLineStyle} cells={['CONTACT', adminInfos.phone]} cellStyle={setCellStyle} />
            </div>
        </NeuMorphismeFlex>
    )
}
