import axios from 'axios';

const appConfig = process.env.REACT_APP_API_ENDPOINT

const tokenKey = process.env.REACT_APP_TOKEN_KEY

 
  const refreshToken = async (tokenrefresh) => {
    try {
      const token = await api.get('/tokenrefresh/'+tokenrefresh)
      localStorage.setItem(tokenKey,token.data.token)
      return token.data.token
    } catch (e) {
      console.error('Erreur lors du renouvellement du token :', e)
    }
  }

  const api = axios.create({
    baseURL: appConfig,
  });

  const requestAgain = async (method, url, data = null, responseType = 'json') => {
    try {
      const rep =  await api({
        method:method,
        url:url,
        data : data,
        responseType : responseType
      })
      return {...rep, error : null}
    } catch (e) {
      return {error : e}
    }
    
  }

  export const makeRequest = async (method, url, tokenRefresh, data = null, responseType = 'json') => {
    try {
      api.defaults.headers.common['token'] = localStorage.getItem(tokenKey);
      const response = await api({
        method:method,
        url:url,
        data : data,
        responseType : responseType
      })
      return {...response, error : null}
    } catch(e) {
      if (e.response && e.response.status === 401) {
        const response = await refreshToken(tokenRefresh)
        api.defaults.headers.common['token'] = response
        const resp2 = await requestAgain(method, url, data, responseType)
        return {...resp2, error : null}
      } else {
        return {error : e}
      }

  }
}




