import { ConfirmationSnackBarAccessStopShow,  DashBoardTableObject } from 'onaosreact'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { setExportButtonAvailable, setIdsExport } from '../../../../redux/exportmenu/ExportMenu'
import { setAllEntries, setEntriesIDActive, setLocalInfos, setLoincInfos, setNavClientPart, setOtherInfos, setUpdateCardInfos, stopShowAccessDanger } from '../../../../redux/transcoding/transcoding'
import { makeRequest } from '../../../../api/apihook'
import { useSnackbar } from 'notistack'
import { setExportLineCheck } from '../../../../redux/transcodingmenu/TranscodingMenu'



export const TableClient = ({}) => {

    const transcoTable = useSelector(state=>state.TranscodingMenu.transcodingTable)
    const showAccessDanger = useSelector(state => state.Transcoding.showAccessDanger)
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    useEffect(()=> {
      const idCheck = transcoTable.body.filter(line => line.check === true).map(line => {
         return line.id
      })            
      dispatch(setIdsExport(idCheck))
      dispatch(setExportButtonAvailable(idCheck.length > 0))
    }, [transcoTable])


    const returnDashboard = id => (
      <ConfirmationSnackBarAccessStopShow
          selectConfirm = {() => {closeSnackbar(id)}}
          selectDismiss = {() => {closeSnackbar(id); navigate("/mapper/transcodage")}}
          selectStop = {() => {dispatch(stopShowAccessDanger()); closeSnackbar(id); navigate("/mapper/transcodage")}}
      />
    );

  
    const onClickLine = (id, position) => {
        const collectionsInfos = {
          ids : id,
          chapter : "all",
          kindEntry : "all",
          addAllEntries : true    
        }
  
        makeRequest("put", "/collection", tokenRefresh, collectionsInfos).then(resp => {
          if (resp.data) {
            if (resp.data.allEntries && resp.data.localInfos) {
              dispatch(setAllEntries({all : resp.data.allEntries, id : resp.data.localInfos.id}))
            } else {
              dispatch(setAllEntries({all : [], id : -1}))
            }
            if (resp.data.localInfos) {
              dispatch(setLocalInfos(resp.data.localInfos)) 
            } else {
              dispatch(setLocalInfos({})) 
            }
            if (resp.data.loincInfos) {
              dispatch(setLoincInfos(resp.data.loincInfos)) 
            } else {
              dispatch(setLoincInfos([]))
            }



            if (resp.data.otherInfos) dispatch(setOtherInfos(resp.data.otherInfos))
            if (resp.data.majInfos) dispatch(setUpdateCardInfos(resp.data.majInfos))
            if (resp.data.nav && resp.data.nav.clientBlock && resp.data.nav.clientBlock.chapitres) dispatch(setNavClientPart(resp.data.nav.clientBlock))  
            dispatch(setEntriesIDActive(id))         

            if (resp.data.allDone && showAccessDanger) {
              enqueueSnackbar("Attention cette collection a déjà été vérifiée. Pensez à télécharger vos exports avant toutes modifications", {
                action : returnDashboard,
                variant: 'warning',
                persist: true,
              })
            } else {
              navigate("/mapper/transcodage")
            }

           
          } //gestion erreur ? 
        })
  
      }
  

    const onCheckLine = (lineID) => {
        //TODO
        dispatch(setExportLineCheck(lineID))
      }
  

    return (
        <DashBoardTableObject 
            body = {transcoTable.body}
            head = {transcoTable.head}
            maxCarac = {25}
            onClick = {onClickLine}
            borderLineStyle = {{height : "48px", borderBottom : '1px solid #ECECEC'}}
            footStyle = {{height : "150px" }}
            checkbox = {true}
          //  stateChangeAll = {()=>console.log("stateall")}
            headStyle  ={{zIndex : 20, height : "48px"}}
            onChange = {(lineID) =>onCheckLine(lineID)}
        />
    )


}