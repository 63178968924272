import React from 'react'
import { TitleTransco } from './TitleTransco'
// import { ExportButton } from '../../Export/ExportButton'

export const HeadOnaos = ({}) => {
    const containerTitleStyle = {
        padding: '0 16px',
    }

    return (
        <div style={containerTitleStyle}>
            <TitleTransco />
        </div>
    )
}
