import React from 'react'
import { OnaosAccessTraceability } from './OnaosAccess/OnaosAccessTraceability'
import { ClientAccessTraceability } from './ClientAccess/ClientAccessTraceability'
import { NeuMorphismeFlex } from 'onaosreact'
import { useSelector } from 'react-redux'

export const Traceability = ({}) => {
    const access = useSelector((state) => state.log.access)

    const getFileComponents = () => {
        const isOnaos = access.isAdminOnaos || access.isExpertOnaos || access.isThirdExpertOnaos
        if (isOnaos) {
            return <OnaosAccessTraceability />
        }
        return <ClientAccessTraceability />
    }

    return <NeuMorphismeFlex style={{ justifyContent: '', padding: '16px 0', flexGrow: 1, gap: '16px' }}>{getFileComponents()}</NeuMorphismeFlex>
}
