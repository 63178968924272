import { createSlice } from "@reduxjs/toolkit";



export const GoldMessageSlice = createSlice({
    name : "GoldMessage",
    initialState:{
        infos : {
            show : false,
            code : ''
        },
        saveDatas : {},
        response : ''
    },
    reducers: {
        showModalGold : (state, action) => {
            state.infos.show = action.payload
            return state
        },
        setLOINCCode : (state, action) => {
            state.infos.code = action.payload
            return state
        },
        setSaveDatas : (state, action) => {
            state.saveDatas = action.payload
            return state
        },
        setResponse : (state, action) => {
            state.response = action.payload
            return state
        }
    }
})



export const {showModalGold, setLOINCCode, setSaveDatas, setResponse} = GoldMessageSlice.actions;