import { ModalDoTranscode, ModalDoTranscodePreselection, ModalWindowWTabs } from 'onaosreact'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showTranscodingWindow } from '../../../../../../redux/filemenu/TranscodingModal'
import { setActiveFileTab } from '../../../../../../redux/filemenu/FileMenu'
import { setDatasForConfirmation, setDatasForConfirmationTitleandFor } from '../../../../../../redux/filemenu/PreviewMenu'



export const Transcoding = ({}) => {
    const [tabTranscoActif, setTabTransco] = useState(2)
    const [clientID, setClientID] = useState("")
    const [tilesInfos, setTilesInfos] = useState([])
    const [preselectFrom, setPreselectFrom] = useState("")


    const clientListing = useSelector(state => state.TranscodingModal.clientListing)
    const clientDatas = useSelector(state => state.TranscodingModal.tableClientInfos)
    const fileID = useSelector(state => state.PreviewMenu.activeFileID)
    const tilesDatas = useSelector(state => state.TranscodingModal.tilesDatas)

   


    const dispatch = useDispatch()


    const onTranscode = () => {
        const APIInfos = {
            fileID : fileID,
            transcodingType : tabTranscoActif,
            preselect : {
                fromClient : clientID,
                attributs : tilesInfos,
                fromCollection : preselectFrom
            }
        }
        console.log("APIInfos : ", APIInfos)
        close()
    }


    const close = () => {
        dispatch(showTranscodingWindow(false))
        dispatch(setDatasForConfirmation({}))
        dispatch(setDatasForConfirmationTitleandFor({title : "", for :""}))
        dispatch(setActiveFileTab(0))

    }

    return(
        <ModalWindowWTabs 
            style = {{   width : "1296px", height : "660px", backgroundColor : '#f7f7f7', padding : "16px"}}
            tabsTitle = { [ {title : "Présélection expert" }, {title : "Présélection client"}, {title : "Transcodage"}]}
            tabClick = {setTabTransco}
            tabActive = {tabTranscoActif}
        >
        {(tabTranscoActif === 0  || tabTranscoActif === 1) && 
            <ModalDoTranscode 
                onClose = {close}
                title = {tabTranscoActif === 0 ? "Présélection expert" : "Présélection client"}
                onClick = {onTranscode}
                disabledTranscode = {clientID === "" || preselectFrom === "" || tilesInfos.filter(line => line.check === true).length === 0}
            >
                <ModalDoTranscodePreselection 
                    clientsList = {clientListing}
                    onClientSelect = {setClientID}
                    clientsData = {clientDatas}
                    onTileSelect = {setTilesInfos}
                    attributsTiles = {tilesDatas}
                    onCollectionChoice = {setPreselectFrom}
                    
                />
            </ModalDoTranscode>
        }

        {tabTranscoActif === 2 && 
            <ModalDoTranscode 
                onClose = {close}
                title = "Transcodage"
                onClick = {onTranscode}
            />
        }

    </ModalWindowWTabs>
    )

}
