import { ConfirmationSnackBar, DashBoardTableObject, Search, SubMenuHelp, ThreeDotMenu } from 'onaosreact'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { makeRequest } from '../../../api/apihook'
import { setClientsListing } from '../../../redux/clients/ClientsMenu'



export const ClientsTable = ({}) => {
  const listing = useSelector(state => state.ClientsMenu.clientsListing)
  const filtersInit = useSelector(state => state.ClientsMenu.filters)
  const tokenRefresh = useSelector((state) => state.log.tokenRefresh)

  const allClients = useSelector(state => state.InformationMenu.allClients)

  const [filters, setFilter] = useState(JSON.parse(JSON.stringify(filtersInit)))
  const [clientsAuto, setClientsAuto] = useState([])
  const [msgAction, setMsgAction] = useState({ id: '', url: '', msg: '' })
  const [requestSearchInfos, setRequestInfos] = useState({})


  const dispatch = useDispatch()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    if (msgAction.id === '' || msgAction.id === undefined) return
    enqueueSnackbar(msgAction.msg, {
        key: msgAction.id,
        action,
        variant: msgAction.variant !== undefined ? msgAction.variant : 'warning',
        autoHideDuration: 5000,
    })
  }, [msgAction])

  const action = (id) => (
    <ConfirmationSnackBar
        selectConfirm={() => {
            sendToClient()
            closeSnackbar(id)
        }}
        selectDismiss={() => {
            closeSnackbar(id)
        }}
    />
  )

  const sendToClient = () => {

    const id = msgAction.id.replace('%', '')
    const url = msgAction.url
    makeRequest('get', '/' + url + '/' + id, tokenRefresh).then((resp) => {
        if (resp.data) {
            makeRequest('put', '/searchClientsListing', tokenRefresh, requestSearchInfos).then((resp) => {
                if (resp.data && resp.data.listing) dispatch(setClientsListing(resp.data.listing))
                if (resp.data && resp.data.filters ) setFilter(resp.data.filters)
              })
        }
    })
  }


  useEffect(()=>{
    const clientsDtas = allClients.map(v => {
        return v.text[0]
    })
    setClientsAuto(clientsDtas)
  }, [allClients])

  const onSearch = (text, tags) => {
    enqueueSnackbar('Recherche en cours...', {
      variant: 'info',
      persist: true,
    })
    const datas = {
      client: text,
      type : 'clients',
      filters: tags,
    }
    
    makeRequest('put', '/searchClientsListing', tokenRefresh, datas).then((resp) => {
      if (resp.data) setRequestInfos(datas)
      if (resp.data && resp.data.listing) dispatch(setClientsListing(resp.data.listing))
      if (resp.data && resp.data.filters ) setFilter(resp.data.filters)
      closeSnackbar()
    })
  }

  

  const onClickLine = (id, position) => {

  
  }

  const openEvent = (lineClick, obj, lineID) => {
    switch (obj.params) {
        case 'update':
            setMsgAction({ id: lineID, url: 'clientupdate', msg: "Créer des collections 'Veille' pour ce dictionnaire ?" })
            break
        case 'report':
            setMsgAction({ id: lineID, url: 'clientreport', msg: 'Créer un rapport de mise à jour ?' })
            break
    }
}


  const subMenuData = [
    { label: 'Rapport de veille', onClick: openEvent, params: 'report' },
    { label: 'Veille', onClick: openEvent, params: 'update' },
  ]

  return (

      <Search
          searchLabel = "Rechercher un client"
          searchStyle = {{height : '32px', boxSizing: 'border-box', gap : '16px'}}
          blocSearchStyle = {{justifyContent : 'space-between', padding : '0 16px 16px', marginBottom : '', justifyContent:'center'}}
          styleBlockSearch = {{ gap : '16px', padding : "0 16px" }}
          defaultInputValue = ""
          searhcAndFilterWidth = "632px"
          filterData = {filters}
          // filterSize = {{width: '336px'}}
          onSearch = {onSearch}
          autoCompletDatas = {clientsAuto}
          removePage = {true}
          blockStyle= {{marginTop : "0px", height : '94%'}}
          tagStyle = {{width : '100%', padding : '0 16px 16px', boxSizing : 'border-box'}}
          allowEmpty = {true}
          openBlockSearchStyle = {{borderRadius : "5px 5px 0 0"}}
      >
          <DashBoardTableObject 
              body = {listing.body}
              head = {listing.head}
              onClick = {onClickLine}
              borderLineStyle = {{height : "48px", borderBottom : '1px solid #ECECEC'}}
              headStyle  ={{zIndex : 20, height : "48px"}}
              footStyle = {{height : "150px" }}
              style = {{overflow : 'auto'}}
              ThreeDotMenu={
                <ThreeDotMenu>
                    <SubMenuHelp datas={subMenuData} style={{ top: '28px', right: '5px', zIndex: 12 }} />
                </ThreeDotMenu>
            }

          />
    </Search>
  )


}