import { DashBoardTableObject, NeuMorphismeFlex, TitleDashboard } from 'onaosreact'
import React from 'react'
import { useSelector } from 'react-redux'

export const ExpertsUser = ({}) => {
    const expertDatas = useSelector((state) => state.InformationMenu.expertInfos)

    const neumorphismeStyle = {
        height: '',
        padding: '16px 0',
        justifyContent: '',
        flexGrow: 1,
        gap: '16px',
    }

    const expertsStyle = {
        fontSize: '20px',
        padding: '0 16px',
    }

    const chapterStyle = {
        fontSize: '16px',
        padding: '0 16px',
    }

    const tableChapterStyle = {
        height: '',
        overflow: '',
    }

    const tableLineStyle = {
        height: '64px',
        borderBottom: '1px solid #ECECEC',
    }

    const tableChapterFootStyle = {
        height: '16px',
    }

    const tableDemResStyle = {
        fontSize: '16px',
        padding: '0 16px',
    }

    return (
        <NeuMorphismeFlex style={neumorphismeStyle}>
            <TitleDashboard text='Experts' style={expertsStyle} />
            <TitleDashboard text='Responsable du dictionnaires de Chapitres' style={chapterStyle} />
            <DashBoardTableObject
                style={tableChapterStyle}
                body={expertDatas.chapter || [{ show: true, text: ['Aucun expert assigné'], id: -1 }]}
                borderLineStyle={tableLineStyle}
                footStyle={tableChapterFootStyle}
            />
            <TitleDashboard text='Responsables des dictionnaires de Demandes et de Résultats' style={tableDemResStyle} />
            <DashBoardTableObject body={expertDatas.resdem} maxCarac={[10, 10, 30, 40, 40, 60]} borderLineStyle={tableLineStyle} />
        </NeuMorphismeFlex>
    )
}
