import { createSlice } from "@reduxjs/toolkit";


export const ConfirmationAndEditionSlice = createSlice({
    name : "ConfirmationAndEdition",
    initialState:{
        table : {
            body : [],
            head : [],
        },
        actions : [],
        show : false   ,
        filters : []
    },
    reducers: {
        setTableDatasEdition : (state, action) => {
            state.table = action.payload
            return state
        },
        showWindowConfirmationEdition : (state, action)=>{
            state.show = action.payload
            return state
        },
        checkConfirmationData : (state, action)=>{
            const index = state.table.body.findIndex(line => line.id === action.payload)
            if (index === -1) return state
            state.table.body[index].check = !state.table.body[index].check
            return state
        },
        checkAllConfirmData : (state, action)=>{
            state.table.body = state.table.body.body(v => {
                v.check = action.payload
            })
            return state
        },
        setActions : (state, action)=>{
            state.actions = action.payload
            return state
        },
        setFilters : (state, action)=>{
            state.filters = action.payload
            return state
        },
    }
})


export const {showWindowConfirmationEdition, setTableDatasEdition, 
    checkConfirmationData, checkAllConfirmData, setActions, setFilters
} = ConfirmationAndEditionSlice.actions;