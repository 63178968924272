import { createSlice } from "@reduxjs/toolkit";



export const TranscodingMenuSlice = createSlice({
    name : "TranscodingMenu",
    initialState:{
        transcodingTable : {
            body : [],
            head : []
        },
        allExperts : [],
        tabs : [
        ],
        tabActive : 0,
        filters : {},
        searchSave : {
          client : '',
          filters : {}
        }
    },
    reducers: {
      setSearchSave : (state, action) => {
        state.searchSave.client = action.payload.client 
        const tmp = []
        if (state.searchSave.filters === undefined) {
          state.searchSave.filters = {}
          return state
        }

        Object.entries(action.payload.filters).forEach( v => {
          Object.entries(v[1]).forEach(vv => { 
            vv[1].forEach(value => {
             if (value.check) tmp.push({libelle : value.libelle, title : vv[0] === "" ? v[0] : vv[0], trueTitle :  v[0], trueSubtitle :  vv[0], truePosition : value.position, other : value.other})
           })
         })
        })
        state.searchSave.filters = tmp
        return state
      },
      
      cleanSearchClient : (state, action) => {
        state.searchSave.client = ''
        return state
      },
      setTranscodingTable : (state, action)=> {
        state.transcodingTable = action.payload
        return state
      },
      setAutoCompletionAllExperts : (state, action) => {
        state.allExperts = action.payload
        return state
      }, 

      setFilters : (state, action) => {
        state.filters = action.payload
        return state
      }, 

      clearTranscodingTable : (state, action)=> {
        state.transcodingTable = {
          body : [],
          head : []
        }
        return state
      },
      clearTranscodingBody : (state, action)=> {
        state.transcodingTable.body = []
        return state
      },
      setAllTranscoTabs : (state, action) => {
        state.tabs = action.payload
        return state 
      },
      setTranscoTabActive : (state, action) => {
        state.tabActive = action.payload
        return state
      }, 
      setExportLineCheck : (state, action) => {
        state.transcodingTable.body = state.transcodingTable.body.map(v => {
          return v.id === action.payload ? {...v, check : v.check === undefined ? true : !v.check} : v
        })
        return state
      }
    }
})


export const {
  setTranscodingTable, setAutoCompletionAllExperts, 
  clearTranscodingTable, setAllTranscoTabs, 
  setTranscoTabActive, clearTranscodingBody, 
  setExportLineCheck , setFilters, setSearchSave, cleanSearchClient
} = TranscodingMenuSlice.actions;