import { ConfirmationSnackBar, DashBoardTableObject, DownloadButton, Search, SubMenuHelp, ThreeDotMenu, TitleDashboard } from 'onaosreact'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ExportButton } from '../../../Export/ExportButton'
import {  setExportDicoCheck, setOpenFileImport, setTableFileDatas } from '../../../../../redux/filemenu/FileMenu'
import {
    setActiveFileID,
} from '../../../../../redux/filemenu/PreviewMenu'
import { makeRequest } from '../../../../../api/apihook'
import { cleanHeadTable, setHeadTilesDatas, setTableDatas, showWindow } from '../../../../../redux/filemenu/PreviewMenuHead'
import { useSnackbar } from 'notistack'
import { setExportButtonAvailable, setIdsExport } from '../../../../../redux/exportmenu/ExportMenu'
import { filesPage } from '../../../../../api/utils'
import { setActions, setFilters, setTableDatasEdition, showWindowConfirmationEdition } from '../../../../../redux/filemenu/ConfirmationAndEdition'



export const RawFiles = ({}) => {
    const [msgAction, setMsgAction] = useState({ id: '', url: '', msg: '' })

    const dispatch = useDispatch()
    const exportButtonAvailable = useSelector((state) => state.ExportMenu.exportButtonAvailable)

    const clientListing = useSelector((state) => state.FileMenu.tableDatas)
    const clientInfos = useSelector((state) => state.FileMenu.clientInfos)
    const filtersInit = useSelector((state) => state.FileMenu.filters)
    const tokenRefresh = useSelector((state) => state.log.tokenRefresh)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const [clientsAuto, setClientsAuto] = useState([])
    const [requestSearchInfos, setRequestInfos] = useState({})

    const [filters, setFilter] = useState(JSON.parse(JSON.stringify(filtersInit)))
    


    useEffect(()=>{
        setFilter(JSON.parse(JSON.stringify(filtersInit)))
      }, [filtersInit])


    useEffect(()=>{
        const clientsDtas = clientInfos.map(v => {
            return v.text[0]
        })
        setClientsAuto(clientsDtas)
    }, [clientInfos])


    useEffect(() => {
        if (msgAction.id === '' || msgAction.id === undefined) return
        enqueueSnackbar(msgAction.msg, {
            key: msgAction.id,
            action,
            variant: msgAction.variant !== undefined ? msgAction.variant : 'warning',
            autoHideDuration: 5000,
        })
    }, [msgAction])

    const action = (id) => (
        <ConfirmationSnackBar
            selectConfirm={() => {
                sendToClient()
                closeSnackbar(id)
            }}
            selectDismiss={() => {
                closeSnackbar(id)
            }}
        />
    )

    useEffect(() => {
        const idCheck = clientListing.body
            .filter((line) => line.check === true)
            .map((line) => {
                return line.id
            })
        dispatch(setIdsExport(idCheck))
        dispatch(setExportButtonAvailable(idCheck.length > 0))
    }, [clientListing])

    const loadDirectToConfirm = (lineID, url) => {
        enqueueSnackbar('Chargement en cours', { variant: 'info', persist: true })
        makeRequest('get', url + lineID, tokenRefresh).then((resp) => {
            if (resp.data && resp.data.file) {
                dispatch(setActiveFileID(lineID))
                dispatch(setTableDatasEdition(resp.data.file))
                if (resp.data.actions) dispatch(setActions(resp.data.actions))
                if (resp.data.filters) dispatch(setFilters(resp.data.filters))

                dispatch(showWindowConfirmationEdition(true))
                closeSnackbar()
            } else {
                enqueueSnackbar('une erreur est survenue', { variant: 'error' })
            }
        })
    }

    const openEvent = (lineClick, obj, lineID) => {
        switch (obj.params) {
            case 'transcoding':
                setMsgAction({ id: lineID, url: 'transcode', msg: 'Lancer le transcodage du dictionnaire ?' })
                break
            case 'update':
                setMsgAction({ id: lineID, url: 'update', msg: "Créer des collections 'Veille' pour ce dictionnaire ?" })
                break
            case 'report':
                setMsgAction({ id: lineID, url: 'report', msg: 'Créer un rapport de mise à jour ?' })
                break
            case 'delete':
                setMsgAction({
                    id: lineID,
                    url: 'delete',
                    msg: 'Supprimer le dictionnaire et les collections liées ? (attention action irreversible !)',
                    confirm: true,
                })
                break

            case 'head':
                dispatch(cleanHeadTable())
                enqueueSnackbar('Chargement en cours', { variant: 'info', persist: true })
                makeRequest('get', '/getTiles/' + lineID, tokenRefresh).then((resp) => {
                    if (resp.data && resp.data.tiles) {
                        makeRequest('get', '/getFile/' + lineID, tokenRefresh).then((resp2) => {
                            if (resp2.data && resp2.data.file) {
                                dispatch(setTableDatas(resp2.data.file))
                                dispatch(setHeadTilesDatas(resp.data.tiles))
                                dispatch(setActiveFileID(lineID))
                                dispatch(showWindow(true))
                                closeSnackbar()
                            }
                        })
                    } else {
                        enqueueSnackbar('une erreur est survenue', { variant: 'error' })
                    }
                })

                break
            case 'join':
                loadDirectToConfirm(lineID, '/join/')
                break

            // case 'transcoding' :
            //   title = title === "" ? "Vérification pour transcodage" : title
            // case 'preT' :
            //   title = title === "" ? "Résulat du prétraitement" : title
            // case 'join' :
            //   title = title === "" ? "Résulat des jointures" : title
            //   //ask api with obj.params => return confirmationDatas

            //   dispatch(setDatasForConfirmation(MOCKTABLERAW))
            //   dispatch(setDatasForConfirmationTitleandFor({title : title, for : obj.params}))
            //   dispatch(setPageName('confirm'))
            //   dispatch(setActiveFileID(lineID))
            //   dispatch(setActiveFileTab(1))
            //   break
            // case 'modif' :
            //   onClickLine(lineID, -1)
            //   break
        }
    }

    const sendToClient = () => {
        if (msgAction.confirm === true) {
            console.log('confirm')
            closeSnackbar()
            setMsgAction({ id: msgAction.id + '%', url: msgAction.url, msg: 'Etes-vous sur ?', variant: 'error' })
            return
        }

        const id = msgAction.id.replace('%', '')
        const url = msgAction.url
        makeRequest('get', '/' + url + '/' + id, tokenRefresh).then((resp) => {
            if (resp.data) {
                makeRequest('put', '/searchDicos', tokenRefresh, requestSearchInfos).then((resp) => {
                    if (resp.data && resp.data.clientsFiles) dispatch(setTableFileDatas(resp.data.clientsFiles))
                    if (resp.data && resp.data.filters ) setFilter(resp.data.filters)
                  })
            }
        })
    }

    const subMenuData = [
        { label: 'Modifier les en-têtes', onClick: openEvent, params: 'head' },
        { label: 'Jointures', onClick: openEvent, params: 'join' },
        { label: 'Transcodage', onClick: openEvent, params: 'transcoding' },
        { label: 'Rapport de veille', onClick: openEvent, params: 'report' },
        { label: 'Veille', onClick: openEvent, params: 'update' },
        { label: 'Supprimer le dictionnaire', onClick: openEvent, params: 'delete', style: { color: '#c43c3c' } },

        // { label: "Pré-traitement", onClick: openEvent, params : "preT"},
        // { label: "Remplacer le fichier", onClick: loadNew, params : ""},
        // { label: "Modification du fichier", onClick: openEvent, params : "modif"},
        // { label: "Ajouter des codes oubliés", onClick: openEvent, params : "add"},
    ]

    const onSearch = (text, tags) => {
        enqueueSnackbar('Recherche en cours...', {
          variant: 'info',
          persist: true,
        })
        const datas = {
          client: text,
          type : 'dico',
          filters: tags,
        }

        
        makeRequest('put', '/searchDicos', tokenRefresh, datas).then((resp) => {
          if (resp.data) setRequestInfos(datas)
          if (resp.data && resp.data.clientsFiles) dispatch(setTableFileDatas(resp.data.clientsFiles))
          if (resp.data && resp.data.filters ) setFilter(resp.data.filters)
          closeSnackbar()
        })
    }

    const onClickLine = (id, position) => {
        loadDirectToConfirm(id, '/getFullFile/')
    }

    const onCheckLine = (lineID) => {
        dispatch(setExportDicoCheck(lineID))
    }

    const addFile = () => {
        //check API if admin/expert Onaos => if ok return all client
        dispatch(setOpenFileImport(true))
    }

    return (
        <React.Fragment>
            <TitleDashboard text='Dictionnaires' style={{ fontSize: '20px', padding: '0 16px', margin: '0px' }} />
            {clientListing.body.length > 0 && (
                <Search
                    leftComponent={
                        <div style={{ visibility: 'hidden', display: 'flex', justifyContent: 'start', gap: '16px' }}>
                            <DownloadButton disabled={true} />
                            <ExportButton disabled={true} />
                        </div>
                    }
                    rightComponent={
                        <div style={{ display: 'flex', justifyContent: 'end', gap: '16px' }}>
                            <DownloadButton
                                style={{ width: '168px', height: '32px', padding: '', fontSize: '13px' }}
                                onClick={addFile}
                                text='Ajouter des fichiers'
                                disabled={false}
                            />
                            <ExportButton disabled={!exportButtonAvailable} exportType='full' />
                        </div>
                    }
                    searchLabel='Rechercher une structure'
                    searchStyle={{ height: '32px', boxSizing: 'border-box', gap: '16px' }}
                    blocSearchStyle={{ justifyContent: 'space-between', padding: '0 16px 16px', marginBottom: '' }}
                    styleBlockSearch={{ gap: '16px', padding: '0 16px' }}
                    defaultInputValue=''
                    searhcAndFilterWidth='632px'
                    filterData={filters}
                    // filterSize = {{width: '100%'}}
                    onSearch={onSearch}
                    autoCompletDatas={clientsAuto}
                    removePage={true}
                    blockStyle={{ marginTop: '0px', height: '94%' }}
                    tagStyle={{ width: '100%', padding: '0 16px 16px', boxSizing: 'border-box' }}
                    openBlockSearchStyle={{ borderRadius: '5px 5px 0 0' }}
                    allowEmpty = {true}

                >
                    <DashBoardTableObject
                        body={clientListing.body}
                        head={clientListing.head}
                        maxCarac={25}
                        onClick={onClickLine}
                        borderLineStyle={{ height: '48px', borderBottom: '1px solid #ECECEC' }}
                        headStyle={{ zIndex: 20, height: '48px' }}
                        ThreeDotMenu={
                            <ThreeDotMenu>
                                <SubMenuHelp datas={subMenuData} style={{ top: '28px', right: '5px', zIndex: 12 }} />
                            </ThreeDotMenu>
                        }
                        footStyle={{ height: '150px' }}
                        checkbox={true}
                    //    stateChangeAll={() => console.log('stateall')}
                        onChange={(lineID) => onCheckLine(lineID)}
                    />
                </Search>
            )}
        </React.Fragment>
    )
}
