import { createSlice } from "@reduxjs/toolkit";



export const VeilleMenuSlice = createSlice({
    name : "VeilleMenu",
    initialState:{
        reportslisting : {
            head : [],
            body : []
        },
        filters : {},
        updateCustomer : false
    },
    reducers: {
        setReportsListing : (state, action) => {
            state.reportslisting = action.payload
            return state
        },
        checkReportLine : (state, action) => {
            state.reportslisting.body[action.payload].check = !state.reportslisting.body[action.payload].check
            return state
        },
        setReportsFilter : (state, action) => {
            state.filters = action.payload
            return state
        },
        setUpdateCustomerInfo : (state, action)=> {
            state.updateCustomer = action.payload
            return state
        }
        
    }
})


export const {setReportsListing, checkReportLine, setReportsFilter, setUpdateCustomerInfo} = VeilleMenuSlice.actions;