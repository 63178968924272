import { useSnackbar } from 'notistack'
import { DownloadButton } from 'onaosreact'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeRequest } from '../../../api/apihook'
import { VeillePage } from '../../../api/utils'
import { useNavigate } from 'react-router'
import { setPage } from '../../../redux/dashboardmenu/DashBoardMenu'

export const ExportPDF = ({ disabled }) => {
    const tokenRefresh = useSelector((state) => state.log.tokenRefresh)
    const listing = useSelector((state) => state.VeilleMenu.reportslisting)
    const userAccess = useSelector((state) => state.log.access)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const makeFile = (blob, fileName) => {
        const urld = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = urld
        link.title = 'Rapport'
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(urld)
    }

    const makeNameFile = (type) => {
        if (type === 'zip') return 'Onoas_Transcodage LOINC_EtudesImpacts.zip'
        const line = listing.body.find((line) => line.check)

        return userAccess.isAdminOnaos || userAccess.isExpertOnaos || userAccess.isThirdExpertOnaos
            ? line.text[0] + '_Onaos_Transcodage LOINC_' + line.text[1] + '.pdf'
            : 'Onaos_Transcodage LOINC_' + line.text[0] + '.pdf'
    }

    const requestExport = () => {
        const datas = {
            links: listing.body
                .filter((line) => line.check)
                .map((v) => {
                    return { id: v.id, clientID: v.clientID }
                }),
        }



        enqueueSnackbar('Votre export est en cours de génération, merci de patienter', {
            variant: 'info',
            persist: true,
        })
        makeRequest('put', '/reportDownload', tokenRefresh, datas, 'blob').then((resp) => {
            if (resp.data) {
                console.log(resp.data);
                
                const fileName = datas.links.length > 1 ? makeNameFile('zip') : makeNameFile('pdf')
                makeFile(resp.data, fileName)
                closeSnackbar()
            } else {
                enqueueSnackbar("Une erreur s'est produite", { variant: 'error' })
            }

            // VeillePage({
            //     dispatch: dispatch,
            //     tokenRefresh: tokenRefresh,
            //     callback: () => {
            //         dispatch(setPage(["Veille - Etude d'impacts", 'veille']))
            //         navigate('/mapper/dashboard')
            //     },
            // })


        })
    }

    return <DownloadButton style={{ width: '112px', height: '32px', padding: '', fontSize: '13px' }} onClick={requestExport} disabled={disabled} />
}
