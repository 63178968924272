import { setClientsFilter, setClientsListing } from "../redux/clients/ClientsMenu"
import { setPage } from "../redux/dashboardmenu/DashBoardMenu"
import { setActiveFileTab, setClientInfosFile, setDicoFilters, setTabFileLabel, setTableFileDatas } from "../redux/filemenu/FileMenu"
import { setAdminInfos, setAllClients, setClientActive, setExpertInfos, setListingChapters } from "../redux/informationmenu/InformationMenu"
import { cleanSearchClient, clearTranscodingTable, setAllTranscoTabs, setAutoCompletionAllExperts, setFilters, setSearchSave, setTranscoTabActive, setTranscodingTable } from "../redux/transcodingmenu/TranscodingMenu"
import { setReportsFilter, setReportsListing, setUpdateCustomerInfo } from "../redux/veillemenu/VeilleMenu"
import { makeRequest } from "./apihook"

export const dispatchExperts = ({dispatch, tokenRefresh, clientID, callback}) => {
    const url = clientID === undefined || clientID === -1 ? '/dispatchExperts' : '/dispatchExperts/' + clientID

    makeRequest("get", url, tokenRefresh).then(resp => {
        if (resp.data && resp.data.chaptersLinsting) dispatch(setListingChapters(resp.data.chaptersLinsting))
        if (resp.data && resp.data.expertInfos) dispatch(setExpertInfos(resp.data.expertInfos)) 
        if (callback !== undefined) callback()
    })
}




export const informationPage = ({dispatch, tokenRefresh, addExpert = false, clientID, callback}) => {
    const url = clientID === undefined ? '/informationPage' : '/informationPage/' + clientID

    makeRequest("get", url, tokenRefresh).then(resp => {
        if (resp.data && resp.data.clients) dispatch(setAllClients(resp.data.clients))
        if (resp.data && resp.data.dsi) dispatch(setAdminInfos(resp.data.dsi))
        addExpert && dispatchExperts({dispatch : dispatch, tokenRefresh : tokenRefresh, clientID : clientID})
        if (callback !== undefined) callback()
      })


}

export const filesPage = ({dispatch, tokenRefresh, callback}) => {
    makeRequest("get", "/clientsFiles", tokenRefresh).then(resp => {
        if (resp.data && resp.data.clientsFiles) {
            dispatch(setTableFileDatas(resp.data.clientsFiles))
            if (resp.data.clients) dispatch(setClientInfosFile(resp.data.clients))
            if (resp.data.tabulation) dispatch(setTabFileLabel(resp.data.tabulation))
            if (resp.data && resp.data.filters ) dispatch(setDicoFilters(resp.data.filters))
            dispatch(setActiveFileTab(0))
        }
        if (callback !== undefined) callback()
    })
}

export const VeillePage = ({dispatch, tokenRefresh, callback}) => {
    makeRequest("get", "/reportListing", tokenRefresh).then(resp => {
        if (resp.data && resp.data.listing) {
            dispatch(setReportsListing(resp.data.listing))
            if (resp.data.filter) dispatch(setReportsFilter(resp.data.filter))
            dispatch(setUpdateCustomerInfo(resp.data.isVeille))
        }
        
        if (callback !== undefined) callback()
    })
}


export const menuClick = ({page, dispatch, tokenRefresh, navigate, access, snack}) => {

    const Info = () => {
        informationPage({
            dispatch : dispatch,
            tokenRefresh : tokenRefresh,
            addExpert : true,
            callback  : ()=>{
                dispatch(setClientActive(-1))
                dispatch(setPage(page))
                navigate("/mapper/dashboard");    
            }
        })
    }


    const Files = () => {
        const id = snack[0]('Chargement en cours...', {
            variant: 'info',
            persist: true,
        })
        filesPage({
            dispatch : dispatch,
            tokenRefresh : tokenRefresh,
            callback  : ()=>{
                snack[1](id)
                dispatch(setPage(page))
                navigate("/mapper/dashboard"); 
            }
        })
    }

    const Transco = () => {
        const id = snack[0]('Chargement en cours...', {
            variant: 'info',
            persist: true,
        })
        // dispatch(clearTranscodingTable())
        dispatch(cleanSearchClient())
        makeRequest("get", "/transcodingPage", tokenRefresh).then(resp=>{
            if (resp.data && resp.data.collections) dispatch(setTranscodingTable(resp.data.collections))
            if (resp.data && resp.data.allTabs) dispatch(setAllTranscoTabs(resp.data.allTabs))
            dispatch(setTranscoTabActive(0))

            if (resp.data && resp.data.clients && resp.data.clients.length > 0) dispatch(setAutoCompletionAllExperts(resp.data.clients))
            if (resp.data && resp.data.filters ) dispatch(setFilters(resp.data.filters))
            dispatch(setSearchSave({client:'', filters: resp.data.filters}))
            
            dispatch(setPage(page))
            snack[1](id)
            navigate("/mapper/dashboard");  
        })
    }
    

    const Traca = () => {
        const id = snack[0]('Chargement en cours...', {
            variant: 'info',
            persist: true,
        })
        makeRequest("get", "/clientsFiles/traca", tokenRefresh).then(resp => {
            if (resp.data && resp.data.clientsFiles) {
                dispatch(setTableFileDatas(resp.data.clientsFiles))
                if (resp.data.clients) dispatch(setClientInfosFile(resp.data.clients))
                if (resp.data.tabulation) dispatch(setTabFileLabel(resp.data.tabulation))
                if (resp.data && resp.data.filters ) dispatch(setDicoFilters(resp.data.filters))
                dispatch(setPage(page))
                navigate("/mapper/dashboard");  
            }
            snack[1](id)
        })
    }


    
    const Veille = () => {
        const id = snack[0]('Chargement en cours...', {
            variant: 'info',
            persist: true,
        })
        VeillePage({
            dispatch : dispatch,
            tokenRefresh : tokenRefresh,
            callback  : ()=>{
                dispatch(setPage(page))
                navigate("/mapper/dashboard");   
                snack[1](id) 
            }
        })
    }


    const Clients = () => {
        const id = snack[0]('Chargement en cours...', {
            variant: 'info',
            persist: true,
        })
        makeRequest("get", "/clientsListing", tokenRefresh).then(resp => {
            if (resp.data && resp.data.listing) {
                dispatch(setClientsListing(resp.data.listing))
                if (resp.data.filter) dispatch(setClientsFilter(resp.data.filter))
            }
            dispatch(setPage(page))
            snack[1](id)
            navigate("/mapper/dashboard");  
        })
    }

    snack[1]()
    if (Object.entries(page)[1][1] === "info") return Info()
    if (Object.entries(page)[1][1] === "files") return Files()
    if (Object.entries(page)[1][1] === "transco") return Transco()
    if (Object.entries(page)[1][1] === "traca") return Traca()
    if (Object.entries(page)[1][1] === "veille") return Veille()
    if (Object.entries(page)[1][1] === "clients") return Clients()





}