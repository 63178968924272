import { createSlice } from "@reduxjs/toolkit";



export const ClientsMenuSlice = createSlice({
    name : "ClientsMenu",
    initialState:{
        clientsListing : {
            head : [],
            body : []
        },
        filters : {},
    },
    reducers: {
        setClientsListing : (state, action) => {
            state.clientsListing = action.payload
            return state
        },

        setClientsFilter : (state, action) => {
            state.filters = action.payload
            return state
        },

        
    }
})


export const {setClientsListing, setClientsFilter,} = ClientsMenuSlice.actions;