import React from 'react'

import { AdminInfos } from './AdminInfos/AdminInfos'
import { ExpertsUser } from './Experts/ExpertsUser'
import { useSelector } from 'react-redux'
import { ExpertsAdminUser } from './Experts/ExpertsAdminUser'
import { AssignExperts } from './Experts/AssignExperts/AssignExperts'
import { FindClient } from './FindClient/FindClient'

export const Informations = ({}) => {
    const userAccess = useSelector((state) => state.log.access)
    const openWindows = useSelector((state) => state.InformationMenu.expertInfos.openChapterAssign)
    const clientID = useSelector((state) => state.InformationMenu.clientId)
    const isEditableClient = useSelector((state) => state.InformationMenu.isEditableClient)

    const returnAdminInfosPage = () => {
        if (userAccess.isAdminOnaos || userAccess.isExpertOnaos || userAccess.isThirdExpertOnaos)
            return <React.Fragment>{clientID !== -1 ? <AdminInfos /> : <div></div>} </React.Fragment>
        return <AdminInfos />
    }

    const returnExpertInfosPage = () => {
        if (userAccess.isAdminOnaos || userAccess.isExpertOnaos || userAccess.isThirdExpertOnaos) {
            if (clientID === -1) return <div></div>
            if (isEditableClient) {
                return <ExpertsAdminUser />
            } else {
                return <ExpertsUser />
            }
        }
        if (userAccess.isAdmin) return <ExpertsAdminUser />
        return <ExpertsUser />
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', height: '100%' }}>
            {(userAccess.isAdminOnaos || userAccess.isExpertOnaos || userAccess.isAdmin) && openWindows && <AssignExperts />}
            {(userAccess.isAdminOnaos || userAccess.isExpertOnaos || userAccess.isThirdExpertOnaos) && <FindClient />}
            {returnAdminInfosPage()}
            {returnExpertInfosPage()}
        </div>
    )
}
