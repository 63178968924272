export const internalDiv = {
    mainContainerStyle :  {
        marginLeft:'auto',
        marginRight:'auto',
        width:'95%',
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-between',
        height:'98%',
        backgroundColor : "#F7F7F7",
    },

    containerStyle : {
        marginTop:'0',
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
    

    }
}