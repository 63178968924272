import React, { useEffect, useState } from 'react'
import { DashBoardTableObject } from 'onaosreact'
import { useDispatch, useSelector } from 'react-redux'
import { changeLOINCState, turnSelectedIDToUntreated } from '../../../../../redux/transcoding/transcoding'
import { makeRequest } from '../../../../../api/apihook'
import { useSnackbar } from 'notistack'

export const LOINCCardsTablePart = () => {
    const [loincTableDatas, setLoincDatas] = useState([])
    const { enqueueSnackbar } = useSnackbar()
    const headData = ['Code', 'Composant', 'Milieu', 'Grandeur', 'Temps', 'Échelle', 'Technique', 'Type (international)', 'Type (circuit bio)']

    const userAccess = useSelector((state) => state.log.access)
    const tokenRefresh = useSelector((state) => state.log.tokenRefresh)

    const loincDatas = useSelector((state) => state.Transcoding.loincDatas.cards)
    const updateCard = useSelector((state) => state.Transcoding.updateCard)
    const collectionInfos = useSelector((state) => state.Transcoding.otherInfos)
    const entryID = useSelector((state) => state.Transcoding.clientDatas.id)
    const collectionIDS = useSelector((state) => state.Transcoding.entrieID)

    const dispatch = useDispatch()

    useEffect(() => {
        const transformedData = transformLoincInfosToTableData(loincDatas)
        if (updateCard && updateCard.code) {
            const updatedCardData = transformSingleLoincInfoToTableData(updateCard)
            transformedData.unshift(updatedCardData)
        }
        setLoincDatas(transformedData)
    }, [loincDatas, updateCard])

    const getInternationalType = (type) => {
        switch (type) {
            case 'Observation':
                return 'Résultat'
            case 'Order':
                return 'Demande'
            case 'Both':
                return 'Res. + Dem.'
            default:
                return type || 'Non spécifiée'
        }
    }

    const getBioTypeFull = (type, isWaiting) => {
        if (isWaiting) return getBioType(type) + " (en attente)"
        return getBioType(type)
    }

    const getBioType = (type) => {
        if (!type) return 'Code absent'
        if (type.includes('demande') && type.includes('résultat')) return 'Res. + Dem.'
        if (type.includes('demande')) return 'Demande'
        if (type.includes('résultat')) return 'Résultat'
        if (type.includes('chapitre')) return 'Chapitre'
        return 'Code absent'
    }

    const predefinedMessages = {
        create: ['Créer un code LOINC', 'Vous avez demandé à créer un code LOINC pour cette entrée locale.'],
        notfind: [
            'Créer un code LOINC',
            "Aucun code LOINC actuellement disponible pour cette entrée locale. Sélectionnez cette carte pour demander la création d'un code",
        ],
        untranscodable: ['Ne pas transcoder', "Cette entrée n'est pas une analyse qui peut être transcodée."],
        donottranscode: ['Ne pas transcoder', 'Vous avez demandé à ne pas transcoder cette entrée.'],
        reject: ['Transcodage non validé', 'Vous avez refusé ce transcodage lors de la première lecture.'],
    }

    const getState = (states) => {
        const collectionState = collectionInfos.stateCollection
        switch (collectionState) {
            case 'Review':
                return states['sélectionné review expert'] ? true : false
            case 'Vérification Onaos':
                return states['sélectionné expert'] ? true : false
            case 'Vérification Client':
                if (states['sélectionné client'] === undefined) return undefined
                return states['sélectionné client'] ? true : false
        }
    }

    const getEmpty = (isWaitingCode) => {
        if (isWaitingCode) return "Code en attente"
        return 'Non spécifiée'
    }

    const transformLoincInfosToTableData = (loincInfos) => {
        return loincInfos.map(info => {
            const { Status, attributs = {}, code, fullLib, waiting } = info
            const { Milieu, Grandeur, Temps, Echelle, Technique, Type, TypeBio } = attributs
            const selectedExpert = getState(Status)

            if (predefinedMessages[code]) {
                return {
                    show: true,
                    text: [...predefinedMessages[code], '', '', '', '', '', '', ''],
                    id: code,
                    check: selectedExpert,
                }
            }

            return {
                show: true,
                text: [
                    Number.isInteger(code) ? code.toString() : code,
                    fullLib || 'Non spécifiée',
                    Milieu?.value || 'Non spécifiée',
                    Grandeur?.value || 'Non spécifiée',
                    Temps?.value || 'Non spécifiée',
                    Echelle?.value || 'Non spécifiée',
                    Technique?.value || 'Non spécifiée',
                    getInternationalType(Type?.value),
                    getBioTypeFull(TypeBio?.value, waiting),
                ],
                id: code,
                check: selectedExpert,
                cellColors : getCelltextColor(info),
                backgroundColor : getFontColor(Status, info.Modifications)
            }
        })
    }

    const getFontColor = (states, modifications) => {
        if (modifications[0] !== undefined) return ''
        if (states.fromClient) return '#ebe536'
        if (states.fromOtherClient) return '#FFC7BF'
        return ''
    }

    const getStyleType = (attributs, attributName) => {
        //if resultat and in resultat : green , same dem, sam chap
        const styleGreen = {
            color : '#509f17',
            fontWeight: 'bold',
        }

        const styleStandard = {
            color : '',
        }

        if (attributName !== 'Type' && attributName !== 'Circuit Bio') return styleStandard
        const type =
            attributName === 'Type' ? getInternationalType(attributs['Type']?.value) : getBioType(attributs['TypeBio']?.value)

        if (collectionInfos.typeDico === 'Résultats' && (type === 'Résultat' || type === 'Res. + Dem.')) return styleGreen
        if (collectionInfos.typeDico === 'Demandes' && (type === 'Demande' || type === 'Res. + Dem.')) return styleGreen
        if (collectionInfos.typeDico === 'Chapitres' && type === 'Chapitre') return styleGreen

        return styleStandard
    }


    const getCelltextColor = (info) => {
        const attributs = ['Code', 'Composant', 'Milieu biologique', 'Grandeur', 'Temps', 'Echelle', 'Technique', 'Type', 'Circuit Bio']
        const result = attributs.map(v => {
            return info?.Modifications.includes(v) ? {
                style : {
                    color:'#205E7C',
                    fontWeight: 'bold',
                }
            } : {
                style : getStyleType(info.attributs, v)
            }
        })
        return result
    }   

    const transformSingleLoincInfoToTableData = (info) => {
        const { attributs = {}, code, fullLib, waiting } = info
        const { Milieu, Grandeur, Temps, Echelle, Technique, Type, TypeBio } = attributs

        if (predefinedMessages[code]) {
            return {
                show: true,
                text: [...predefinedMessages[code], '', '', '', '', '', '', ''],
                id: code,
                disabled: true,
            }
        }

        return {
            show: true,
            text: [
                Number.isInteger(code) ? code.toString() : code,
                fullLib || 'Non spécifiée',
                Milieu?.value || 'Non spécifiée',
                Grandeur?.value || 'Non spécifiée',
                Temps?.value || 'Non spécifiée',
                Echelle?.value || 'Non spécifiée',
                Technique?.value || 'Non spécifiée',
                getInternationalType(Type?.value),
                getBioTypeFull(TypeBio?.value, waiting),
            ],
            id: -1,
            disabled: true
        }
    }

    const handleCardClick = (cardDatas) => {
        if (collectionInfos.stateCollection === 'Vérification Client' && !userAccess.isExpert) {
            enqueueSnackbar('Action non autorisée', { variant: 'error', persist: false })
            return
        }
        if (collectionInfos.stateCollection !== 'Vérification Client' && (userAccess.isExpert || userAccess.isAdmin)) {
            enqueueSnackbar('Action non autorisée', { variant: 'error', persist: false })
            return
        }

        const datas = {
            entryID: entryID,
            collectionIDS: collectionIDS,
            cards: {
                code: cardDatas.code,
                actualStatusInfos: cardDatas.Status,
            },
        }

        makeRequest('put', '/clickOnCard', tokenRefresh, datas).then((resp) => {
            if (resp.data) {
                if (resp.data.msg !== undefined && resp.data.msg !== '') {
                    enqueueSnackbar(resp.data.msg, { variant: 'error', persist: false })
                    return
                }
                dispatch(changeLOINCState({ datas: resp.data, type: collectionInfos.stateCollection }))
                dispatch(turnSelectedIDToUntreated(entryID))
            }
        })
    }

    const onCheckClick = (id) => {        
        const loinc = loincDatas.find(line => line.code === id)
        handleCardClick(loinc)
    }

    return (
        <DashBoardTableObject
            head={headData}
            body={loincTableDatas}
            borderLineStyle={{ height: '48px' }}
            headStyle={{ height: '48px' }}
            checkbox={true}
            onChange={(id, index)=>onCheckClick(id)}
            // maxCarac={25}
        />
    )
}
