import { configureStore } from "@reduxjs/toolkit";
import { logSlice } from "../connection/log";
import {DashBoardMenuSlice} from "../dashboardmenu/DashBoardMenu";
import { InformationMenuSlice } from "../informationmenu/InformationMenu";
import { FileMenuSlice } from "../filemenu/FileMenu";
import { PreviewMenuSlice } from "../filemenu/PreviewMenu";
import { PreviewMenuHeadSlice } from "../filemenu/PreviewMenuHead";
import { TranscodingModalSlice } from "../filemenu/TranscodingModal";
import { TranscodingSlice } from "../transcoding/transcoding";
import { ExportMenuSlice } from "../exportmenu/ExportMenu";
import { TranscodingMenuSlice } from "../transcodingmenu/TranscodingMenu";
import { KeyboardSlice } from "../keyboard/keyboard";
import { GoldMessageSlice } from "../modalwindow/goldmessage";
import { SearchEngineSlice } from "../transcodingmenu/SearchEngine";
import { ConfirmationAndEditionSlice } from "../filemenu/ConfirmationAndEdition";
import { VeilleMenuSlice } from "../veillemenu/VeilleMenu";
import { ClientsMenuSlice } from "../clients/ClientsMenu";

export const store = configureStore({
    reducer:{
        log : logSlice.reducer,
        DashBoardMenu : DashBoardMenuSlice.reducer,
        InformationMenu : InformationMenuSlice.reducer,
        FileMenu : FileMenuSlice.reducer,
        PreviewMenu : PreviewMenuSlice.reducer,
        PreviewMenuHead : PreviewMenuHeadSlice.reducer,
        ExportMenu : ExportMenuSlice.reducer,
        TranscodingModal : TranscodingModalSlice.reducer,
        TranscodingMenu : TranscodingMenuSlice.reducer,
        Transcoding : TranscodingSlice.reducer,
        Keyboard : KeyboardSlice.reducer,
        GoldMessage : GoldMessageSlice.reducer,
        SearchEngine : SearchEngineSlice.reducer,
        ConfirmationAndEdition : ConfirmationAndEditionSlice.reducer,
        VeilleMenu : VeilleMenuSlice.reducer,
        ClientsMenu : ClientsMenuSlice.reducer
    },
});