import { createSlice } from "@reduxjs/toolkit";



export const ExportMenuSlice = createSlice({
    name : "ExportMenu",
    initialState : {
        showExport: false,
        exportAvailable: [],
        mapperAvailable: [],
        exportFormatAvailable:[],
        exportButtonAvailable:false,
        exportIDs : [],
        exportType:''
    },
    reducers: {
        setShowExport : (state, action) => {
            state.showExport = action.payload
            return state
        },
        setExportAvailable : (state, action) => {
            state.exportAvailable = action.payload
            return state 
        },
        setExportFormatAvailable : (state, action) => {
            state.exportFormatAvailable = action.payload
            return state 
        },
        setMappingTypeAvailable : (state, action) => {
            state.mapperAvailable = action.payload
            return state
        },
        setExportButtonAvailable : (state, action) => {
            state.exportButtonAvailable = action.payload
            return state 
        },
        setIdsExport : (state, action)=> {
            state.exportIDs = action.payload
            return state
        },
        setExportType : (state, action) => {
            state.exportType = action.payload
            return state 
        }

    }
})


export const {
    setShowExport, setExportAvailable, 
    setExportFormatAvailable, setMappingTypeAvailable,
    setExportButtonAvailable, setIdsExport,setExportType
} = ExportMenuSlice.actions;