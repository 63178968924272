import { createSlice } from "@reduxjs/toolkit";

export const TranscodingModalSlice = createSlice({
    name : "TranscodingModal",
    initialState:{
        showTranscode : false,
        clientListing : [],
        tableClientInfos : {
            body : [],
            head : []
        },
        IDFileToTranscode : "",
        tilesDatas : []
    },
    reducers: {
       showTranscodingWindow : (state, action) => {
        state.showTranscode = action.payload
        return state
       },
       setIDFileToTranscode : (state, action) => {
        state.IDFileToTranscode = action.payload
        return state
       },
       setClientList : (state, action) => {
        state.clientListing = action.payload
        return state
       },
       setClientDatas : (state, action) => {
        state.tableClientInfos = action.payload
        return state
       },
       setTilesDatas : (state, action) => {
        state.tilesDatas = action.payload
        return state
       }
    }
})


export const {showTranscodingWindow, setIDFileToTranscode, 
    setClientList, setClientDatas, setTilesDatas} = TranscodingModalSlice.actions;