import { DownloadButton } from 'onaosreact'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setExportAvailable, setExportFormatAvailable, setExportType, setMappingTypeAvailable, setShowExport } from '../../../redux/exportmenu/ExportMenu'
import { makeRequest } from '../../../api/apihook'




export const ExportButton = ({disabled, exportType }) => {

    const tokenRefresh = useSelector(state=>state.log.tokenRefresh)
    const exportIDs = useSelector(state=>state.ExportMenu.exportIDs)
   
    const dispatch = useDispatch()

    const requestExport = () => {

        const datas = {
            exportType : exportType,
            exportIDs : exportIDs
        }

        makeRequest("put", "/availableExports", tokenRefresh, datas).then(resp => {
            if (resp.data && resp.data.exportType && resp.data.exportFormat && resp.data.exportFile) {
                dispatch(setExportAvailable(resp.data.exportType))
                dispatch(setExportFormatAvailable(resp.data.exportFormat))
                dispatch(setMappingTypeAvailable(resp.data.exportFile))
                dispatch(setExportType(exportType))
                dispatch(setShowExport(true))
            }
        })

     

    }


    return(
        <DownloadButton
            style = {{ width : '112px', height : '32px', padding : '', fontSize : '13px' }}
            onClick = {requestExport}
            disabled = {disabled}
        />
    )


}