import React, { useEffect, useRef, useState } from 'react'

import { ButtonCancel, DashBoardTableObject, DownloadButton, IconButtonNoMargin, ModalWindow, NeuMorphisme, NeuMorphismeFlex, Tiles, TitleAndClose, TitleDashboard } from 'onaosreact'
import { useDispatch, useSelector } from 'react-redux'
import { changeHead, cleanColumn, setActiveColumn, setColumnDone, setHeadTilesDatas, showWindow } from '../../../../../../redux/filemenu/PreviewMenuHead'
import { setActiveFileTab } from '../../../../../../redux/filemenu/FileMenu'
import { setDatasForConfirmation, setDatasForConfirmationTitleandFor, setDatasOtherForConfirmation, setPageName, showWindowConfirmation } from '../../../../../../redux/filemenu/PreviewMenu'
import { makeRequest } from '../../../../../../api/apihook'
import { useSnackbar } from 'notistack'




export const HeadPreview = ({}) => {

    const tilesDatas = useSelector(state => state.PreviewMenuHead.tilesInfos)
    const tableDatas = useSelector(state => state.PreviewMenuHead.table)

    const activeColumn = useSelector(state => state.PreviewMenuHead.columnDatas.activeColumn)
    const columnDone = useSelector(state => state.PreviewMenuHead.columnDatas.columnDone)

    const fileID = useSelector(state => state.PreviewMenu.activeFileID)
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)

    const refScroll = useRef()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const dispatch = useDispatch()

    const changeColumnDone = (state) => {
        const done = state ?  [...columnDone, activeColumn] : columnDone.filter(val => val !== activeColumn)
        dispatch(setColumnDone([...new Set(done)]))
    }

    const changeActiveColumn = () => {
        const activeC = tableDatas.head[activeColumn + 1] !== undefined ? activeColumn + 1 : 0
        dispatch(setActiveColumn(activeC)) 
    }

    const validAndBlock = (position) => {
        const tiles = tilesDatas.map((v, k) => {
            return k === position ? {...v, check : true, block : true} : {...v, check : false}
        })
        dispatch(setHeadTilesDatas(tiles))
        changeColumnDone( tiles[position].check)
    }

    const uncheck = () => {
        const tiles = tilesDatas.map((v, k) => {
            return {...v, check : false} 
        })
        dispatch(setHeadTilesDatas(tiles))
    }

    const unblockAndUncheck = () => {
        const tiles = tilesDatas.map((v, k) => {
            return (v.label === tableDatas.head[activeColumn]) ?  {...v, block : false, check : false} :  {...v,check : false} 
        })
        dispatch(setHeadTilesDatas(tiles))
    }

    


    const validBlockAndUnblock = (position) => {
        const tiles = tilesDatas.map((v, k) => {
            if (k === position) {
                return {...v, check : true, block : true}
            }
            if (v.label === tableDatas.head[activeColumn]) {
                return {...v, check : false, block : false, }
            }

            return {...v, check : false}
        })
        dispatch(setHeadTilesDatas(tiles))
        changeColumnDone( tiles[position].check)
    }

    const uncheckAndUnBlock = (position) => {
        const tiles = tilesDatas.map((v, k) => {
            if (k === position) {
                return {...v, check : false, block : false}
            }
            return {...v, check : false}
        })
        dispatch(setHeadTilesDatas(tiles))
    }

    const changeColumnUndone = (position) => {
        const done = columnDone.filter(val => val !== position)
        dispatch(setColumnDone(([...new Set(done)])))
    }

    


    const changeTileState = (datas, position) => {
        const head = [...tableDatas.head]
        // const tiles = [...tilesDatas]
        if (datas.multiChoice !== true) {
            if (datas.block !== true) {
                if ( tableDatas.head[activeColumn] === tableDatas.originalHead[activeColumn]) {
                    //head and original head are same 
                    head[activeColumn] = datas.label 
                    dispatch(changeHead(head))
                    validAndBlock(position)
                    changeActiveColumn()
                } else {
                    validBlockAndUnblock(position)
                    head[activeColumn] = datas.label 
                    dispatch(changeHead(head))
                    changeActiveColumn()
                }
            } else {
                if ( tableDatas.head[activeColumn] === datas.label) {
                    head[activeColumn] = tableDatas.originalHead[activeColumn]
                    uncheckAndUnBlock(position)
                    changeColumnUndone(activeColumn)
                } else {
                    if (tableDatas.head[activeColumn] === tableDatas.originalHead[activeColumn]) {}
                    const headToChange = tableDatas.head.indexOf(datas.label)
                    head[headToChange] = tableDatas.originalHead[headToChange]
                    uncheckAndUnBlock(position)
                    changeColumnUndone(headToChange)
                }
               
            }


        } else {
            if (datas.label === "Garder en l'état") {
                head[activeColumn] = tableDatas.originalHead[activeColumn]
                if (columnDone.includes(activeColumn)) { //columnDone
                    if (tableDatas.head[activeColumn] === tableDatas.originalHead[activeColumn]) {//by keep state btn => unselect
                        changeColumnDone(false)
                        uncheck()
                    } else {//by other => select and remove old tile
                        changeColumnDone(true)
                        unblockAndUncheck()
                        changeActiveColumn() 
                    }
                } else {//select
                    changeColumnDone(true)
                    changeActiveColumn()
                    uncheck()
                }
            } else {
                if (datas.label === "Ne pas garder") {
                    if (tableDatas.head[activeColumn] === "Ne pas garder") { //unselect
                        head[activeColumn] = tableDatas.originalHead[activeColumn]
                        changeColumnDone(false)   
                    } else {
                        if (tableDatas.head[activeColumn] === tableDatas.originalHead[activeColumn]) {//untreated
                            head[activeColumn] = datas.label     
                            changeActiveColumn()
                            changeColumnDone(true)   
                        } else {//treated by other
                            head[activeColumn] = datas.label     
                            changeActiveColumn()
                            changeColumnDone(true)   
                            unblockAndUncheck()
                        }
                    }
                }
            }         
        }

        dispatch(changeHead(head))
    }

    useEffect(()=>{
        if (activeColumn === -1 || tableDatas.head[activeColumn] === undefined) return;
        const active = tilesDatas.findIndex(data => data.label === tableDatas.head[activeColumn])
        const columnActiveDone = columnDone.includes(activeColumn)
        const tmp = tilesDatas.map((v, k) => {
            return k === active && columnActiveDone ? {...v, check : true} : {...v, check : false}
        })
        dispatch(setHeadTilesDatas(tmp))
    }, [activeColumn])



    const onChangeHead = (position) => {
        dispatch(setActiveColumn(position))
    }

    useEffect(()=> {
        onScroll()
    }, [activeColumn])

    
    const onScroll = () => {
        refScroll?.current?.scrollIntoView(
            { behavior: 'auto', block: 'nearest', inline: 'start' }
        )
    }
    


    
    const textStyle = {
        fontFamily : "Montserrat",
        color : '#205E7C',
        fontSize : '13px',
        fontWeight : 'bold',
    }

    const reset = () => {
        //ask msg for confirm !!
        dispatch(changeHead(tableDatas.originalHead))
        const tiles = tilesDatas.map(v => {
            return v.block !== undefined ? {...v, check : false, block: false} : {...v, check : false}
        })
        dispatch(setHeadTilesDatas(tiles))
        dispatch(cleanColumn())

    }

    const closePreview = () => {
        // dispatch(setActiveFileTab(0))
        dispatch(cleanColumn())
        dispatch(showWindow(false))
    }

    const onValidate = () => {
        const datas = {
            head : tableDatas.head,
            fileID : fileID
        }

        enqueueSnackbar("Chargement en cours", {variant: 'info', persist : true})
        makeRequest("put", "/renameColumn", tokenRefresh, datas).then(resp => {
            if (resp.data && resp.data.file) {
                dispatch(setDatasOtherForConfirmation(tableDatas.head))
                dispatch(setDatasForConfirmation(resp.data.file))
                dispatch(setDatasForConfirmationTitleandFor({title : "Vérification des nouvelles en-têtes", for : "head"}))
                dispatch(showWindow(false))
                dispatch(cleanColumn())
                dispatch(showWindowConfirmation(true))
                closeSnackbar()
            } else {
                enqueueSnackbar("Une erreur est survenue", {variant: 'error'})
            }
        })



    }

    return(
        <React.Fragment>
            <TitleAndClose 
                title = "Modifier les en-têtes"
                style = {{fontSize : "20px", paddingLeft : "16px", margin : '0px'}}
                onClose = {closePreview}
            >
                <div style = {{paddingRight : "40px", cursor : 'pointer'}}>
                    <span style = {textStyle} onClick={reset}>Revenir au fichier d'origine</span>
                </div>
            </TitleAndClose>
            <NeuMorphismeFlex style = {{height  : "", justifyContent : '',  paddingTop : "16px", gap : "16px", margin : "8px 16px 16px 16px" , overflow : '' }}>
                <TitleDashboard 
                    text = "Sélectionner le type d'en-tête pour la colonne en cours"
                    style = {{fontSize : "13px", paddingLeft : "16px", margin : '0px'}}
                />
                <Tiles 
                    datas = {tilesDatas}
                    onTileClick = {changeTileState}
                    style = {{gap : "16px"}}
                />
            </NeuMorphismeFlex>
            {tableDatas.body.length > 0 && 
                <DashBoardTableObject 
                    body = {tableDatas.body.slice(0,20)}
                    head = {tableDatas.head}
                    maxCarac = {25}
                    borderLineStyle = {{height : "48px", borderBottom : '1px solid #ECECEC'}}
                    headStyle = {{height : "48px"}}
                    colmunSelected = {activeColumn}
                    onHeadClick = {onChangeHead}
                    definedColumn = {columnDone}
                    style = {{overflow : 'auto', height : '', marginLeft : "8px", marginRight : "8px"}}
                    tableStyle = {{overflow : 'auto'}}
                    refScroll = {refScroll}
                />
            }
            <div style = {{display : "flex", justifyContent : 'end', alignItems : 'end', margin : "16px", gap : "8px", flexGrow : 1}}>
                <ButtonCancel
                    onClick = {closePreview}
                    style = {{color : "#C43C3C", fontSize : "11px", fontFamilly : 'Montserrat'}}
                    text = "Fermer"

                />
                <DownloadButton
                    onClick = {onValidate}
                    text = "Valider"
                    disabled={columnDone.length !== tableDatas.head.length}
                />
            </div>
        </React.Fragment>
    )


}