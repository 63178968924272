import React from 'react'
import { SearchLoinc } from './SearchLoinc/SearchLoinc'
import { DisplaySearchEngine } from './DisplaySearchEngine/DisplaySearchEngine'
import { makeRequest } from '../../../api/apihook'
import { useDispatch, useSelector } from 'react-redux'
import {
    setAllEntries,
    setLocalInfos,
    setLoincInfos,
    setOtherInfos,
    setTypeOfEntryID,
    setUpdateCardInfos,
    setchapterID,
} from '../../../redux/transcoding/transcoding'
import { setShowSearchEngine } from '../../../redux/transcodingmenu/SearchEngine'
import { InputSelectTableLittle, NeuMorphisme, ButtonSearchVerification } from 'onaosreact'

export const Nav = () => {
    const chapitresDatas = useSelector((state) => state.Transcoding.nav.chapitres)
    const entriesDatas = useSelector((state) => state.Transcoding.nav.entry)
    const collectionIds = useSelector((state) => state.Transcoding.entrieID)

    const showSearchEngine = useSelector((state) => state.SearchEngine.show)

    const tokenRefresh = useSelector((state) => state.log.tokenRefresh)
    const userAccess = useSelector((state) => state.log.access)

    const dispatch = useDispatch()

    const containerStyle = {
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
    }

    const neumorphismeChapterStyle = {
        width: '448px',
        height: '40px',
        padding: '8px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '8px',
    }

    const selectDivStyle = {
        width: '208px',
        zIndex: 1,
    }

    const selectColorStyle = {
        color: '#383C3F',
    }

    const neumorphismeSearchStyle = {
        height: '40px',
        padding: '8px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        flexGrow: 1,
        justifyContent: userAccess.isAdminOnaos || userAccess.isExpertOnaos || userAccess.isThirdExpertOnaos ? '' : 'flex-end',
    }

    const changeNav = (id, type) => {
        const collectionsInfos = {
            ids: collectionIds,
            chapter: type === 'chapter' ? id : chapitresDatas.id,
            kindEntry: type === 'chapter' ? entriesDatas.id : id,
        }
        makeRequest('put', '/chapterOrEntryChange', tokenRefresh, collectionsInfos).then((resp) => {

            if (resp.data && resp.data.localInfos) {
                if (resp.data.allEntries && resp.data.localInfos) dispatch(setAllEntries({ all: resp.data.allEntries, id: resp.data.localInfos.id }))
                if (resp.data.localInfos) {
                    dispatch(setLocalInfos(resp.data.localInfos))
                }
                if (resp.data.loincInfos) {
                    dispatch(setLoincInfos(resp.data.loincInfos))
                }
                if (resp.data.otherInfos) {
                    dispatch(setOtherInfos(resp.data.otherInfos))
                }
                if (resp.data.majInfos) {
                    dispatch(setUpdateCardInfos(resp.data.majInfos))
                }
            } else {
                dispatch(setAllEntries({ all: [], id: '' }))
                dispatch(setLocalInfos({}))
                dispatch(setLoincInfos([]))
                dispatch(setUpdateCardInfos({}))
            }
            if (type === 'chapter') {
                dispatch(setchapterID(id))
            } else {
                dispatch(setTypeOfEntryID(id))
            }
        })
    }

    const chapitreSelect = (id, text) => {
        if (text !== undefined) return
        changeNav(id, 'chapter')
    }

    const entriesSelect = (id, text) => {
        if (text !== undefined) return
        changeNav(id, 'entry')
    }

    const openSearchEngine = () => {
        dispatch(setShowSearchEngine(true))
    }

    return (
        <div style={containerStyle}>
            <NeuMorphisme style={neumorphismeChapterStyle}>
                <InputSelectTableLittle
                    placeHolder='Chapitres'
                    autoOptions={chapitresDatas.datas}
                    onLineClick={chapitreSelect}
                    divStyle={selectDivStyle}
                    selectedID={chapitresDatas.id}
                    isSelectedColor={false}
                    inputStyle={selectColorStyle}
                    heightSize={28}
                />
                <InputSelectTableLittle
                    placeHolder="Type d'entrées"
                    autoOptions={entriesDatas.datas}
                    onLineClick={entriesSelect}
                    divStyle={selectDivStyle}
                    selectedID={entriesDatas.id}
                    isSelectedColor={false}
                    inputStyle={selectColorStyle}
                    heightSize={28}
                />
            </NeuMorphisme>
            <NeuMorphisme style={neumorphismeSearchStyle}>
                {(userAccess.isAdminOnaos || userAccess.isExpertOnaos || userAccess.isThirdExpertOnaos) && <SearchLoinc />}
                <ButtonSearchVerification onClickButton={openSearchEngine} />
                {showSearchEngine && <DisplaySearchEngine />}
            </NeuMorphisme>
        </div>
    )
}
