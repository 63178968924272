import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DashBoardTableObject, TitleDashboard } from 'onaosreact'
import { setExportDicoCheck } from '../../../../redux/filemenu/FileMenu'
import { setExportButtonAvailable, setIdsExport } from '../../../../redux/exportmenu/ExportMenu'
import { ExportButton } from '../../Export/ExportButton'

export const ClientAccessTraceability = ({}) => {
    const exportButtonAvailable = useSelector((state) => state.ExportMenu.exportButtonAvailable)
    const clientListing = useSelector((state) => state.FileMenu.tableDatas)

    const dispatch = useDispatch()

    const containerTitleStyle = {
        padding: '0 16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }

    const titleStyle = {
        fontSize: '20px',
    }

    const tableHeadStyle = {
        zIndex: 20,
        height: '48px',
    }

    const tableLineStyle = {
        height: '48px',
        borderBottom: '1px solid #ECECEC',
    }

    const tableFootStyle = {
        height: '150px',
    }

    useEffect(() => {
        const idCheck = clientListing.body
            .filter((line) => line.check === true)
            .map((line) => {
                return line.id
            })
        dispatch(setIdsExport(idCheck))
        dispatch(setExportButtonAvailable(idCheck.length > 0))
    }, [clientListing])

    const onCheckLine = (lineID) => {
        dispatch(setExportDicoCheck(lineID))
    }

    return (
        <React.Fragment>
            <div style={containerTitleStyle}>
                <TitleDashboard text='Traçabilité' style={titleStyle} />
                <ExportButton disabled={!exportButtonAvailable} exportType='tracabilite' />
            </div>
            <DashBoardTableObject
                body={clientListing.body}
                head={clientListing.head}
                maxCarac={25}
                checkbox={true}
                onClick={(id, position) => console.log('line  : ', id, position)}
                //stateChangeAll={() => console.log('stateall')}
                onChange={(lineID) => onCheckLine(lineID)}
                headStyle={tableHeadStyle}
                borderLineStyle={tableLineStyle}
                footStyle={tableFootStyle}
            />
        </React.Fragment>
    )
}
