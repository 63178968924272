import { DashBoardTableObject, NeuMorphismeFlex, TitleDashboard, TextPlus, InputSelectTableChoice, InputSelectTable, ConfirmationSnackBar } from 'onaosreact'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearChapterDicExpertInfos, setChapterDicExpertInfos, setExpertInfos, setListingChapters, setOpenChaptersWindow } from '../../../../redux/informationmenu/InformationMenu'
import { makeRequest } from '../../../../api/apihook'
import { useSnackbar } from 'notistack'

export const ExpertsAdminUser = ({}) => {

    const [msgAction, setMsgAction] = useState({ id: '', url: '', msg: '' })


    const [isFootHover, setFootHover] = useState(false)
    const expertDatas = useSelector(state => state.InformationMenu.expertInfos)
    const chaptersListing = useSelector(state => state.InformationMenu.chaptersListing)
    const tokenRefresh = useSelector(state=>state.log.tokenRefresh)
    const clientID = useSelector(state => state.InformationMenu.clientId)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    
    const refInput = useRef()
    const dispatch = useDispatch()


    useEffect(() => {
        if (msgAction.id === '' || msgAction.id === undefined) return
        enqueueSnackbar(msgAction.msg, {
            key: msgAction.id,
            action,
            variant: msgAction.variant !== undefined ? msgAction.variant : 'warning',
            autoHideDuration: 5000,
        })
    }, [msgAction])


    const action = (id) => (
        <ConfirmationSnackBar
            selectConfirm={() => {
                confirmDelete()
                closeSnackbar(id)
            }}
            selectDismiss={() => {
                closeSnackbar(id)
            }}
        />
    )

    const deleteExpert = (id) => {
        setMsgAction({msg : 'Supprimer l\'assignation pour cet expert ?', id : id})
    }

    
    const confirmDelete = () => {

        const datas = {
            userID : msgAction.id,
            clientID : clientID
        }
        makeRequest("put", '/removeUserChapter', tokenRefresh, datas).then(resp => {
            if (resp.data && resp.data.ok) {                
                if (resp.data && resp.data.chaptersLinsting) dispatch(setListingChapters(resp.data.chaptersLinsting))
                if (resp.data && resp.data.expertInfos) dispatch(setExpertInfos(resp.data.expertInfos)) 
            }
        })

    }

    const removeChapterDicAssignation = (id) => {
        dispatch(clearChapterDicExpertInfos())
    }


    const chooseChapterExpert = (id, text) => {
        //TODO : if only text => do nothing 
        //send ID to API, record response as expert for chapter
        if (text !== undefined) return 
        const datas = {
            userID : id,
            clientID : clientID
        }
        makeRequest("put", '/assignChapterDico', tokenRefresh, datas).then(resp => {
            if (resp.data && resp.data.ok) {                
                const newDatas = expertDatas.allUsers.filter((line) => line .id === id)
                dispatch(setChapterDicExpertInfos(newDatas))
            }
        })

        

    }

    const openChaptersWindow = () => {
        dispatch(setOpenChaptersWindow(true))
    }

    return(
        <NeuMorphismeFlex style = {{justifyContent : '', height : '', paddingTop : "16px", flexGrow : 1 }}>
            <TitleDashboard 
                text = "Experts"
                style = {{fontSize : "20px", paddingLeft : "16px", marginBottom : '16px'}}
            />

            <TitleDashboard 
                text = "Responsable du dictionnaires de Chapitres"
                style = {{fontSize : "16px", paddingLeft : "16px", marginBottom : '16px'}}
            />


            {expertDatas && expertDatas.chapter && expertDatas.chapter.length === 1 && 
                <InputSelectTableChoice
                    datas = {expertDatas.chapter}
                    onDelete = {removeChapterDicAssignation}   
                    style = {{marginBottom : '16px'}}
                />
            }
            {(expertDatas === undefined || expertDatas.chapter === undefined) &&  expertDatas.allUsers &&
                <InputSelectTable
                    placeHolder="Rechercher un utilisateur de LOINC Mapper"
                    style={{ width : '', margin : '0 16px 16px 16px', borderBottom:"1px solid transparent" }}
                    auto={true}
                    autoOptions={expertDatas.allUsers !== undefined ? expertDatas.allUsers : [] } 
                    autoClean = {false}
                    inputRef = {refInput}
                    onLineClick = {chooseChapterExpert} 
                />
            }

            <div style = {{display  : "flex", justifyContent : 'space-between', alignItems : 'center', marginRight : '16px' }}>
                <TitleDashboard 
                    text = "Responsables des dictionnaires de Demandes et de Résultats"
                    style = {{fontSize : "16px", paddingLeft : "16px", marginBottom : '16px'}}
                />
                <TextPlus 
                    text = "Assigner des chapitres pour vérifications" 
                    onClick = {openChaptersWindow}
                />
            </div>

            <DashBoardTableObject 
                body = {expertDatas.resdem}
                maxCarac = {[10, 10, 30, 10, 80, 10 ]}
                onClick = {deleteExpert}
                borderLineStyle = {{height : "64px", borderBottom : '1px solid #ECECEC'}}
                onHover = "delete"
            />
            {chaptersListing && chaptersListing.filter(chapter => chapter.assignTo.uuid === "").length > 0 &&
                <div style = {{display : 'flex', justifyContent : "center", alignItems : 'end'}}>
                    <div 
                        style = {{ cursor : "pointer", display : "flex", justifyContent : "center", alignItems : "center", height : "48px", width : "100%", textAlign : "center", backgroundColor : isFootHover ? "#DFE1E2" : ""}}
                        onMouseEnter={()=>setFootHover(true)}
                        onMouseLeave={()=>setFootHover(false)}
                        onClick={openChaptersWindow}
                    >
                        <span style = {{color : "#C43C3C", fontFamily:"Montserrat", fontSize : "13px"}}>Tous les chapitres ne sont pas attribués aux experts de votre structure, veuillez les mettre à jour</span>
                    </div>

                </div>
            }

        </NeuMorphismeFlex>

    )

}

