import { createSlice } from "@reduxjs/toolkit";


const tokenKeyLog = process.env.REACT_APP_TOKEN_KEY


export const logSlice = createSlice({
    name : "log",
    initialState:{
        connect : false, 
        tokenRefresh : "", 
        dashboard:false, 
        onaosProject : [],
        user : "",
        email : "",
        institution : "",
        access : {
            isAdminOnaos : false,
            isExpertOnaos : false,
            isExpert : false,
            isAdmin : false,
            isThirdExpertOnaos : false
        },
        expertComms : [],
        firstConnection : true,
    },
    reducers: {
        unLog : (state, action) => {
            state.connect = false
            state.tokenRefresh = ""
            state.dashboard = false
            localStorage.clear()

            return state
        },
        turnOffFirstConnection : (state, action) => {
            state.firstConnection = false 
            return state
        },
        setToken : (state,action) => {
            if (action.payload.token !== undefined && action.payload.tokenRefresh !== undefined ){
                // const userInfos = action.payload.userdatas.etablissements[0]
                state.connect = true
                localStorage.setItem(tokenKeyLog,action.payload.token)
                state.tokenRefresh = action.payload.tokenRefresh
                state.dashboard = true
                state.onaosProject =  action.payload.onaos_projects
                state.onaosTuto = action.payload.userdatas && action.payload.userdatas.onaosTuto ? action.payload.userdatas.onaosTuto : []
                state.user = action.payload.userdatas.infos.fullname
                state.email = action.payload.userdatas.infos["e-mail"]
                state.institution = action.payload.userdatas.etablissements[0]
                state.firstConnection = action.payload.userdatas.firstConnection
                //for display only !
                if (state.institution.access) {
                    if (state.institution.access.all && state.institution.access.all.includes("AdminOnaos")) state.access.isAdminOnaos = true

                    if (state.institution.access.mapper) {
                        if (state.institution.access.mapper.includes("Expert")) state.access.isExpert = true
                        if (state.institution.access.mapper.includes("DSI")) state.access.isAdmin = true
                        if (state.institution.access.mapper.includes("Expert Onaos")) state.access.isExpertOnaos = true
                        if (state.institution.access.mapper.includes("Expert tiers")) state.access.isThirdExpertOnaos = true
                        if (state.institution.access.mapper.includes("Admin Onaos")) state.access.isAdminOnaos = true
                    }


                }
            }

            return state
        },
        setDash : (state, action) => {
            state.dashboard = action.payload
            return state
        },
        setCommExpert : (state, action) => {
            state.expertComms = action.payload
            return state
        },
        addCommExpert : (state, action) => {
            state.expertComms.allComm.push(action.payload)
            return state 
        },
        addRecoExpert : (state, action) => {
            state.expertComms.allReco.push(action.payload)
            return state 
        },
        
        changeAccessDev : (state, action) => {
            state.access = {
                isAdminOnaos : false,
                isExpertOnaos : false,
                isExpert : false,
                isAdmin : false,
                isThirdExpertOnaos : false
            }
            switch (action.payload){
                case '1':
                    state.access.isExpert = true
                    break
                case '2':
                    state.access.isExpertOnaos = true
                    break
                case '3':
                    state.access.isThirdExpertOnaos = true
                    break                
                case '4':
                    state.access.isAdminOnaos = true
                    break                
                case '5':
                    state.access.isAdmin = true
                    break                    
                default:
                    state.access.isExpert = true
                    break
            }
            return state
        }
       
    }
})


export const {unLog,setToken, setDash, changeAccessDev, setCommExpert, addCommExpert, addRecoExpert, turnOffFirstConnection} = logSlice.actions;