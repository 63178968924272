import { LOINCCard2 } from 'onaosreact'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeRequest } from '../../../../../api/apihook'
import { changeLOINCState, setProgression, turnSelectedIDToUntreated } from '../../../../../redux/transcoding/transcoding'
import { useSnackbar } from 'notistack'
import './watermark.css'

export const LOINCCardsPart = () => {
    const loincDatas = useSelector((state) => state.Transcoding.loincDatas.cards)
    const updateCard = useSelector((state) => state.Transcoding.updateCard)
    const collectionInfos = useSelector((state) => state.Transcoding.otherInfos)
    const collectionIDS = useSelector((state) => state.Transcoding.entrieID)
    const isAllowBtn = useSelector((state) => state.Transcoding.otherInfos.allowed)

    const userAccess = useSelector((state) => state.log.access)
    const tokenRefresh = useSelector((state) => state.log.tokenRefresh)

    const entryID = useSelector((state) => state.Transcoding.clientDatas.id)

    const dispatch = useDispatch()

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const containerStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'left',
        // alignItems: 'center',
        gap: '24px',
        padding: '16px',
    }


    const onCardsClick = (cardDatas, position) => {
        // if (userAccess.isAdmin || userAccess.isAdminOnaos) return ;
        if (collectionInfos.stateCollection === 'Vérification Client' && !userAccess.isExpert) {
            enqueueSnackbar('Action non autorisée', { variant: 'error', persist: false })
            return
        }
        if (collectionInfos.stateCollection !== 'Vérification Client' && (userAccess.isExpert || userAccess.isAdmin)) {
            enqueueSnackbar('Action non autorisée', { variant: 'error', persist: false })
            return
        }

        const datas = {
            entryID: entryID,
            collectionIDS: collectionIDS,
            cards: {
                code: cardDatas.code,
                actualStatusInfos: cardDatas.Status,
            },
        }

        makeRequest('put', '/clickOnCard', tokenRefresh, datas).then((resp) => {
            if (resp.data) {
                if (resp.data.msg !== undefined && resp.data.msg !== '') {
                    enqueueSnackbar(resp.data.msg, { variant: 'error', persist: false })
                    return
                }
                dispatch(changeLOINCState({ datas: resp.data, type: collectionInfos.stateCollection }))
                dispatch(turnSelectedIDToUntreated(entryID))
                // dispatch(setProgression(entryID))
            }
        })
    }

    const onEditCard = (cardDatas) => {}

    const getState = (states) => {
        const collectionState = collectionInfos.stateCollection
        switch (collectionState) {
            case 'Review':
                return states['sélectionné review expert'] ? 'valid' : 'reject'
            case 'Vérification Onaos':
                return states['sélectionné expert'] ? 'valid' : 'reject'
            case 'Vérification Client':
                if (states['sélectionné client'] === undefined) return undefined
                return states['sélectionné client'] ? 'valid' : 'reject'
        }
    }

    const getFontColor = (states, modifications) => {
        if (modifications[0] !== undefined) return '#fff'
        if (states.fromClient) return '#ebe536'
        if (states.fromOtherClient) return '#FFC7BF'
        return '#FFF'
    }

    const reOrderCards = () => {
        //change order if gold card exist, if otherclient exist
        return loincDatas
    }

    return (
        <div style={containerStyle} className={isAllowBtn ? '' : 'watermark'}>
            {updateCard && updateCard.code && (
                <LOINCCard2 datas={updateCard} dicoType={collectionInfos.typeDico} fontStyle='#eeeeee' state='update' />
            )}
            {loincDatas &&
                reOrderCards().map((v, k) => (
                    <LOINCCard2
                        key={'cards_' + k}
                        datas={v}
                        state={getState(v.Status)}
                        onClick={() => onCardsClick(v, k)}
                        onEdit={() => onEditCard(v)}
                        onPanelClick={() => console.log('')}
                        dicoType={collectionInfos.typeDico}
                        fontStyle={getFontColor(v.Status, v.Modifications)}
                        showModifications={v.Modifications}
                    />
                ))}
        </div>
    )
}
