import React from "react"
import {Title, MenuItem, NeuMorphisme} from "onaosreact"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { menuClick } from "../../api/utils";
import { useSnackbar } from "notistack";






export const Menu = ({}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const DashBoardMenu = useSelector( (state) =>state.DashBoardMenu ) 
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)
    const access = useSelector(state => state.log.access) 
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const isOnaos = access.isAdminOnaos || access.isExpertOnaos || access.isThirdExpertOnaos

    const page = {
        "Informations" : "info",
        "Dictionnaires" : "files",
        "Vérification transcodage" : "transco",
        "Traçabilité" : "traca",
        'Veille - Etude d\'impacts' : 'veille',
    }

    const accessPage = {
        'Clients' : 'clients'
    }


    const styleDiv = {
        width:"250px",
       // height:"85vh",
        // position:'absolute',
        // top:'110px',
        // left:'144px',

        paddingTop : "16px"
    }

    return(
        <NeuMorphisme  style = {styleDiv}>
   
            <Title text = "Menu" style = {{fontSize : "20px"}} />

            {Object.entries(page).map( v => (
                <MenuItem 
                    text = {v[0]}
                    click = {()=> menuClick({page : v, dispatch : dispatch, tokenRefresh : tokenRefresh, navigate : navigate, access : access, snack : [enqueueSnackbar, closeSnackbar]})}
                    active = {DashBoardMenu.page[1] === v[1]}
                    key = {v[1]}
                    style = {{fontSize : '13px', fontFamilly : "Montserra"}}
                />
            ))}
            {isOnaos && 
                Object.entries(accessPage).map( v => (
                    <MenuItem 
                        text = {v[0]}
                        click = {()=> menuClick({page : v, dispatch : dispatch, tokenRefresh : tokenRefresh, navigate : navigate, access : access, snack : [enqueueSnackbar, closeSnackbar]})}
                        active = {DashBoardMenu.page[1] === v[1]}
                        key = {v[1]}
                        style = {{fontSize : '13px', fontFamilly : "Montserra"}}
                    />
                ))
            }
            
            </NeuMorphisme>
    )
}