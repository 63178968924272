import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { LOINCHead } from './LOINCHead/LOINCHead'
import { LOINCCardsPart } from './LOINCCardsPart/LOINCCardsPart'
import { LOINCCardsTablePart } from './LOINCCardsTablePart/LOINCCardsTablePart'
import { ButtonsBloc } from './ButtonBloc/ButtonBloc'
import { NeuMorphismeFlex } from 'onaosreact'

export const LOINCDatas = () => {
    const [showTable, setShowTable] = useState(false)

    const isAllowBtn = useSelector((state) => state.Transcoding.otherInfos.allowed)

    const neumorphismeStyle = {
        justifyContent: '',
        padding: '16px 0',
        flexGrow: 2,
        gap: '10px',
        height: '',
    }

    return (
        <NeuMorphismeFlex style={neumorphismeStyle}>
            <LOINCHead showTable={showTable} setShowTable={setShowTable} />
            {showTable ? <LOINCCardsTablePart /> : <LOINCCardsPart />}
            {isAllowBtn && <ButtonsBloc />}
        </NeuMorphismeFlex>
    )
}
