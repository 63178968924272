import { ConfirmationSnackBar, DashBoardTableObject, DownloadButton, LoincSearchBtns, Search, SubMenuHelp, ThreeDotMenu } from 'onaosreact'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ExportButton } from '../../Export/ExportButton'
import { useNavigate } from 'react-router'
import { setExportButtonAvailable, setIdsExport } from '../../../../redux/exportmenu/ExportMenu'
import { setAllEntries, setEntriesIDActive, setLocalInfos, setLoincInfos, setNavClientPart, setNavOnaosPart, setOtherInfos, setUpdateCardInfos } from '../../../../redux/transcoding/transcoding'
import { makeRequest } from '../../../../api/apihook'
import { useSnackbar } from 'notistack'
import { setCommExpert } from '../../../../redux/connection/log'
import { setAllTranscoTabs, setExportLineCheck, setSearchSave, setTranscoTabActive, setTranscodingTable } from '../../../../redux/transcodingmenu/TranscodingMenu'




export const TableOnaos = ({}) => {
    const [msgAction, setMsgAction] = useState({id : '', url : '', msg : ''})
    const exportButtonAvailable = useSelector(state =>state.ExportMenu.exportButtonAvailable)
    const transcoTable = useSelector(state=>state.TranscodingMenu.transcodingTable)
    const allClients = useSelector(state=>state.TranscodingMenu.allExperts)
    const filtersInit = useSelector(state=>state.TranscodingMenu.filters)

    const tokenRefresh = useSelector(state => state.log.tokenRefresh)
    const tabActive = useSelector(state => state.TranscodingMenu.tabActive)
    const tabs = useSelector(state => state.TranscodingMenu.tabs)
    const searchSave = useSelector((state) => state.TranscodingMenu.searchSave)
  

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()


    const dispatch = useDispatch()



    const navigate = useNavigate()


    const [filters, setFilter] = useState(JSON.parse(JSON.stringify(filtersInit)))
    const [clientsAuto, setClientsAuto] = useState([])


    useEffect(()=>{
      setFilter(JSON.parse(JSON.stringify(filtersInit)))
    }, [filtersInit])
    

    useEffect(()=>{
      if (msgAction.id === "" || msgAction.id === undefined) return;

      enqueueSnackbar(msgAction.msg, {
          key : msgAction.id,
          action,
          variant: 'warning',
          autoHideDuration: 5000,
        })

    }, [msgAction])



    useEffect(()=>{
      const clientsDtas = allClients.map(v => {
          return v.text[0]
      })
      setClientsAuto(clientsDtas)
    }, [allClients])


    useEffect(()=> {
      const idCheck = transcoTable.body.filter(line => line.check === true).map(line => {
         return line.id
      })            
      dispatch(setIdsExport(idCheck))
      dispatch(setExportButtonAvailable(idCheck.length > 0))
    }, [transcoTable])

    const sendToClient = () => {
      const id = msgAction.id 
      const url = msgAction.url
      
      
      makeRequest("get", "/" + url + "/" + id, tokenRefresh).then(resp => {
        if (resp.data) {
          if (resp.data.msg !== "") {
            enqueueSnackbar(resp.data.msg, {variant: 'error', persist : false})
          }
          makeRequest("get", "/transcodingPage" , tokenRefresh).then(resp=>{
            if (resp.data && resp.data.collections) dispatch(setTranscodingTable(resp.data.collections))
            if (resp.data && resp.data.allTabs) {
              if (tabs[tabActive].title !== resp.data.allTabs[tabActive]?.title) {
                dispatch(setTranscoTabActive(0))
              }
              dispatch(setAllTranscoTabs(resp.data.allTabs))
            } 

          })
        }
      })
    }

    const action = id => (
      <ConfirmationSnackBar
          selectConfirm = {() => { sendToClient(); closeSnackbar(id)}}
          selectDismiss = {() => { closeSnackbar(id) }}
      />
    );


    const openEvent = (lineClick, obj, id) => {
      if (obj.params === "send") {
        setMsgAction({id : id, url :"sendToClient",  msg : "Livrer la collection ?"})
        return
      }

      if (obj.params === "review") {
        setMsgAction({id : id, url :"sendToReview", msg : "Passer la collection en relecture ?"})
        return
      }
    }

    const onCheckLine = (lineID) => {
      //TODO
      dispatch(setExportLineCheck(lineID))

    }


    const subMenuData = [
        { label: "Passer en review", onClick: openEvent, params : "review" },
        { label: "Livrer", onClick: openEvent, params : "send"},
        { label: "Détails", onClick: openEvent, params : "details" },
        // { label: "Validation Expert", onClick: openEvent, params : "expert"},
        // { label: "Validation Client", onClick: openEvent, params : "client"},
    ]

      
    const onSearch = (text, tags) => {
      const id = enqueueSnackbar('Recherche en cours...', {
        variant: 'info',
        persist: true,
    })
      const datas = {
        client: text,
        type : tabs[tabActive].key,
        filters: tags,
      }
      
      makeRequest('put', '/searchCollections', tokenRefresh, datas).then((resp) => {
        if (resp.data && resp.data.collections) dispatch(setTranscodingTable(resp.data.collections))
        if (resp.data && resp.data.filters ) setFilter(resp.data.filters)
          dispatch(setSearchSave({client:text, filters:filters}))
        closeSnackbar(id)
      })

    }
 
    
    const onClickLine = (id, position) => {
      const collectionsInfos = {
        ids : id,
        chapter : "all",
        kindEntry : "all",
        addAllEntries : true //problem with setAllEntries : not update all the time because too much resource, but if assignation change after load, problem...

      }

      makeRequest('get', '/allCommAndReco', tokenRefresh).then(resp => {
        if (resp.data.allCommReco !== undefined) dispatch(setCommExpert(resp.data.allCommReco))
        // if (resp.data.allReco !== undefined) dispatch(setRecoExpert(resp.data.allReco)) 
        makeRequest("put", "/collection", tokenRefresh, collectionsInfos).then(resp => {
          if (resp.data) {
            if (resp.data.allEntries && resp.data.localInfos) {
              dispatch(setAllEntries({all : resp.data.allEntries, id : resp.data.localInfos.id}))
            } else {
              dispatch(setAllEntries({all : [], id : -1}))
            }
            if (resp.data.localInfos) {
              dispatch(setLocalInfos(resp.data.localInfos)) 
            } else {
              dispatch(setLocalInfos({})) 
            }
            if (resp.data.loincInfos) {
              dispatch(setLoincInfos(resp.data.loincInfos)) 
            } else {
              dispatch(setLoincInfos([]))
            }
            if (resp.data.otherInfos) dispatch(setOtherInfos(resp.data.otherInfos))
            if (resp.data.majInfos) dispatch(setUpdateCardInfos(resp.data.majInfos))
            if (resp.data.nav && resp.data.nav.clientBlock && resp.data.nav.clientBlock.chapitres) dispatch(setNavClientPart(resp.data.nav.clientBlock))
            if (resp.data.nav && resp.data.nav.onaosBlock && resp.data.nav.onaosBlock.dicoType ) dispatch(setNavOnaosPart(resp.data.nav.onaosBlock))
  
            
            dispatch(setEntriesIDActive(id))
            navigate("/mapper/transcodage")
          } //gestion erreur ? 
        })
      })



    }

    return (
        <Search
            leftComponent = {<div style = {{visibility : "hidden"}}><DownloadButton disabled = {true}/></div>}
            rightComponent = {
                <ExportButton 
                  disabled={!exportButtonAvailable}
                  exportType = 'collection'
                />
            }
            searchLabel = "Rechercher une entrée"
            searchStyle = {{height : '32px', boxSizing: 'border-box', gap : '16px'}}
            blocSearchStyle = {{justifyContent : 'space-between', padding : '0 16px 16px', marginBottom : ''}}
            styleBlockSearch = {{ gap : '16px', padding : "0 16px" }}
            defaultInputValue = {searchSave.client}
            searhcAndFilterWidth = "632px"
            filterData = {filters}
            // filterSize = {{width: '100%'}}
            onSearch = {onSearch}
            autoCompletDatas = {clientsAuto}
            removePage = {true}
            blockStyle= {{marginTop : "0px", height : '94%'}}
            tagStyle = {{width : '100%', padding : '0 16px 16px', boxSizing : 'border-box'}}
            allowEmpty = {true}
            openBlockSearchStyle = {{borderRadius : "5px 5px 0 0"}}
        >
            <DashBoardTableObject 
                body = {transcoTable.body}
                head = {transcoTable.head}
                maxCarac = {[25,25,25,25,20,25,25,10,25,25]}
                onClick = {onClickLine}
                borderLineStyle = {{height : "48px", borderBottom : '1px solid #ECECEC'}}
                headStyle  ={{zIndex : 20, height : "48px"}}
                ThreeDotMenu = {
                    <ThreeDotMenu>
                        <SubMenuHelp
                            datas={subMenuData}
                            style = {{top : "28px", right : "5px", zIndex : 12}}
                        />
                    </ThreeDotMenu>
                }
                footStyle = {{height : "150px" }}
                checkbox = {true}
           //     stateChangeAll = {()=>console.log("stateall")}
                onChange = {lineID=>onCheckLine(lineID)}
                style = {{overflow : 'auto'}}

            />
        </Search>
    )


}