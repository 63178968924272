import React, { useRef } from 'react'
import { NeuMorphismeFlex, TitleDashboard, InputSelectTable, InputSelectTableChoice, TextEdit } from 'onaosreact'
import { useDispatch, useSelector } from 'react-redux'
import { setClientActive, setEditableClient } from '../../../../redux/informationmenu/InformationMenu'
import { informationPage } from '../../../../api/utils'



export const FindClient = ({}) => {

    const allClients = useSelector(state => state.InformationMenu.allClients)
    const clientID = useSelector(state => state.InformationMenu.clientId)
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)


    const dispatch = useDispatch()
    const refInput = useRef()

    const chooseClient = (id, text) => {
        if (text !== undefined) return 
        // dispatchExperts({dispatch : dispatch, tokenRefresh : tokenRefresh, clientID :id, callback : dispatch(setClientActive(id))})
        informationPage({dispatch : dispatch, tokenRefresh :tokenRefresh, addExpert : true, clientID : id, callback : ()=>dispatch(setClientActive(id))})
    }

    const closeClient = () => {
        dispatch(setClientActive(-1))
        dispatch(setEditableClient(false))
    }

    const editInfos = () => {
        //TODO : add messsage from snake lib for confirmation
        dispatch(setEditableClient(true))
    }

    return(
        <NeuMorphismeFlex style = {{justifyContent : '', height : '', paddingTop : "16px", overflowY : '' }}>
            <div style = {{display  : "flex", justifyContent : 'space-between', alignItems : 'center', marginRight : '16px', marginBottom : '16px' }}>
                <TitleDashboard 
                    text = "Rechercher un client"
                    style = {{fontSize : "20px", paddingLeft : "16px", margin : '0px'}}
                />
                {clientID !== -1 && <TextEdit 
                    text = "Modifier les informations" 
                    onClick = {editInfos}
                />}
            </div>

            {clientID !== -1 && 
                <InputSelectTableChoice
                    datas = {[allClients.find(line => line.id === clientID)]}
                    onDelete = {closeClient}   
                    style = {{marginBottom : '16px'}}
                />
            }
            {clientID === -1 && allClients.length > 0 &&
                <InputSelectTable
                    placeHolder="Rechercher un client LOINC Mapper"
                    style={{ width : '', margin : '0 16px 16px 16px', borderBottom:"1px solid transparent", zIndex : 100 }}
                    auto={true}
                    autoOptions={allClients} 
                    autoClean = {false}
                    inputRef = {refInput}
                    onLineClick = {chooseClient} 
                />
            }

        </NeuMorphismeFlex>
    )


}