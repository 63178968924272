import React from "react";
import { OnaosButtons } from "./OnaosButtons";
import { useSelector } from "react-redux";

export const ButtonsBloc = () => {
  const userAccess = useSelector((state) => state.log.access);

  return (
    <div style={{ padding: "0 16px", display: "flex", justifyContent: "end", alignItems: "end", gap: "16px", flexGrow: 1 }}>
      {(userAccess.isAdmin || userAccess.isExpert) && (
        <OnaosButtons isClient={true} userAccess={userAccess.isExpert} />
      )}
      {(userAccess.isAdminOnaos || userAccess.isExpertOnaos || userAccess.isThirdExpertOnaos) && (
        <OnaosButtons
          userAccess={userAccess.isExpertOnaos || userAccess.isThirdExpertOnaos || userAccess.isAdminOnaos}
          isClient={false}
        />
      )}
    </div>
  )
}