import { ButtonCancel, DownloadButton } from 'onaosreact'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cleanTableConfirm, setPageName, showWindowConfirmation } from '../../../../../../redux/filemenu/PreviewMenu'
import { setActiveFileTab, setTableFileDatas } from '../../../../../../redux/filemenu/FileMenu'
import { setClientDatas, setClientList, setTilesDatas, showTranscodingWindow } from '../../../../../../redux/filemenu/TranscodingModal'
import { makeRequest } from '../../../../../../api/apihook'
import { setTableDatasEdition, showWindowConfirmationEdition } from '../../../../../../redux/filemenu/ConfirmationAndEdition'
import { useSnackbar } from 'notistack'


const MOCKCLIENTLISTING = [
    {show : true, text : ["CHU Lille"], id : "1"},
    {show : true, text : ["CHU Lille"], id : "1"},
    {show : true, text : ["CHU Lille"], id : "1"},
    {show : true, text : ["CHU Lille"], id : "1"},
    {show : true, text : ["CHU Lille"], id : "1"},
    {show : true, text : ["CHU Lille"], id : "1"},
    {show : true, text : ["CHU Lille"], id : "1"},
]

const MOCKTABLECOLLECTIONONAOS = {
    body : [
        {show : true, text  :["CHU Lille", "Résultat", "2023-09-07", "smith@examples.com", "Biochimie, biologie moléculaire", "1500", "En cours de transcodage", {type : "progress", values : [20,5,75], text : "En cours de transcodage"}],  id : "10"},
        {show : true, text  :["CHU Lille", "Résultat", "2023-09-07", "smith@examples.com", "Biochimie, biologie moléculaire", "1500", "En cours de transcodage", {type : "progress", values : [20,5,75], text : "En cours de vérification"}],  id : "11"},
        {show : true, text  :["CHU Lille", "Résultat", "2023-09-07", "smith@examples.com", "Biochimie, biologie moléculaire", "1500", "En cours de transcodage", {type : "progress", values : [20,20,60], text : 'Terminé'}],  id : "12"},
        {show : true, text  :["CHU Lille", "Résultat", "2023-09-07", "smith@examples.com", "Biochimie, biologie moléculaire", "1500", "En cours de transcodage", {type : "progress", values : [20,5,75], text : "En attente de vérification"}],  id : "13"},
        {show : true, text  :["CHU Lille", "Résultat", "2023-09-07", "smith@examples.com", "Biochimie, biologie moléculaire", "1500", "En cours de transcodage", {type : "progress", values : [100,0,0], text : "En attente de livraison"}],  id : "13"},
        {show : true, text  :["CHU Lille", "Résultat", "2023-09-07", "smith@examples.com", "Biochimie, biologie moléculaire", "1500", "En cours de transcodage", {type : "progress", values : [0,100,0]}],  id : "13"},
        {show : true, text  :["CHU Lille", "Résultat", "2023-09-07", "smith@examples.com", "Biochimie, biologie moléculaire", "1500", "En cours de transcodage", {type : "progress", values : [0,0,100]}],  id : "13"},
        {show : true, text  :["CHU Lille", "Résultat", "2023-09-07", "smith@examples.com", "Biochimie, biologie moléculaire", "1500", "En cours de transcodage", {type : "progress", values : [50,0,50]}],  id : "13"},
        {show : true, text  :["CHU Lille", "Résultat", "2023-09-07", "smith@examples.com", "Biochimie, biologie moléculaire", "1500", "En cours de transcodage", {type : "progress", values : [50,50,0]}],  id : "13"},
        {show : true, text  :["CHU Lille", "Résultat", "2023-09-07", "smith@examples.com", "Biochimie, biologie moléculaire", "1500", "En cours de transcodage", {type : "progress", values : [0,1,99]}],  id : "13"},
        {show : true, text  :["CHU Lille", "Résultat", "2023-09-07", "smith@examples.com", "Biochimie, biologie moléculaire", "1500", "En cours de transcodage", {type : "progress", values : [98,1,1]}],  id : "13"},
        {show : true, text  :["CHU Lille", "Résultat", "2023-09-07", "smith@examples.com", "Biochimie, biologie moléculaire", "1500", "En cours de transcodage", {type : "progress", values : [1,1,98]}],  id : "13"},
    ],
    head : [ "Structure", "Dictionnaire", "Chargé le", "Expert", "Chapitres", "Codes", "Statut", "Progression"],
}


const MOCKTILES = [
    {label : "Code", params : "", check  :false, block : false},
    {label : "Libellé", params : "", check  :true, block : false},
    {label : "Milieu", params : "", check  :true, block : false},
    {label : "Unité", params : "", check  :false, block : false},
    {label : "Technique", params : "", check  :false, block : false},
]

export const ConfirmationBtn = ({}) => {
  

    const confirmationDatas = useSelector(state => state.PreviewMenu.confirmationDatas)
    const confirmationBtn = useSelector(state => state.PreviewMenu.confirmationBtn)

    const tokenRefresh = useSelector(state => state.log.tokenRefresh)

    const fileID = useSelector(state => state.PreviewMenu.activeFileID)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const dispatch = useDispatch()

    const closeConfirm = () => {
        dispatch(cleanTableConfirm())
        dispatch(showWindowConfirmation(false))
    }

    const confirmConfirm = () => {
        switch(confirmationDatas.for) {
            case 'head':
                const datas = {
                    fileID : fileID,
                    what : confirmationDatas.for,
                    newHeads : confirmationDatas.other
                }
                enqueueSnackbar("Chargement en cours", {variant: 'info', persist : true})
                makeRequest('put', '/confirmModifFile', tokenRefresh, datas).then(resp => {
                    if (resp.data && resp.data.ok) {
                        dispatch(setTableDatasEdition(resp.data.datas))
                        dispatch(showWindowConfirmation(false))
                        dispatch(showWindowConfirmationEdition(true))
                        closeSnackbar()
                        // dispatch(setTableFileDatas(resp.data.clientsFiles))
                    }
                })

                
                break;
            case 'transcoding':
                // dispatch(setClientList(MOCKCLIENTLISTING))
                // dispatch(setClientDatas(MOCKTABLECOLLECTIONONAOS))
                // dispatch(setTilesDatas(MOCKTILES))
                // dispatch(showTranscodingWindow(true))    
                break
            default: 
                break
        }

        closeConfirm()


      
    }



    return(
        <div style = {{display : "flex", justifyContent : 'end', alignItems : 'end', gap : "8px", flexGrow : 1, margin : "0 16px 16px 0"}}>
            <ButtonCancel
                onClick = {closeConfirm}
                style = {{color : "#C43C3C", fontSize : "11px", fontFamilly : 'Montserrat'}}
                text = "Fermer"
            />
            <DownloadButton
                onClick = {confirmConfirm}
                text = "Confirmer"
                disabled = {!confirmationBtn}
            />
        </div>
       
    )


}