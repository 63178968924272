import { NewNavBar, HelpMenuMapper, ConfirmationSnackBarDontShow } from 'onaosreact'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { turnOffFirstConnection, unLog } from '../redux/connection/log'

import { useNavigate } from 'react-router-dom'
import { menuClick } from '../api/utils'
import { directMakeRequest, makeRequest } from '../api/apihook'
import { useSnackbar } from 'notistack'

export const HeadMenu = ({ style }) => {
    const email = useSelector((state) => state.log.email)
    const user = useSelector((state) => state.log.user)

    const tokenRefresh = useSelector((state) => state.log.tokenRefresh)
    const onaosProject = useSelector((state) => state.log.onaosProject)
    const onaosTuto = useSelector((state) => state.log.onaosTuto)
    
    const firstConnection = useSelector((state) => state.log.firstConnection)

    const access = useSelector((state) => state.log.access)
    const isOnaos = access.isAdminOnaos || access.isExpertOnaos || access.isThirdExpertOnaos


    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const openDashboard = (position, params) => {
        menuClick({
            page: params,
            tokenRefresh: tokenRefresh,
            dispatch: dispatch,
            navigate: navigate,
            access: access,
            snack : [enqueueSnackbar, closeSnackbar]
        })
    }

    const openExit = () => {
        dispatch(unLog())
    }

    const goto = (id, destination) => {
        const data = {
            destination: destination,
        }
        makeRequest('put', '/redirect', tokenRefresh, data).then(resp => {
            if (resp.data && resp.data.url !== undefined) {
                setTimeout(() => window.open(resp.data.url, '_blank'), 200)
            } else {
                setTimeout(() => window.open(process.env.REACT_APP_URL_SSO_FRONT + '?r=' + destination, '_blank'), 200)
            }
        })
    }

    const MenuData = [
        { label: 'Informations', onClick: openDashboard, params: ['Informations', 'info'] },
        { label: 'Dictionnaires', onClick: openDashboard, params: ['Dictionnaires', 'files'] },
        { label: 'Vérification transcodage', onClick: openDashboard, params: ['Vérification transcodage', 'transco'] },
        { label: 'Traçabilité', onClick: openDashboard, params: ['Traçabilité', 'traca'] },
        { label: 'Veille - Etude d\'impacts', onClick: openDashboard, params: ['Veille - Etude d\'impacts', 'veille'] },
    ]

    const MenuAccess = [
        { label: 'Clients', onClick: openDashboard, params: ['Clients', 'clients'] },
    ]

    const getOnaosProject = () => {
        const data = onaosProject
            .filter((v) => v.label !== undefined && v.label !== '')
            .map((v) => {
                return { label: v.label, onClick: goto, params: v.projet }
            })
        return data
    }

    const openOnaosWebSite = () => {
        setTimeout(() => window.open('https://www.onaos.com', '_blank'), 200)
    }

    const goHome = () => {
        openDashboard(0, ['Vérification transcodage', 'transco'])
    }

    const linkFormation = 'https://www.bioformation.org/formation/287/formation-transcodage-loinc-dictionnaires-informatiques-de-laboratoire-de-biologie-medicale-de-la-theorie-a-la-pratique'


    const downloadFile = (url,title, name) => {
        const anchor = document.createElement('a')
        anchor.setAttribute('href', url + "/download")
        anchor.setAttribute('title', title)
        anchor.setAttribute('download', name)
        anchor.click()
        anchor.remove();
      }


    const openFiles = (index, params) => {
        downloadFile(params.link, params.title, params.title)
    }

    const getOnaosTuto = () => {
        const tutos = onaosTuto.map(datas => {
            return {
                label : datas.label,
                onClick: openFiles,
                params: {link : datas.lien, title : datas.fichier},
                type : datas.type === undefined ? 'pdf' : datas.type
            }
        })
        return [...tutos,  {label: 'Retour', onClick: openFiles, params: '', type: '' }]

    }

    const action = (id) => (
        <ConfirmationSnackBarDontShow
            selectStop={() => {
                dispatch(turnOffFirstConnection())
                closeSnackbar(id)
            }}
        />
    )

    useEffect(()=>{
        if (firstConnection) {
            enqueueSnackbar(
                "Bienvenu sur l'interface Loinc-Mapper d'Onaos.\nUn tutoriel est disponible au niveau du menu 'Aide (?)' se trouvant en haut à droite de votre écran.\nBon transcodage.",
                  {
                      action,
                      variant:'info',
                      persist : true,
                      style: { whiteSpace: "pre-line" },
                  }
              )
        }
    }, [])

    return (
        <NewNavBar
            onaosClick={openOnaosWebSite}
            projectInfos={{
                name: 'LOINC Mapper',
                onClick: goHome,
            }}
            style={style}
            menu={{
                style: { width: '250px' },
                datas: isOnaos ? [...MenuData, ...MenuAccess] : MenuData,
            }}
            help = {
            <HelpMenuMapper
                formationLink = {linkFormation}
                helpDatas = {getOnaosTuto()}
                highlight = {firstConnection}
            />
            }

            onaosProducts={getOnaosProject()}
            userInfos={{
                user: {
                    name: user,
                    email: email,
                },
                subMenu: [
                    { label: 'Mon compte', onClick: goto, params: 'dashboard' },
                    { label: 'Se déconnecter', onClick: openExit, params: '' },
                ],
            }}
        />
    )
}
