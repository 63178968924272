import { DashBoardTableObject, Search } from 'onaosreact'
import React, { useEffect, useState } from 'react'
import { ExportPDF } from '../../Export/ExportPDF'
import { useDispatch, useSelector } from 'react-redux'
import { checkReportLine, setReportsListing } from '../../../../redux/veillemenu/VeilleMenu'
import { useSnackbar } from 'notistack'
import { makeRequest } from '../../../../api/apihook'

export const VeilleOnaos = ({}) => {
    const listing = useSelector((state) => state.VeilleMenu.reportslisting)
    const filtersInit = useSelector((state) => state.VeilleMenu.filters)
    const tokenRefresh = useSelector((state) => state.log.tokenRefresh)

    const allowDownload = listing?.body.filter((line) => line.check === true).length > 0
    const allClients = useSelector((state) => state.InformationMenu.allClients)

    const [filters, setFilter] = useState(JSON.parse(JSON.stringify(filtersInit)))
    const [clientsAuto, setClientsAuto] = useState([])

    const dispatch = useDispatch()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    useEffect(() => {
        const clientsDtas = allClients.map((v) => {
            return v.text[0]
        })
        setClientsAuto(clientsDtas)
    }, [allClients])

    const onSearch = (text, tags) => {
        enqueueSnackbar('Recherche en cours...', {
            variant: 'info',
            persist: true,
        })
        const datas = {
            client: text,
            type: 'rapport',
            filters: tags,
        }

        makeRequest('put', '/searchUpdate', tokenRefresh, datas).then((resp) => {
          console.log("response.data:",resp.data);
          
            if (resp.data && resp.data.listing) dispatch(setReportsListing(resp.data.listing))
            if (resp.data && resp.data.filters) setFilter(resp.data.filters)
            closeSnackbar()
        })
    }

    const onCheckLine = (lineID, position) => {
        dispatch(checkReportLine(position))
    }

    const onClickLine = (id, position) => {}

    return (
        <Search
            leftComponent={
                <div style={{ visibility: 'hidden' }}>
                    <ExportPDF disabled={true} />
                </div>
            }
            rightComponent={<ExportPDF disabled={!allowDownload} />}
            searchLabel='Rechercher un client'
            searchStyle={{ height: '32px', boxSizing: 'border-box', gap: '16px' }}
            blocSearchStyle={{ justifyContent: 'space-between', padding: '0 16px 16px', marginBottom: '' }}
            styleBlockSearch={{ gap: '16px', padding: '0 16px' }}
            defaultInputValue=''
            searhcAndFilterWidth='632px'
            filterData={filters}
            filterSize = {{width: '336px'}}
            onSearch={onSearch}
            autoCompletDatas={clientsAuto}
            removePage={true}
            blockStyle={{ marginTop: '0px', height: '94%' }}
            tagStyle={{ width: '100%', padding: '0 16px 16px', boxSizing: 'border-box' }}
            allowEmpty={true}
            openBlockSearchStyle={{ borderRadius: '5px 5px 0 0' }}
        >
            <DashBoardTableObject
                body={listing.body}
                head={listing.head}
                onClick={onClickLine}
                borderLineStyle={{ height: '48px', borderBottom: '1px solid #ECECEC' }}
                headStyle={{ zIndex: 20, height: '48px' }}
                footStyle={{ height: '150px' }}
                checkbox={true}
                //     stateChangeAll = {()=>console.log("stateall")}
                onChange={onCheckLine}
                style={{ overflow: 'auto' }}
            />
        </Search>
    )
}
