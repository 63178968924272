import React from 'react'
import { useWindowSize } from 'onaosreact'
import { useSelector } from 'react-redux'
import { Informations } from './Informations/Informations'
import { Transcoding } from './Transcoding/Transcoding'
import { Files } from './Files/Files'
import { ExportFiles } from './Export/ExportFiles'
import { Traceability } from './Traceability/Traceability'
import { Veille } from './Veille/Veille'
import { Clients } from './Clients/Clients'

export const DashBoardDatas = (props) => {
    const DashBoardMenu = useSelector((state) => state.DashBoardMenu)
    const showExport = useSelector((state) => state.ExportMenu.showExport)

    const windowSize = useWindowSize()

    const withSize = () => {
        return windowSize.width * window.devicePixelRatio < 1600
            ? Math.trunc(windowSize.width) - 16 - 16
            : Math.trunc(windowSize.width) - 16 - 250 - 16 - 40
    }

    const divDashStyle = {
        width: withSize(),
       // height: '85vh',
        overflow: 'hidden',
        flexGrow : 2
        // position: 'absolute',
        // top: '110px',
        // right: '144px',
    }

    const getDashPage = (shortTitle) => {
        switch (shortTitle) {
            case 'info':
                return <Informations />
            case 'files':
                return <Files />
            case 'transco':
                return <Transcoding />
            case 'traca':
                return <Traceability />
            case 'veille':
                return <Veille />
            case 'clients':
                return <Clients />
            default:
                return <div></div>
        }
    }

    return (
        <div style={divDashStyle}>
            {showExport && <ExportFiles />}
            {getDashPage(DashBoardMenu.page[1])}
        </div>
    )
}
