import React from 'react'
import { ExportButton } from '../../Export/ExportButton'
import { TitleTransco } from './TitleTransco'
import { useSelector } from 'react-redux'

export const HeadClient = ({}) => {
    const exportButtonAvailable = useSelector((state) => state.ExportMenu.exportButtonAvailable)

    return (
        <div style={{ padding: '0 16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <TitleTransco />
            <ExportButton disabled={!exportButtonAvailable} exportType='collection' />
        </div>
    )
}
