import { ModalExports, ModalWindow } from 'onaosreact'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIdsExport, setShowExport } from '../../../redux/exportmenu/ExportMenu'
import { makeRequest } from '../../../api/apihook'
import { useSnackbar } from 'notistack'




export const ExportFiles = ({}) => {
    const exportsDatas = useSelector(state=>state.ExportMenu)
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)

    const dispatch = useDispatch()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()


    const makeFile = (blob, fileName) => {
        const urld = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = urld;
        link.title = 'Export';
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(urld);
    }

    const requestDownload = (infosDatas, infosMapperType) => {
        enqueueSnackbar('Votre export est en cours de génération, merci de patienter', { 
            variant: 'info',
            persist: true,
        }) 

        const datas = {
            type : exportsDatas.exportType,
            fileType : infosMapperType.params,
            exportType : infosDatas.label,
            formatType : exportsDatas.exportFormatAvailable.find(v => v.isActive === true).label,
            ids : exportsDatas.exportIDs
        }
        makeRequest('put', '/exportsNames', tokenRefresh, datas).then(resp => {
            if (resp.data && resp.data.fileName ){
                const fileName = resp.data.fileName
                makeRequest('put', '/exports', tokenRefresh, datas, 'blob').then(resp => {  
                    if (resp.data){
                        makeFile(resp.data,fileName)
                        closeSnackbar()
                        dispatch(setShowExport(false))
                    } else {
                        enqueueSnackbar("Une erreur s'est produite", {variant: 'error'})
                    }
                })
            }
        })
    }

    return(
        <ModalWindow style = {{   width : "max-content",height : "max-content", backgroundColor : '#f7f7f7', padding : "16px"}}>
            <ModalExports
                title = "Téléchargement"
                onClose = {()=>dispatch(setShowExport(false))}
                exportDatas = {exportsDatas.exportAvailable}
                fileTypes = {exportsDatas.exportFormatAvailable}
                transcodageTypes = {exportsDatas.mapperAvailable}
                onClick = {requestDownload}
            />
      </ModalWindow>
    )

}