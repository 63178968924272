import { Comments, LocalEntries, NeuMorphisme, TabulationMapper, InputNavigationLocalEntries } from 'onaosreact'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { editComm, setLocalInfos, setLoincInfos, setOtherInfos, setProgression, setUpdateCardInfos } from '../../../../redux/transcoding/transcoding'
import { makeRequest } from '../../../../api/apihook'
import { addCommExpert, addRecoExpert } from '../../../../redux/connection/log'
import { useSnackbar } from 'notistack'
import { clearKey } from '../../../../redux/keyboard/keyboard'




export const ClientDatas = () => {
    const [isLinkVisible, setLinkVisible] = useState(false)

    const localDatas = useSelector(state => state.Transcoding.clientDatas)
    const progression = useSelector(state => state.Transcoding.progression)
    const allComm = useSelector(state => state.log.expertComms)


    const localID = localDatas.id
    const allEntries = useSelector(state => state.Transcoding.allEntries)
    
    const notifNumber = localDatas.infosComm !== undefined  && localDatas.infosComm.comms !== undefined ? localDatas.infosComm.comms.filter(datas => datas.value !== "").length : undefined

    const tokenRefresh = useSelector(state => state.log.tokenRefresh)
    const entriesDatas = useSelector(state => state.Transcoding.nav.entry)
    const chapitresDatas = useSelector(state => state.Transcoding.nav.chapitres)
    const collectionIDs = useSelector(state => state.Transcoding.entrieID)

    const keypress = useSelector(state => state.Keyboard.keyPress)
    const showSearchEngine = useSelector((state) => state.SearchEngine.show)
    const updateCard = useSelector((state) => state.Transcoding.updateCard)



    const [tabActif, setTab] = useState(0)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    useEffect(()=>{
        if (!showSearchEngine) {
            if (keypress === 'ArrowRight') onNext()
            if (keypress === 'ArrowLeft') onPrev()
             dispatch(clearKey())
        }
    }, [keypress])


    useEffect(()=>{
        setTab(0)
    }, [localDatas.id] )

    useEffect(()=>{
        if (updateCard && updateCard.code) {
            setTab(1)
        }
    }, [updateCard])

    const dispatch = useDispatch()

    const loadEntryByID = (id) => {
        const collectionsInfos = {
            ids : collectionIDs,        
            id : id,
            chapter : chapitresDatas.id,
            kindEntry : entriesDatas.id 
        }
    
        makeRequest("put", "/card", tokenRefresh, collectionsInfos).then(resp => {
            if (resp.data) {
                // if (resp.data.allEntries && resp.data.localInfos) dispatch(setAllEntries({all : resp.data.allEntries, id : resp.data.localInfos.id})) 

                if (resp.data.localInfos) dispatch(setLocalInfos(resp.data.localInfos)) 
                if (resp.data.localInfos) dispatch(setProgression(resp.data.localInfos.id)) 

                if (resp.data.loincInfos) dispatch(setLoincInfos(resp.data.loincInfos)) 
                if (resp.data.otherInfos) dispatch(setOtherInfos(resp.data.otherInfos))
                if (resp.data.majInfos) dispatch(setUpdateCardInfos(resp.data.majInfos))
            }
        })
    }

    const onEntrySelect = (id, text) => {
        if (text !== undefined) return;
        loadEntryByID(id)
    }

    const onPrev = () => {
        const actualPosition = allEntries.findIndex(line => line.id === localID)
        const id = allEntries[actualPosition - 1] === undefined || allEntries[actualPosition - 1].id === undefined ? allEntries[allEntries.length - 1].id : allEntries[actualPosition - 1].id
        loadEntryByID(id)
    }

    const onNext = () => {
        const actualPosition = allEntries.findIndex(line => line.id === localID)
        const id = allEntries[actualPosition + 1] === undefined || allEntries[actualPosition + 1].id === undefined ? allEntries[0].id : allEntries[actualPosition + 1].id
        loadEntryByID(id)
    }

    const onComment = (type, value, position, allCommKey) => {
        const newComm = type === 'delete' ? '' : value
        const label = localDatas.infosComm.comms[position].label

        const datas = {
            type : type,
            value : newComm, 
            label : label,
            localID : localID,
            ids : collectionIDs,         
        }
        makeRequest("put", "/changeComm", tokenRefresh, datas).then(resp => {
            if (resp.data) {
                if (resp.data.ok && resp.data.msg === "") {
                    dispatch(editComm({ value : newComm, position}))
                    if (type === 'selectAndCreate' && value !== "") {
                        if (allCommKey === 'allComm') dispatch(addCommExpert({show : true, text : [value], id : value}))
                        if (allCommKey === 'allReco') dispatch(addRecoExpert({show : true, text : [value], id : value}))

                        return
                    }
                    
                }
                if (!resp.data.ok || resp.data.msg !== "" ) enqueueSnackbar(resp.data.msg, {variant: 'error', persist : false})
            }
        })
    }


    return (
        <div style = {{display : "flex", flexDirection : 'column', width : '448px', }}>
            <TabulationMapper 
                tabs = {
                    [ {title : "Entrée locale" }, {title : "Commentaires", notif : notifNumber === 0 ? undefined : notifNumber}]
                }
                style = {{width : "224px"}}
                active = {tabActif}
                onClick = {(index)=> setTab(index)}
                commNumber = {notifNumber === 0 ? undefined : notifNumber}
            />
            <NeuMorphisme style={{width : "100%",  padding : "24px 0 16px 0", display : "flex", flexDirection : "column", flexGrow : 1, borderRadius : '0px 0px 5px 5px'}}>
                <InputNavigationLocalEntries
                    selectedID={localID}
                    autoOptions={allEntries}
                    onLineClick={onEntrySelect}
                    onClickPrev={onPrev}
                    onClickNext={onNext}
                />
                {tabActif === 0 &&
                    <LocalEntries 
                        datas = {localDatas}
                        onShowDiv = {setLinkVisible}
                        defaultDiv = {isLinkVisible}
                        progression = {progression}
                    />
                }
                {tabActif === 1 && localDatas && localDatas.infosComm && localDatas.infosComm.comms &&
                    <Comments 
                        allExpertComm = {allComm}
                        comments = {localDatas.infosComm.comms}
                        onAction = {onComment}
                        style = {{marginTop : '16px'}}
                    />
                
                }
            </NeuMorphisme>
        </div>

    )
}