import { NeuMorphismeFlex } from 'onaosreact'
import React from 'react'
import { ClientsTable } from './Table'
import { ClientsHead } from './Head'




export const Clients = () => {

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <NeuMorphismeFlex style={{ justifyContent: '', height: '98%', padding: '16px 0', flexGrow: 1, gap: '16px' }}>
                <ClientsHead />
                <ClientsTable />
            </NeuMorphismeFlex>
        </div>
    )


}