import React from 'react'
import { useSelector } from 'react-redux'
import { FilesClientsAccess } from './FilesClientsAccess/FilesClientsAccess'
import { FilesOnaosAccess } from './FilesOnaosAccess/FilesOnaosAccess'
import { ImportFile } from './ImportFile/ImportFile'

export const Files = ({}) => {
    const access = useSelector((state) => state.log.access)
    const openWindow = useSelector((state) => state.FileMenu.openImport)

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    }

    const getFileCompoents = () => {
        const isOnaos = access.isAdminOnaos || access.isExpertOnaos || access.isThirdExpertOnaos
        if (isOnaos) {
            return <FilesOnaosAccess upload={access.isAdminOnaos || access.isExpertOnaos} />
        }
        const isAllowedToUpload = access.isAdmin
        return <FilesClientsAccess upload={isAllowedToUpload} />
    }

    return (
        <div style={containerStyle}>
            {openWindow && <ImportFile />}
            {getFileCompoents()}
        </div>
    )
}
