import { createSlice } from "@reduxjs/toolkit";


export const PreviewMenuHeadSlice = createSlice({
    name : "PreviewMenuHead",
    initialState:{
        tilesInfos : [],
        table : {
            body : [],
            head : [],
            originalHead : []
        },

        columnDatas : {
            activeColumn : 0,
            columnDone : [0]
        },
        show : false    
    },
    reducers: {
        setHeadTilesDatas : (state, action)=>{
            state.tilesInfos = action.payload
            return state
        },
        setTableDatas : (state, action) => {
            state.table = action.payload
            return state
        },
        setActiveColumn : (state, action) => {
            state.columnDatas.activeColumn = action.payload
            return state
        },
        setColumnDone : (state, action) => {
            state.columnDatas.columnDone = action.payload
            return state
        },
        cleanColumn : (state, action) => {
            state.columnDatas = {
                activeColumn : 0,
                columnDone : [0]
            }
            return state
        },
        changeHead : (state, action) => {
            state.table.head = action.payload
            return state
        }, 
        cleanHeadTable : (state, action) => {
            state.table = {
                body : [],
                head : [],
                originalHead : []
            }
            return state
        },
        showWindow : (state, action)=>{
            state.show = action.payload
            return state
        }
    }
})


export const {setHeadTilesDatas, setTileActive, 
    setTableDatas, setActiveColumn, setColumnDone, 
    changeHead, cleanHeadTable, showWindow, cleanColumn} = PreviewMenuHeadSlice.actions;