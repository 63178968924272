import { InputSelectTableLittle } from 'onaosreact'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeRequest } from '../../../../api/apihook'
import { setLoincInfos, setProgression, setUpdateCardInfos, turnSelectedIDToUntreated } from '../../../../redux/transcoding/transcoding'
import { useSnackbar } from 'notistack'



export const SearchLoinc = () => {

    const tokenRefresh = useSelector(states => states.log.tokenRefresh)
    const collectionIDS = useSelector(state => state.Transcoding.entrieID)
    const localID = useSelector(state => state.Transcoding.clientDatas.id)

    const entriesDatas = useSelector(state => state.Transcoding.nav.entry)
    const chapitresDatas = useSelector(state => state.Transcoding.nav.chapitres)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()


    const dispatch = useDispatch()

    const onSearchCode = (id, text) => {
        if (id === -1 && text !== undefined) {
            const datas = {
                ids : collectionIDS,        
                id : localID,
                code : text
            }
            makeRequest('put', '/addLOINCCard', tokenRefresh, datas).then(resp => {
                if (resp.data) {
                    if (resp.data.notExist) {
                        enqueueSnackbar("Le code LOINC saisi '" + text + "' n'existe pas.", {variant: 'error', persist : false})
                        return
                    }
                    if (resp.data.msg !== "") {
                        enqueueSnackbar(resp.data.msg , {variant: 'error', persist : false})
                        return
                    }
                    dispatch(turnSelectedIDToUntreated(localID))
                    dispatch(setProgression(localID))
                    refreshCard()
                }
            })
        }
    }
 
    const refreshCard = () => {
        const collectionsInfos = {
            ids : collectionIDS,        
            id : localID,
            chapter : chapitresDatas.id,
            kindEntry : entriesDatas.id 
        }
    
        makeRequest("put", "/refreshLOINCS", tokenRefresh, collectionsInfos).then(resp => {
            if (resp.data.loincInfos) dispatch(setLoincInfos(resp.data.loincInfos)) 
            if (resp.data.majInfos) dispatch(setUpdateCardInfos(resp.data.majInfos))

        })
    }

    return (
    
        <InputSelectTableLittle 
            selectedID = {""}
            autoOptions={[]}//for autocompletion
            onLineClick = {onSearchCode}
            divStyle = {{display : 'flex', flexGrow : 1, zIndex : 1, width : '' }}
            styleBlockSearchText = {{ width: '100%' }}
            placeHolder = "Ajouter un code LOINC"
            heightSize = {28}
            removeFocusAfterEnter = {true}
        />
    

    )
}