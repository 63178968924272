import { DashBoardTableObject, DownloadButton, NeuMorphismeFlex, SubMenuHelp, ThreeDotMenu, TitleDashboard } from 'onaosreact'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setExportDicoCheck, setOpenFileImport } from '../../../../redux/filemenu/FileMenu'
import { ExportButton } from '../../Export/ExportButton'
import { setExportButtonAvailable, setIdsExport } from '../../../../redux/exportmenu/ExportMenu'

export const FilesClientsAccess = ({ upload }) => {
    const exportButtonAvailable = useSelector((state) => state.ExportMenu.exportButtonAvailable)
    const fileExpertDatas = useSelector((state) => state.FileMenu.tableDatas)
    const dispatch = useDispatch()

    const neumorphismeStyle = {
        padding: '16px 0',
        justifyContent: '',
        gap: '16px',
        flexGrow: 1,
    }

    const containerStyle = {
        padding: '0 16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }

    const fontStyle = {
        fontSize: '20px',
    }

    const containerButtonStyle = {
        display: 'flex',
        justifyContent: 'end',
        gap: '16px',
    }

    const tableHeadStyle = {
        zIndex: 20,
        height: '48px',
    }

    const tableLineStyle = {
        height: '48px',
        borderBottom: '1px solid #ECECEC',
    }

    const tableFootStyle = {
        height: '150px',
    }

    useEffect(() => {
        const idCheck = fileExpertDatas.body
            .filter((line) => line.check === true)
            .map((line) => {
                return line.id
            })
        dispatch(setIdsExport(idCheck))
        dispatch(setExportButtonAvailable(idCheck.length > 0))
    }, [fileExpertDatas])

    const addFile = () => {
        dispatch(setOpenFileImport(true))
    }

    const loadNew = (lineClick, obj, lineID) => {}

    const subMenuData = [
        //TODO : add "disabled" ???
        { label: 'Remplacer le fichier', onClick: loadNew, params: '' },
    ]

    const onCheckLine = (lineID) => {
        dispatch(setExportDicoCheck(lineID))
    }

    return (
        <NeuMorphismeFlex style={neumorphismeStyle}>
            <div style={containerStyle}>
                <TitleDashboard text='Fichiers' style={fontStyle} />
                <div style={containerButtonStyle}>
                    {/* <DownloadButton
                        onClick = {addFile}
                        text = "Ajouter des fichiers"
                        disabled={!upload}
                    /> */}
                    <ExportButton disabled={!exportButtonAvailable} exportType='full' />
                </div>
            </div>
            {fileExpertDatas.body.length > 0 && (
                <DashBoardTableObject
                    body={fileExpertDatas.body}
                    head={fileExpertDatas.head}
                    maxCarac={25}
                    onClick={(id, position) => console.log('line  : ', id, position)}
                    checkbox={true}
                    //stateChangeAll={() => console.log('stateall')}
                    onChange={(lineID) => onCheckLine(lineID)}
                    headStyle={tableHeadStyle}
                    borderLineStyle={tableLineStyle}
                    footStyle={tableFootStyle}
                    // ThreeDotMenu = {
                    //     <ThreeDotMenu>
                    //         <SubMenuHelp
                    //             datas={subMenuData}
                    //             style = {{top : "28px", right : "5px", zIndex : 12}}
                    //         />
                    //     </ThreeDotMenu>
                    // }
                />
            )}
        </NeuMorphismeFlex>
    )
}
