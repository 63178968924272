import { ContinueMapper, NotTranscodable, ValidationMapper, NotInLOINCMapper, DoNotTranscode, CreateLOINC, LOINCConfirmNoGoldSelect, ConfirmationSnackBar, ConfirmationSnackBarStopShow, LOINCDuplicate } from 'onaosreact'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeRequest } from '../../../../../api/apihook'
import { useSnackbar } from 'notistack'
import { changeLOINCState,  clearNavEntry,  setAllEntries,  setLocalInfos, setLoincInfos, setNavClientPart, setOtherInfos, setProgression, setUpdateCardInfos, stopShowAllDone, turnSelectedIDTo, turnSelectedIDToUntreated } from '../../../../../redux/transcoding/transcoding'
import { clearKey } from '../../../../../redux/keyboard/keyboard'
import { setLOINCCode, setResponse, setSaveDatas, showModalGold } from '../../../../../redux/modalwindow/goldmessage'
import { useNavigate } from 'react-router'
import { clearTranscodingTable, setAllTranscoTabs, setTranscoTabActive, setTranscodingTable } from '../../../../../redux/transcodingmenu/TranscodingMenu'


export const OnaosButtons = ({userAccess, isClient}) => {

    const [allowAccess, setAllowAccess] = useState(false)
    const [showDuplication, setDuplicationWindow] = useState(false)
    const [duplicationDatas, setDuplicationDatas] = useState({head:[], body:[]})
    const [localDatasForDuplication, setLocalDatas] = useState({head:[], body:[]})
    const [datasDeletion, setDeletionDatas] = useState({})
    const [datasOverDuplication, setOverDatas] = useState({})


    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const goldInfos = useSelector(state => state.GoldMessage.infos)
    const goldResponse = useSelector(state => state.GoldMessage.response)
    const goldSaveDatas = useSelector(state => state.GoldMessage.saveDatas)
    const updateCard = useSelector(state => state.Transcoding.updateCard)
    const canShowAllDone = useSelector(state => state.Transcoding.showAllDone)

    const loincDatas = useSelector(state => state.Transcoding.loincDatas.cards)
    const collectionType = useSelector(state => state.Transcoding.otherInfos.stateCollection)
    const tokenRefresh = useSelector(state => state.log.tokenRefresh)


    const localDatas = useSelector(state => state.Transcoding.clientDatas)
    const localID = localDatas.id
    const collectionIDS = useSelector(state => state.Transcoding.entrieID)

    const entriesDatas = useSelector(state => state.Transcoding.nav.entry)
    const chapitresDatas = useSelector(state => state.Transcoding.nav.chapitres)


    const keypress = useSelector(state => state.Keyboard.keyPress)



    const dispatch = useDispatch()
    const navigate = useNavigate()

    
    useEffect(() => {
     if (keypress === 'v') validationClick()
     if (keypress === 'p') continueClick()
     if (keypress === 'l') {
        if (isClient) addNotFind() ; else notFind()
     }
     if (keypress === 'n') {
        if (isClient) doNotTranscodeClick() ; else notTranscodableClick()
     }

     dispatch(clearKey())
    }, [keypress])

        
    useEffect(() => {
        /**
         * allow for clic : 
         *  - if vérif onaos : allow only if !isclient 
         *  - if verif client : allow only if isclient 
         *  - for spécific action : depend => test on each action
         */
        if (collectionType === "Vérification Client") {
            setAllowAccess(isClient)
        } else {
            setAllowAccess(!isClient)
        }

    }, [collectionType])
   


    useEffect(()=>{
        if (goldResponse === '') return;
        onTraitment(goldSaveDatas.type)
        dispatch(showModalGold(false))
        dispatch(setResponse(''))

    }, [goldResponse])


    const clearUpdateInterface = (eraseNav = true ) => {
        dispatch(setAllEntries({all : [], id : ''}))
        dispatch(setLocalInfos({}))
        dispatch(setLoincInfos([])) 
        dispatch(setUpdateCardInfos({}))
        if (eraseNav) dispatch(clearNavEntry())
        dispatch(setProgression(''))
    }

    const onNext = (addAllEntrie = false) => {
        const collectionsInfos = {
            ids : collectionIDS,        
            chapter : chapitresDatas.id,
            kindEntry : entriesDatas.id, 
            addAllEntries : addAllEntrie,
        }
        makeRequest("put", "/collection", tokenRefresh, collectionsInfos).then(resp => {
            switch(true) {
                case resp.data !== undefined && resp.data.localInfos !== undefined:
                    if (resp.data.allEntries && resp.data.allEntries.length > 0) dispatch(setAllEntries({all : resp.data.allEntries, id : resp.data.localInfos.id}))
                    if (resp.data.localInfos) dispatch(setLocalInfos(resp.data.localInfos)) 
                    if (resp.data.loincInfos) dispatch(setLoincInfos(resp.data.loincInfos)) 
                    if (resp.data.otherInfos) dispatch(setOtherInfos(resp.data.otherInfos))
                    if (resp.data.localInfos) dispatch(setProgression(resp.data.localInfos.id))
                    if (resp.data.majInfos) dispatch(setUpdateCardInfos(resp.data.majInfos))
                    if (chapitresDatas.id !== "all" && resp.data.nav.clientBlock.chapitres.id !== chapitresDatas.id) {
                        dispatch(setNavClientPart(resp.data.nav.clientBlock))
                    }


                    if (resp.data.allDone && canShowAllDone) {
                        const msg = isClient ? 
                            "Félicitations vous avez terminé votre relecture. Retourner sur la page d'accueil pour télécharger vos transcodages"
                            :
                            "Transcodage terminé pour cette collection, retourner à la page d'accueil ?"
                        
                        enqueueSnackbar(msg, {
                            action : returnDashboard,
                            variant: 'info',
                            persist: true,
                        })
                        
                    }
                    break
                default:
                    clearUpdateInterface(false)
                    break
            }
        })
    }

    const refreshCard = () => {
        const collectionsInfos = {
            ids : collectionIDS,        
            id : localID,
            chapter : chapitresDatas.id,
            kindEntry : entriesDatas.id 
        }
    
        makeRequest("put", "/refreshLOINCS", tokenRefresh, collectionsInfos).then(resp => {
            if (resp.data.loincInfos) dispatch(setLoincInfos(resp.data.loincInfos)) 
            if (resp.data.majInfos) dispatch(setUpdateCardInfos(resp.data.majInfos))

        })
    }

    const loadTransco = () => {
        dispatch(clearTranscodingTable())
        makeRequest("get", "/transcodingPage", tokenRefresh).then(resp=>{
            if (resp.data && resp.data.collections) dispatch(setTranscodingTable(resp.data.collections))
            if (resp.data && resp.data.allTabs) dispatch(setAllTranscoTabs(resp.data.allTabs))
            dispatch(setTranscoTabActive(0))
            navigate("/mapper/dashboard")
        })
    }



    const returnDashboard = id => (
        <ConfirmationSnackBarStopShow
            selectConfirm = {() => {loadTransco(); closeSnackbar(id)}}
            selectDismiss = {() => {closeSnackbar(id)}}
            selectStop = {() => {dispatch(stopShowAllDone()); closeSnackbar(id)}}
        />
    );

    const returnDashboardOrClean = id => (
        <ConfirmationSnackBar
            selectConfirm = {() => {loadTransco();   closeSnackbar(id)}}
            selectDismiss = {() => { 
                clearUpdateInterface()
                closeSnackbar(id) 
            }}
        />
    );

    


    const deleteCard = () => {
        const datas = {
            entryID : localID,
            collectionIDS : collectionIDS,
        }
        makeRequest('put', '/removeUpdateCard', tokenRefresh, datas).then(resp => {
            if (resp.data) {
                switch(true) {
                    case resp.data.ok:
                        onNext(true)
                        break
                    case resp.data.empty !== undefined:
                        enqueueSnackbar("La collection en cours n'existe plus, cliquer pour revenir sur le dashboard", {
                            action : returnDashboardOrClean,
                            variant: 'warning',
                            persist: true,
                          })
                
                        break
                    default:
                        const msg = resp.data.msg ? resp.data.msg : "Une erreur s'est produite"
                        enqueueSnackbar(msg , {variant: 'error', persist : false})
                        break
                }
            }
        })

    }


    const askDuplicate = () => {
        const datas = {
            localID : localID,
            collectionIDS : collectionIDS,

        }
        makeRequest('put', '/findDuplication', tokenRefresh, datas).then(resp=> {
            if (resp.data && resp.data.find) {
                setDuplicationDatas(resp.data.entries)
                setLocalDatas(resp.data.entry)
                setDuplicationWindow(true)
            } else {
                onNext()
            }
        })
    }

    const onTraitment = (type) => {
        const datas = {
            type : type,
            entryID : localID,
            collectionIDS : collectionIDS,
            goldResponse : goldResponse
        }
        makeRequest('put', '/onaosTraitment', tokenRefresh, datas).then(resp => {
            if (resp.data) {
                if (!resp.data.ok && resp.data.msg === "goldcomm") {
                    dispatch(showModalGold(true))
                    dispatch(setLOINCCode(resp.data.code))
                    dispatch(setSaveDatas(datas))
                    return 
                }   
    

                if (!resp.data.ok && resp.data.msg !== "") {
                    enqueueSnackbar(resp.data.msg , {variant: 'error', persist : false})
                    return
                }
                if (resp.data.newState !== undefined && resp.data.newState.color !== '') {
                    dispatch(turnSelectedIDTo({id : localID, color : resp.data.newState.color, state : resp.data.newState.text}))
                }

                if (!isClient && type !== 'passer')  { //todo remove if traitement = passe) 
                    askDuplicate()
                    return
                }

                onNext()
            }
        })
    }


    const addCard = (type) => {
        const datas = {
            type : type,
            entryID : localID,
            collectionIDS : collectionIDS
        }
        makeRequest('put', '/addNonLOINCCard',tokenRefresh, datas).then(resp => {
            if (resp.data) {
                if (!resp.data.ok) {
                    enqueueSnackbar(resp.data.msg , {variant: 'error', persist : false})
                    return
                }
                if (resp.data.add) { 
                    dispatch(turnSelectedIDToUntreated(localID))
                    dispatch(setProgression(localID))
                    refreshCard()
                }
            }
        })  

    }
   

    const notTranscodableClick = () => {
        if (!allowAccess) {
            enqueueSnackbar("Action non autorisée", {variant: 'error', persist : false})
            return;
        }  
        addCard('untranscodable')

    }


    const clickOnFirstCardAndValidate = () => {
        const datas = {
            entryID : localID,
            collectionIDS : collectionIDS,
            cards : {
                code : loincDatas[0].code,
                actualStatusInfos : loincDatas[0].Status,
            }
        }
        makeRequest('put', '/clickOnCard', tokenRefresh, datas).then(resp => {
            if (resp.data){
                if (resp.data.msg !== undefined && resp.data.msg !== "" ) {
                    enqueueSnackbar(resp.data.msg, {variant: 'error', persist : false})
                    return
                }
                dispatch(changeLOINCState({datas : resp.data, type : collectionType})) 
                onTraitment("validation")
            }
        })
    }

    const validationClick = () => {
        if (!allowAccess) {
            enqueueSnackbar("Action non autorisée", {variant: 'error', persist : false})
            return;
        } 

        if ( 
            (collectionType === "Vérification Onaos" && loincDatas.filter(cards => cards.Status["sélectionné expert"] === true).length === 0) 
            || 
            (collectionType === "Review" && loincDatas.filter(cards => cards.Status["sélectionné review expert"] === true ).length === 0)
         ) {
            enqueueSnackbar("Veuillez sélectionner au moins 1 carte ou cliquer sur passer", {variant: 'warning', persist : false, autoHideDuration: 7000 })
            return; 
         }

        if (loincDatas.length > 1 && collectionType === "Vérification Client" && loincDatas.filter(cards => cards.Status["sélectionné client"] === true ).length === 0) {
            enqueueSnackbar("Veuillez sélectionner au moins 1 carte, marquer l'entrée comme non transcodable, demander la création d'un code LOINC ou cliquer sur passer", {variant: 'warning', persist : false, autoHideDuration: 7000 })
            return
        }

        if ( collectionType === "Vérification Client" && loincDatas.length === 1 && loincDatas[0].Status["sélectionné client"] !== true) {
            clickOnFirstCardAndValidate()
           return
        } 
        
        onTraitment("validation")
        

       
    }

    const continueClick = () => {
        if (!allowAccess) {
            enqueueSnackbar("Action non autorisée", {variant: 'error', persist : false})
            return;
        }
        onTraitment("passer")
    }

    
    const notFind = () => {
        if (!allowAccess) {
            enqueueSnackbar("Action non autorisée", {variant: 'error', persist : false})
            return;
        }
        addCard('notfind')
    }

    const addNotFind = () => {
        if (!allowAccess) {
            enqueueSnackbar("Action non autorisée", {variant: 'error', persist : false})
            return;
        }
        addCard('create')
    }
    
    const doNotTranscodeClick = () => {
        if (!allowAccess) {
            enqueueSnackbar("Action non autorisée", {variant: 'error', persist : false})
            return;
        }
        addCard('donottranscode')
    }


    const action = id => (
        <ConfirmationSnackBar
            selectConfirm = {() => { deleteCard(); closeSnackbar(id)}}
            selectDismiss = {() => { closeSnackbar(id) }}
        />
      );
  
    const actionRemoveDuplicate = id => (
        <ConfirmationSnackBar
            selectConfirm = {() => { deleteDuplication(); closeSnackbar(id)}}
            selectDismiss = {() => { closeSnackbar(id)}}
        />
    );


    const actionForceDuplicate = id => (
        <ConfirmationSnackBar
            selectConfirm = {() => { validateDuplication(); closeSnackbar(id)}}
            selectDismiss = {() => { closeSnackbar(id)}}
        />
    );


    const removeCard = () => {
        if (!allowAccess) {
            enqueueSnackbar("Action non autorisée", {variant: 'error', persist : false})
            return;
        }
        enqueueSnackbar("Supprimer cette entrée ? Attention cette action est irréversible !", {
            action,
            variant: 'warning',
            autoHideDuration: 5000,
          })
    }

    const validateDuplication = (data) => {
        const datas = data === undefined ? datasOverDuplication : data
        makeRequest('put', '/duplicateTransco', tokenRefresh, datas).then(resp => {
            setDuplicationWindow(false)
            //change to treated !
            onNext(true)
        })
    }

    
    const validateAndClose = (datasSelect) => {
        const ids = datasSelect.map(data => {
            if (data.check) return data.id
        })
        const datas = {
            localID : localID,
            collectionIDS : collectionIDS,
            datas : ids
        }
        if (datasSelect.filter(line => line.check === true && line.backgroundColor !== '' ).length > 0) {
            setOverDatas(datas)
            enqueueSnackbar("Attention des entrées déjà traitées vont voir leur transcodage modifié, confirmer ?", {
                action:actionForceDuplicate,
                variant: 'warning',
                autoHideDuration: 5000,
              })
        } else {
            validateDuplication(datas)
        }


    }

    const removeAndClose = (datas)=> {
        //show notistack for confirmation

        const dataDeletion = {
            localID : localID,
            datas : datas.map(data => {
                if (data.check) return data.id
            }),
            collectionIDS : collectionIDS,
        }

        setDeletionDatas(dataDeletion)
        enqueueSnackbar("Cette action va repasser les entrées sélectionnées en 'Non traitées', confirmer ?", {
            action:actionRemoveDuplicate,
            variant: 'warning',
            autoHideDuration: 5000,
          })
    }

    const deleteDuplication = ()=> {
        makeRequest('put', '/removeDuplicateTransco', tokenRefresh, datasDeletion).then(resp => {
            setDuplicationWindow(false)
            onNext(true)
        })
    }

    
    const dismissAndClose = ()=> {
        setDuplicationWindow(false)
    }

    
    const passAndClose = ()=> {
        setDuplicationWindow(false)
        onNext()
    }


    
    return (
        <React.Fragment>
            {showDuplication && 
                <LOINCDuplicate 
                    entryInfos={localDatasForDuplication}
                    entriesInfos={duplicationDatas}
                    onValidate={validateAndClose}
                    onCancel={dismissAndClose}
                    onPass={passAndClose}
                    onRemove={removeAndClose}
                />
            }
            {goldInfos.show && 
                <LOINCConfirmNoGoldSelect 
                    title = "Absence de justification pour la carte gold"
                    loincGold = {goldInfos.code}
                    clickCancel = {()=>dispatch(showModalGold(false))}
                    action = {(action)=>dispatch(setResponse(action))}
                />
            }
            {!isClient && updateCard && updateCard.code && collectionType !== "Vérification Client" &&  <DoNotTranscode style= {{backgroundColor : 'rgb(63, 61, 61)'}} text = "Ne pas proposer la MAJ" onClick ={removeCard} /> }
            {isClient || collectionType === "Vérification Client" ? <DoNotTranscode onClick ={doNotTranscodeClick} disabled = {!userAccess}/> :  <NotTranscodable onClick ={notTranscodableClick} disabled = {!userAccess}/>}
            {isClient || collectionType === "Vérification Client" ? <CreateLOINC onClick ={addNotFind} disabled = {!userAccess}/> :  <NotInLOINCMapper onClick ={notFind} disabled = {!userAccess}/>}
            <ContinueMapper onClick ={continueClick} disabled = {!userAccess}/>
            <ValidationMapper onClick ={validationClick} disabled = {!userAccess}/>
        </React.Fragment>
    )
}