import { ModalWindow, NeuMorphisme, NeuMorphismeFlex } from 'onaosreact'
import React from 'react'
import { useSelector } from 'react-redux'
import { RawFiles } from './Tabs/RawFiles'
import { Transcoding } from './Tabs/PreviewFile/Transcoding'
import { HeadPreview } from './Tabs/PreviewFile/HeadPreview'
import { Confirmation } from './Tabs/PreviewFile/Confirmation'
import { ConfirmationEdition } from './Tabs/PreviewFile/ConfirmationEdition'

export const FilesOnaosAccess = ({ upload }) => {
    const showTranscode = useSelector((state) => state.TranscodingModal.showTranscode)
    const showHead = useSelector((state) => state.PreviewMenuHead.show)
    const showConfirmation = useSelector((state) => state.PreviewMenu.show)
    const showConfirmationEdition = useSelector((state) => state.ConfirmationAndEdition.show)

    const neumorphismeFlexStyle = {
        padding: '16px 0',
        justifyContent: '',
        flexGrow: 1,
        overflow: 'hidden',
    }

    const modalStyle = {
        width: '95%',
        height: '95%',
        overflow: 'auto',
    }

    const neumorphismeStyle = {
        width: '100%',
        height: '100%',
        padding: '16px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
    }

    return (
        <NeuMorphismeFlex style={neumorphismeFlexStyle}>
            {showTranscode && <Transcoding />}
            {(showConfirmation || showHead || showConfirmationEdition) && (
                <ModalWindow style={modalStyle}>
                    <NeuMorphisme style={neumorphismeStyle}>
                        {showHead && <HeadPreview />}
                        {showConfirmation && <Confirmation />}
                        {showConfirmationEdition && <ConfirmationEdition />}
                    </NeuMorphisme>
                </ModalWindow>
            )}
            <RawFiles />
        </NeuMorphismeFlex>
    )
}
