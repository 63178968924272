import React, { useEffect } from 'react'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setDash } from '../redux/connection/log.js'
import { HeadMenu } from '../menu/HeadMenu.js'
import { Menu } from './menu/Menu.js'
import { DashBoardDatas } from './dashboarddatas/DashBoardDatas.js'
import { internalDiv } from '../styles/internalDiv.js'
import { ConfirmationSnackBar, useWindowSize } from 'onaosreact'

export const Dashboard = (props) => {
    const windowSize = useWindowSize()

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams()


    useEffect(() => {
        if (searchParams.has('t') || searchParams.has('sso')) {
            let newParams = []
            setSearchParams(newParams)
            navigate(
                {
                    search: createSearchParams(newParams).toString(),
                },
                { replace: true },
            )
        }
        dispatch(setDash(false))
    }, [])

    return (
        <div style = {{height : '100vh', display : 'flex', flexDirection : 'column', position:'relative'}}>
            <div style = {{position : 'absolute', width : '100%'}}>
                <HeadMenu />
            </div>
            <div style={{...internalDiv.mainContainerStyle, margin : '', width : '', padding : '64px 16px 16px 16px', height:'100%', boxSizing : 'border-box'}}>
                <div style={{...internalDiv.containerStyle, gap :'16px', height : '100%'} }>
                    {Math.trunc(windowSize.width * window.devicePixelRatio)  >= 1600 && <Menu />}
                    <DashBoardDatas />
                </div>
            </div>
        </div>
    )
}
