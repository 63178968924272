import { TitleDashboard } from 'onaosreact'
import React from 'react'

export const ClientsHead = ({}) => {
    const containerTitleStyle = {
        padding: '0 16px',
    }

    return (
        <div style={containerTitleStyle}>
            <TitleDashboard text="Clients" style={{ fontSize: '20px' }} />
        </div>
    )
}
