import { ButtonSelectAll, TitleDashboard } from 'onaosreact'
import React from 'react'
import { useSelector } from 'react-redux'

export const LOINCHead = ({ showTable, setShowTable }) => {
    // const [tmpTable, tmpShowTable] = useState(false) //TODO in API/redux
    const userAccess = useSelector((state) => state.log.access)

    const containerStyle = {
        padding: '0 16px',
        display: 'flex',
        alignItems: 'center',
    }

    const fontSizeStyle = {
        fontSize: '20px',
    }

    const labelStyle = {
        width: '48px',
        height: '22px',
    }

    const buttonStyle = {
        width: '16px',
        height: '16px',
    }

    // const showTable = () => {
    //     tmpShowTable(!tmpTable)
    // }

    const handleToggle = () => {
        setShowTable(!showTable)
    }

    return (
        <div style={containerStyle}>
            <TitleDashboard text='Propositions LOINC' style={fontSizeStyle} />
            {(userAccess.isExpertOnaos || userAccess.isThirdExpertOnaos || userAccess.isAdminOnaos) && (
                <ButtonSelectAll isOn={showTable} handleToggle={handleToggle} id='show_table' styleLabel={labelStyle} styleButton={buttonStyle} />
            )}
        </div>
    )
}
