import { MainMapperProgress, useWindowSize } from 'onaosreact'
import React from 'react'
import { useSelector } from 'react-redux'



export const ProgressBar = () => {
   
    const progressInfos = useSelector(state => state.Transcoding.progression)

    const windowSize = useWindowSize()
    const maxPixel = windowSize.width - (16*2)


    const Legende = ({color}) => {
        return (
            <div style = {{width :'16px', height : "16px", borderRadius : "50%", backgroundColor : color }} />
        )
    }

    const LegendeText = ({text}) => {
        return (
            <span style = {{color :'#383C3F', fontFamily : 'Montserrat', fontSize : '13px' }}>
                {text}
            </span>
        )
    }


    return (
        <div style = {{marginBottom : "8px"}}>
        {progressInfos && 
            <div style = {{height : "41px", width : "100%", display : 'flex', flexDirection : 'column', gap : "7px" }}>
                <MainMapperProgress
                    values = {progressInfos.progress}
                    maxWidth = {maxPixel}
                    style = {{width : maxPixel+"px" }}
                    progressText = {progressInfos.texts}
                />
                <div style = {{height : "16px", width : "100%", display : "flex", gap : '17px'}}>
                    <div style = {{display : 'flex', gap : '8px'}}>
                        <Legende color = '#488E69'/>
                        <LegendeText text = "Examens vérifiés" />
                    </div>
                    <div style = {{display : 'flex', gap : '8px'}}>
                        <Legende color = '#D7872F'/>
                        <LegendeText text = "Examens passés" />
                    </div>
                    <div style = {{display : 'flex', gap : '8px'}}>
                        <Legende color = '#C43C3C'/>
                        <LegendeText text = "Examens à vérifier" />
                    </div>
                </div> 
            </div>
        }
        </div>
    )
}