import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NeuMorphismeFlex, TabulationMapper } from 'onaosreact'
import { ExportFiles } from '../Export/ExportFiles'
import { TableOnaos } from './Table/TableOnaos'
import { TableClient } from './Table/TableClient'
import { HeadClient } from './Head/HeadClient'
import { HeadOnaos } from './Head/HeadOnaos'
import { clearTranscodingBody, clearTranscodingTable, setFilters, setTranscoTabActive, setTranscodingTable } from '../../../redux/transcodingmenu/TranscodingMenu'
import { makeRequest } from '../../../api/apihook'
import { useSnackbar } from 'notistack'

export const Transcoding = ({}) => {
    const userAccess = useSelector((state) => state.log.access)
    const transcoTable = useSelector((state) => state.TranscodingMenu.transcodingTable)
    const mapperTypes = useSelector((state) => state.TranscodingMenu.tabs)
    const tabSelected = useSelector((state) => state.TranscodingMenu.tabActive)
    const tokenRefresh = useSelector((state) => state.log.tokenRefresh)
    const searchSave = useSelector((state) => state.TranscodingMenu.searchSave)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const dispatch = useDispatch()

    const getTable = () => {
        if (transcoTable.length === 0) return <div></div>
        if (userAccess.isAdminOnaos || userAccess.isExpertOnaos || userAccess.isThirdExpertOnaos) return <TableOnaos />
        return <TableClient />
    }

    const getHead = () => {
        if (userAccess.isAdminOnaos || userAccess.isExpertOnaos || userAccess.isThirdExpertOnaos) return <HeadOnaos />
        return <HeadClient />
    }

    const adminSearch = (index, id) => {
        const datas = {
            client: searchSave.client,
            type : mapperTypes[index].key,
            filters: searchSave.filters,
        }
          
        makeRequest('put', '/searchCollections', tokenRefresh, datas).then((resp) => {
            if (resp.data && resp.data.collections) dispatch(setTranscodingTable(resp.data.collections))
            if (resp.data && resp.data.filters ) dispatch(setFilters(resp.data.filters))
            
            closeSnackbar(id)
        })
    }

    const clientSearch = (index, id) => {
        makeRequest('get', '/transcodingPage/' + mapperTypes[index].key, tokenRefresh).then((resp) => {
            if (resp.data && resp.data.collections) dispatch(setTranscodingTable(resp.data.collections))  
            if (resp.data && resp.data.filters ) dispatch(setFilters(resp.data.filters))
            
            closeSnackbar(id)
        })
    }

    const getListingMapping = (index) => {
        const id = enqueueSnackbar('Chargement en cours...', {
            variant: 'info',
            persist: true,
        })
        dispatch(clearTranscodingBody())
        dispatch(setTranscoTabActive(index))

        if (userAccess.isAdminOnaos || userAccess.isExpertOnaos || userAccess.isThirdExpertOnaos) {
            adminSearch(index, id)
        } else {
            clientSearch(index, id)
        }
        
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            {mapperTypes.length > 0 && <TabulationMapper tabs={mapperTypes} active={tabSelected} onClick={getListingMapping} />}
            <NeuMorphismeFlex style={{ justifyContent: '', height: '98%', padding: '16px 0', flexGrow: 1, gap: '16px' }}>
                {getHead()}
                {getTable()}
            </NeuMorphismeFlex>
        </div>
    )
}
