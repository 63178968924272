import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SearchEngine } from 'onaosreact'
import { setShowSearchEngine, setShowSaveFilter } from '../../../../redux/transcodingmenu/SearchEngine'
import { setLoincInfos, setProgression, setUpdateCardInfos, turnSelectedIDToUntreated } from '../../../../redux/transcoding/transcoding'
import { makeRequest } from '../../../../api/apihook'
import { useSnackbar } from 'notistack'

export const DisplaySearchEngine = () => {
    const [filtersSearch, setFiltersSearch] = useState({})
    const [searchResult, setSearchResult] = useState({ head: [], body: [] })
    const [detailsPanel, setDetailPanel] = useState({})
    const [filterChoice, setFilterChoice] = useState(0)

    const localDatas = useSelector((state) => state.Transcoding.clientDatas)
    const loincDatas = useSelector((state) => state.Transcoding.loincDatas.cards)

    const collectionIds = useSelector((state) => state.Transcoding.entrieID)
    const localId = useSelector((state) => state.Transcoding.clientDatas.id)
    const entriesDatas = useSelector((state) => state.Transcoding.nav.entry)
    const chapitresDatas = useSelector((state) => state.Transcoding.nav.chapitres)
    const autoComplete = useSelector((state) => state.SearchEngine.autoComplete)
    const showListingFilter = useSelector((state) => state.SearchEngine.listingFilter)

    const tokenRefresh = useSelector((state) => state.log.tokenRefresh)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [LOINCSelected, setLOINCSelected] = useState(undefined)
    const [activeItem, setActiveItem] = useState(-1)

    const [composant, setComposant] = useState('')

    const dispatch = useDispatch()

    const filterSizeStyle = {
        width: '336px',
    }

    const closeSearchEngine = () => {
        dispatch(setShowSearchEngine(false))
    }

    const openDetailsPanel = (id) => {
        makeRequest('get', '/panelInfos/' + id, tokenRefresh).then((resp) => {
            if (resp.data && resp.data.loincdetails) {
                setDetailPanel(resp.data)
            }
        })
    }

    const closeDetailsPanel = () => {
        setDetailPanel({})
    }

    const selectChangeFilter = (value) => {}

    const showRecord = () => {
        dispatch(setShowSaveFilter(true))
    }

    const onSearch = (text, tags) => {
        if (text === '') return
        const datas = {
            idCollection: collectionIds,
            composant: text,
            searchinfos: {
                codeType: 'loinc',
                mode: 'auto',
                filters: tags,
            },
        }
        makeRequest('put', '/search', tokenRefresh, datas).then((resp) => {
            if (resp.data) {
                if (resp.data.filters) setFiltersSearch(resp.data.filters)
                if (resp.data.results && resp.data.head)
                    setSearchResult({
                        head: resp.data.head,
                        body: resp.data.results,
                    })
                //TODO : history
            }
        })
    }

    const refreshCard = () => {
        const collectionsInfos = {
            ids: collectionIds,
            id: localId,
            chapter: chapitresDatas.id,
            kindEntry: entriesDatas.id,
        }

        makeRequest('put', '/refreshLOINCS', tokenRefresh, collectionsInfos).then((resp) => {
            if (resp.data.loincInfos) dispatch(setLoincInfos(resp.data.loincInfos))
            if (resp.data.majInfos) dispatch(setUpdateCardInfos(resp.data.majInfos))
        })
    }

    const onAddCode = (id, text) => {
        if (id === -1 && text !== undefined) {
            const datas = {
                ids: collectionIds,
                id: localId,
                code: text,
            }

            makeRequest('put', '/addLOINCCard', tokenRefresh, datas).then((resp) => {
                if (resp.data) {
                    if (resp.data.msg !== '') {
                        enqueueSnackbar(resp.data.msg, { variant: 'error', persist: false })
                        return
                    }

                    dispatch(turnSelectedIDToUntreated(localId))
                    dispatch(setProgression(localId))
                    refreshCard()
                    closeSearchEngine()
                }
            })
        }
    }

    useEffect(() => {
        if (localDatas && localDatas.fullLib && loincDatas) {
            const libelles = loincDatas.filter(
                (loincs) =>
                    loincs.code !== undefined &&
                    (Number.isInteger(loincs.code) || !isNaN(loincs.code.charAt(0))) &&
                    loincs.fullLib !== '' &&
                    (loincs.Status['sélectionné expert'] === true || loincs.Status['sélectionné review expert'] === true),
            )
            const libelle = libelles.length === 0 ? localDatas.fullLib : libelles[0].fullLib
            const datas = {
                lib: localDatas.fullLib,
                ids: collectionIds,
                id: localId,
            }
            makeRequest('put', '/findComposant', tokenRefresh, datas).then((resp) => {
                if (resp && resp.data) {
                    setComposant(resp.data.composant ? resp.data.composant : libelle)
                }
            })
        }
    }, [localDatas])

    const getLOINCInfos = (code) => {
        const index = searchResult.body.findIndex((line) => line.id === code)
        const newActive = activeItem === index ? -1 : index
        setActiveItem(newActive)

        makeRequest('get', '/loincCard/' + code, tokenRefresh).then((resp) => {
            if (resp && resp.data) {
                setLOINCSelected(resp.data)
            }
        })
    }

    const onValid = () => {
        if (LOINCSelected && LOINCSelected.code) onAddCode(-1, LOINCSelected.code)
    }

    return (
        <React.Fragment>
            {/* {showIsOpen && (
                <div>
                    {showSaveFilter && (
                        <ModalWindow
                            modalObject={
                                <ModalWindowSave
                                    title='Nom pour le groupe de filtres'
                                    placeHolder=''
                                    change={(e) => dispatch(setShowNewFilterName(e))}
                                    cancel={() => dispatch(showNewFilterName(false))}
                                    // save={addNewFilterName}
                                />
                            }
                        />
                    )}
                </div>
            )} */}
            <SearchEngine
                datasPanelDetails={detailsPanel}
                onCloseLink={closeDetailsPanel}
                datasLocalEntries={localDatas}
                datasLOINCSelected={LOINCSelected}
                onClickValid={onValid}
                onClickCancel={closeSearchEngine}
                onSearch={onSearch}
                filterdata={filtersSearch}
                autoCompletDatas={autoComplete}
                datasResult={searchResult}
                onLineClick={getLOINCInfos}
                onOpenLink={openDetailsPanel}
                recordFilter={showListingFilter}
                onFilterChoice={selectChangeFilter}
                filterSelected={filterChoice}
                record={showRecord}
                defaultInput = {composant}
                activeItem = {activeItem}
                filterSize={{ width: '32%' }}
            />
        </React.Fragment>
    )
}
