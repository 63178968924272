import React from 'react'
import { HeadVeilleOnaos } from './Head/HeadVeilleOnaos'
import { HeadVeilleClient } from './Head/HeadVeilleClient'
import { VeilleOnaos } from './Table/VeilleOnaos'
import { VeilleClient } from './Table/VeilleClient'
import { NeuMorphismeFlex } from 'onaosreact'
import { useSelector } from 'react-redux'


export const Veille = ({}) => {
    const userAccess = useSelector((state) => state.log.access)

    const getTable = () => {
        if (userAccess.isAdminOnaos || userAccess.isExpertOnaos || userAccess.isThirdExpertOnaos) return <VeilleOnaos />
        return <VeilleClient />
    }

    const getHead = () => {
        if (userAccess.isAdminOnaos || userAccess.isExpertOnaos || userAccess.isThirdExpertOnaos) return <HeadVeilleOnaos />
        return <HeadVeilleClient />
    }



    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <NeuMorphismeFlex style={{ justifyContent: '', height: '98%', padding: '16px 0', flexGrow: 1, gap: '16px' }}>
                {getHead()}
                {getTable()}
            </NeuMorphismeFlex>
        </div>
    )
}
