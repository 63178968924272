import { createSlice } from "@reduxjs/toolkit";


export const PreviewMenuSlice = createSlice({
    name : "PreviewMenu",
    initialState:{
        page : '',
        confirmationDatas : {
            table : {
                body : [],
                head : []
            },
            for : '',
            title : "",
            other : []
        },
        activeFileID : "",
        confirmationBtn : false,
        show : false
    },
    reducers: {
        setPageName : (state, action) => {
            state.page = action.payload
            return state 
        },
        setDatasForConfirmation : (state, action) => {
            state.confirmationDatas.table = action.payload
            return state
        },
        setDatasForConfirmationTitleandFor : (state, action) => { //for dev only
            state.confirmationDatas.title = action.payload.title
            state.confirmationDatas.for = action.payload.for
            return state
        },
        setActiveFileID : (state, action) => { 
            state.activeFileID = action.payload
            return state
        }, 
        cleanTableConfirm : (state, action)=> {
            state.confirmationDatas = {
                table : {},
                for : '',
                title : "",
                other : {}
            }
            state.activeFileID = ""
            return state

        },
        activeConfirmationBtn : (state, action) => {
            state.confirmationBtn = action.payload 
            return state
        },
        setDatasOtherForConfirmation : (state, action) => {
            state.confirmationDatas.other = action.payload
            return state
        },
        showWindowConfirmation : (state, action)=>{
            state.show  = action.payload 
            return state
        }
    }
})


export const {setPageName, setDatasForConfirmation, 
    setDatasForConfirmationTitleandFor, setActiveFileID,
    cleanTableConfirm, activeConfirmationBtn, setDatasOtherForConfirmation,
    showWindowConfirmation
} = PreviewMenuSlice.actions;