import React from 'react'
import { ClientDatas } from './ClientDatas/ClientDatas'
import { LOINCDatas } from './LOINCDatas/LOINCDatas'

export const LoincMain = () => {
    const containerStyle = {
        display: 'flex',
        gap: '16px',
        flexGrow: 2,
    }

    return (
        <div style={containerStyle}>
            <ClientDatas />
            <LOINCDatas />
        </div>
    )
}
