import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setKeyPress } from '../redux/keyboard/keyboard'
import { HeadMenu } from '../menu/HeadMenu'
import { OnaosTranscoding } from './OnaosTranscoding/OnaosTranscoding'
import { internalDiv } from '../styles/internalDiv'

export const Transcodage = () => {
    const showGoldMsg = useSelector((state) => state.GoldMessage.infos.show)
    const showSearchEngine = useSelector((state) => state.SearchEngine.show)

    const dispatch = useDispatch()

    const containerStyle = {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    }

    const headMenuStyle = {
        marginBottom: '',
    }

    const internalDivStyle = {
        ...internalDiv.mainContainerStyle,
        marginLeft: '',
        marginRight: '',
        width: '',
        gap: '16px',
        height: '',
        width: '',
        padding: '0 16px',
        flexGrow: 1,
    }

    const downHandler = (event) => {
        if (!showSearchEngine && 
            !showGoldMsg &&
            event.target.localName !== 'input' &&
            (event.key === 'v' || event.key === 'p' || event.key === 'n' || event.key === 'l' || event.key === 'ArrowRight' || event.key === 'ArrowLeft' )
        ) {
            dispatch(setKeyPress(event.key))
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', downHandler)
        return () => window.removeEventListener('keydown', downHandler)
    }, [])

    return (
        <div style={containerStyle}>
            <HeadMenu style={headMenuStyle} />
            <div style={internalDivStyle}>
                <OnaosTranscoding />
            </div>
        </div>
    )
}
