import {  ButtonCancel, DashBoardTableObject, DownloadButton, TitleAndClose, Search, InputSelectTableLittle } from 'onaosreact'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkAllConfirmData, checkConfirmationData, showWindowConfirmationEdition } from '../../../../../../redux/filemenu/ConfirmationAndEdition'
import { filesPage } from '../../../../../../api/utils'



export const ConfirmationEdition = ({}) => {
    const confirmationDatas = useSelector(state => state.ConfirmationAndEdition.table)
    const actionsDatas = useSelector(state => state.ConfirmationAndEdition.actions)
    const filterDatas = useSelector(state => state.ConfirmationAndEdition.filters)
    const tokenRefresh = useSelector((state) => state.log.tokenRefresh)

    const [selectID, setID] = useState('')
    const [selectIDFilter, setIDFilter] = useState('')
    const [checkAllState, setCheckAllState] = useState(false)

    const dispatch = useDispatch()

    const closePreview = () => {
        filesPage({
            tokenRefresh: tokenRefresh,
            dispatch: dispatch,
            callback:dispatch(showWindowConfirmationEdition(false))
        })
    }

    const onCheckLine = (lineID) =>{
        dispatch(checkConfirmationData(lineID))
    }

    const checkAll = () => {
        dispatch(checkAllConfirmData(!checkAllState))
        setCheckAllState(!checkAllState)
    }

    const doSomething = () => {
        console.log("action : ", selectID)
    }

    const selectAction = (id, text) => {
        if (id === -1) return

        setID(id)
    }

    return(
        <React.Fragment>
            <TitleAndClose 
                title = "Modifier"
                style = {{fontSize : "20px", paddingLeft : "16px", margin : '0px'}}
                onClose = {closePreview}
            />    
            <div style = {{display : 'flex', gap : '16px'}}>     
                <div style = {{display : 'flex', gap : '16px', zIndex : 100, width : '50%' }}>     
                    <InputSelectTableLittle 
                        selectedID = {selectID}
                        autoOptions={actionsDatas}
                        onLineClick = {selectAction}
                        divStyle = {{display : 'flex', flexGrow : 1, width : '100%' }}
                        styleBlockSearchText = {{ width: '100%' }}
                        placeHolder = "Actions groupées"
                        heightSize = {28}
                    />
                    <DownloadButton
                        onClick = {doSomething}
                        text = "Appliquer"
                        disabled = {selectID !== "" && !confirmationDatas.body.filter(line => line.check === true).length > 0}
                    />
                </div>
                <div style = {{display : 'flex', gap : '16px', zIndex : 100, width : '50%' }}>     
                    <InputSelectTableLittle 
                        selectedID = {selectIDFilter}
                        autoOptions={filterDatas}
                        onLineClick = {setIDFilter}
                        divStyle = {{display : 'flex', flexGrow : 1, width : '100%' }}
                        styleBlockSearchText = {{ width: '100%' }}
                        placeHolder = "Filtres"
                        heightSize = {28}
                    />
                    <DownloadButton
                        onClick = {doSomething}
                        text = "Appliquer"
                        disabled = {selectID !== "" && !confirmationDatas.body.filter(line => line.check === true).length > 0}
                    />
                </div>
            </div>
        
            <Search
                blocSearchStyle = {{display : 'none'}}
                removePage = {true}
                resultMax = {500}
                blockStyle = {{height: '85%', flexGrow : 1}}
                filterData = {[]}
                autoCompletDatas = {[]}

            > 
                <DashBoardTableObject 
                    body = {confirmationDatas.body}
                    head = {confirmationDatas.head}
                    maxCarac = {25}
                    borderLineStyle = {{height : "48px", borderBottom : '1px solid #ECECEC'}}
                    headStyle = {{height : "48px"}}
                    style= {{overflow : 'auto', display : "flex", flexGrow : 2}}
                    checkbox = {true}
                    stateChangeAll = {checkAll}
                    onChange = {onCheckLine}
                    forceCheck = {true}
                    checkAllState = {checkAllState}
                />
            </Search>
        </React.Fragment>
    )


}