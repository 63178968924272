import { createSlice } from "@reduxjs/toolkit";


export const KeyboardSlice = createSlice({
    name : "Keyboard",
    initialState:{
        keyPress : ''
    },
    reducers: {
        setKeyPress : (state, action) => {
            state.keyPress = action.payload
            return state
        },
        clearKey : (state, action) => {
            state.keyPress = ''
            return state
        },
    }
})


export const {setKeyPress, clearKey
  } = KeyboardSlice.actions;