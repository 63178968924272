import { InputSelectTableLittle, NeuMorphisme } from 'onaosreact'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeRequest } from '../../../api/apihook'
import {
    clearNavEntry,
    setAllEntries,
    setDefaultOnaosNavBar,
    setLocalInfos,
    setLoincInfos,
    setNavClientPart,
    setNavOnaosPart,
    setOtherInfos,
    setUpdateCardInfos,
} from '../../../redux/transcoding/transcoding'

export const ClientsNav = () => {
    const clientsList = useSelector((state) => state.Transcoding.clientnav.clientsList)
    const dicoList = useSelector((state) => state.Transcoding.clientnav.dicoType)
    const expertsCollectionList = useSelector((state) => state.Transcoding.clientnav.expertChapitres)

    const collectionIds = useSelector((state) => state.Transcoding.entrieID)

    const tokenRefresh = useSelector((state) => state.log.tokenRefresh)
    const dispatch = useDispatch()

    const neuMorphismeStyle = {
        width: '100%',
        height: '40px',
        padding: '',
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
    }

    const containerClientStyle = {
        width: '448px',
        padding: '8px',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'space-between',
    }

    const clientDivStyle = {
        width: '208px',
        zIndex: 2,
    }

    const clientInputStyle = {
        color: '#383C3F',
    }

    const dicoDivStyle = {
        width: '208px',
        zIndex: 2,
    }

    const dicoInputStyle = {
        color: '#383C3F',
    }

    const containerCollectionStyle = {
        padding: '8px',
        display: 'flex',
        flexGrow: 1,
    }

    const collectionDivStyle = {
        width: '100%',
        zIndex: 2,
    }

    const changeNav = (clientID, dicoType, collectionID, type) => {
        const collectionsInfos = {
            clientID: clientID,
            dicoType: dicoType,
            collectionID: collectionID,
            type: type,
        }

        makeRequest('put', '/expertNav', tokenRefresh, collectionsInfos).then((resp) => {
            if (resp.data && resp.data.localInfos) {
                if (resp.data.allEntries && resp.data.localInfos) dispatch(setAllEntries({ all: resp.data.allEntries, id: resp.data.localInfos.id }))
                if (resp.data.localInfos) dispatch(setLocalInfos(resp.data.localInfos))
                if (resp.data.loincInfos) dispatch(setLoincInfos(resp.data.loincInfos))
                if (resp.data.otherInfos) dispatch(setOtherInfos(resp.data.otherInfos))
                if (resp.data.majInfos) dispatch(setUpdateCardInfos(resp.data.majInfos))
                if (resp.data.nav && resp.data.nav.clientBlock && resp.data.nav.clientBlock.chapitres)
                    dispatch(setNavClientPart(resp.data.nav.clientBlock))
                if (resp.data.nav && resp.data.nav.onaosBlock && resp.data.nav.onaosBlock.dicoType)
                    dispatch(setNavOnaosPart(resp.data.nav.onaosBlock))
            } else {
                dispatch(setAllEntries({ all: [], id: '' }))
                dispatch(setLocalInfos({}))
                dispatch(setLoincInfos([]))
                dispatch(setUpdateCardInfos({}))
                dispatch(clearNavEntry())
                dispatch(
                    setDefaultOnaosNavBar({
                        clientID: clientID,
                        dicoType: dicoType,
                        collectionID: type === 'collection' ? collectionID : '',
                    }),
                )
            }
        })
    }

    const clientSelect = (id, text) => {
        if (text !== undefined) return
        changeNav(id, dicoList.id, collectionIds, 'client')
    }

    const dicoSelect = (id, text) => {
        if (text !== undefined) return
        changeNav(clientsList.id, id, collectionIds, 'dico')
    }

    const expertSelect = (id, text) => {
        if (text !== undefined) return
        changeNav(clientsList.id, dicoList.id, id, 'collection')
    }

    return (
        <NeuMorphisme style={neuMorphismeStyle}>
            <div style={containerClientStyle}>
                <InputSelectTableLittle
                    placeHolder='Clients'
                    autoOptions={clientsList.datas}
                    onLineClick={clientSelect}
                    divStyle={clientDivStyle}
                    selectedID={clientsList.id}
                    isSelectedColor={false}
                    inputStyle={clientInputStyle}
                    heightSize={28}
                />
                <InputSelectTableLittle
                    placeHolder='Type de dictionnaire'
                    autoOptions={dicoList.datas}
                    onLineClick={dicoSelect}
                    divStyle={dicoDivStyle}
                    selectedID={dicoList.id}
                    isSelectedColor={false}
                    inputStyle={dicoInputStyle}
                    heightSize={28}
                />
            </div>
            <div style={containerCollectionStyle}>
                <InputSelectTableLittle
                    selectedID={collectionIds}
                    autoOptions={expertsCollectionList.datas} //for autocompletion
                    onLineClick={expertSelect}
                    divStyle={collectionDivStyle}
                    placeHolder='Sélectionner une collection'
                    heightSize={28}
                    defaultColor={expertsCollectionList.datas.find((line) => line.id === collectionIds)?.hoverColor}
                />
            </div>
        </NeuMorphisme>
    )
}
