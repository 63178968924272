import React from 'react'
import { TitleVeille } from './TitleVeille'
import { ExportPDF } from '../../Export/ExportPDF'
import { useSelector } from 'react-redux'

export const HeadVeilleClient = ({}) => {
    const listing = useSelector(state => state.VeilleMenu.reportslisting)
    const isVeilleCustomer = useSelector(state => state.VeilleMenu.updateCustomer)
    const allowDownload = listing?.body.filter(line => line.check === true).length > 0


    return (
        <div style={{ padding: '0 16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <TitleVeille />
            {listing.body !== undefined && listing.body.length > 0 && isVeilleCustomer && <ExportPDF disabled={!allowDownload}/>}
        </div>
    )
}
