import {  DashBoardTableObject, Search, TitleAndClose, TitleDashboard } from 'onaosreact'
import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { activeConfirmationBtn, showWindowConfirmation, } from '../../../../../../redux/filemenu/PreviewMenu'
import { ConfirmationBtn } from './ConfirmationBtn'





export const Confirmation = ({}) => {
    const confirmationDatas = useSelector(state => state.PreviewMenu.confirmationDatas)
    const confirmationBtn = useSelector(state => state.PreviewMenu.confirmationBtn)



    const dispatch = useDispatch()
    const divRef = useRef()



  
    const checkForEnabled = () => {
        const isEnd = divRef?.current?.scrollTop + divRef?.current?.offsetHeight >= divRef?.current?.scrollHeight - 100
        if (isEnd !== confirmationBtn && isEnd === true) dispatch(activeConfirmationBtn(isEnd))
    }

    return(
        <React.Fragment>
            <TitleAndClose 
                title = {confirmationDatas.title}
                style = {{fontSize : "20px", paddingLeft : "16px", margin : '0px'}}
                onClose = {()=>dispatch(showWindowConfirmation(false))}
            />   
            <Search
                blocSearchStyle = {{display : 'none'}}
                removePage = {true}
                resultMax = {500}
                blockStyle = {{height : '92%'}}
                filterData = {[]}
                autoCompletDatas = {[]}

            >
                <DashBoardTableObject 
                    body = {confirmationDatas.table.body}
                    head = {confirmationDatas.table.head}
                    maxCarac = {25}
                    borderLineStyle = {{height : "48px", borderBottom : '1px solid #ECECEC'}}
                    headStyle = {{height : "48px"}}
                    onScroll = {checkForEnabled}
                    divRef = {divRef}
                    style= {{overflow : 'auto', height : '100%'}}
                />
            </Search>

            <ConfirmationBtn />
            
        </React.Fragment>
    )


}