import './App.css'
import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setToken } from './redux/connection/log'
import { setPage } from './redux/dashboardmenu/DashBoardMenu'
import { setShowAutoComplete } from './redux/transcodingmenu/SearchEngine'
import { Dashboard } from './dashboard/dashboard'
import { Transcodage } from './transcodage/Transcodage'
import { makeRequest } from './api/apihook'
import { informationPage } from './api/utils'
import 'onaosreact/dist/index.css'

export const App = () => {
    const tokenKeyLog = process.env.REACT_APP_TOKEN_KEY

    const dispatch = useDispatch()

    const log = useSelector((state) => state.log)

    const search = window.location.search

    const params = new URLSearchParams(search)
    let t = params.get('t')
    let sso = params.get('sso')

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
    }

    const connect = (data) => {
        if (data.token) localStorage.setItem(tokenKeyLog, data.token)
        informationPage({
            dispatch: dispatch,
            tokenRefresh: data.tokenRefresh,
            addExpert: true,
            callback: () => {
                dispatch(setPage(['Informations', 'info']))
                dispatch(setToken(data))
            },
        })
        makeRequest('get', '/findallcomponent', data.tokenRefresh).then((resp) => {
            if (resp && resp.data) dispatch(setShowAutoComplete(resp.data))
        })
    }

    useEffect(() => {
        if (t !== null && t !== '' && t !== undefined && !log.connect) {
            const data = {
                token: t,
            }

            makeRequest('put', '/checkredirect', '', data).then((resp) => {
                if (resp.data !== undefined && resp.data.token !== undefined && resp.data.token !== '') {
                    connect(resp.data)
                } else {
                    setTimeout(() => window.open(process.env.REACT_APP_URL_SSO_FRONT + '?r=' + process.env.REACT_APP_PROJET_NAME, '_self'), 200)
                }
            })
        }

        if (t === null && !log.connect && (sso === undefined || sso === null)) {
            setTimeout(() => window.open(process.env.REACT_APP_URL_SSO_FRONT + '?r=' + process.env.REACT_APP_PROJET_NAME, '_self'), 200)
        }

        if (sso !== undefined && sso !== null && !log.connect && t === null) {
            const data = {
                token: sso,
                service: process.env.REACT_APP_PROJET_NAME,
            }
            makeRequest('put', '/checktoken', '', data).then((resp) => {
                if (resp.data !== undefined && resp.data.token !== undefined && resp.data.token !== null && resp.data.token !== '') {
                    connect(resp.data)
                } else {
                    setTimeout(() => window.open(process.env.REACT_APP_URL_SSO_FRONT + '?r=' + process.env.REACT_APP_PROJET_NAME, '_self'), 200)
                }
            })
        }
    })

    return (
        <div className='App'>
            <div style={containerStyle}>
                <Router>
                    <Routes>
                        {log.connect && <Route exact path='/mapper/dashboard' element={<Dashboard />} />}
                        {log.connect && <Route exact path='/mapper/transcodage' element={<Transcodage />} />}
                        {log.dashboard && log.connect && <Route path='*' element={<Navigate replace to='/mapper/dashboard' />} />}
                    </Routes>
                </Router>
            </div>
        </div>
    )
}
